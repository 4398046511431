interface IConfig {
    outerContainerId: string;
    innerClickedContainerId: string;
    event: any;
}

export const overlayPopupClose = (
    isShowChatFilter: boolean,
    setStateCallback: (value: boolean, event: any) => void,
    config: IConfig
) => {
    const buttonElement = document.querySelector(config.innerClickedContainerId) as HTMLElement;

    if (isShowChatFilter) {
        const popoverElement = document.querySelector(config.outerContainerId) as HTMLElement;

        if (popoverElement && !popoverElement.contains(config.event.target as Node)) {
            setStateCallback(false, config.event);
        }
    } else if (buttonElement?.contains(config.event.target as Node)) {
        setStateCallback(true, config.event);
    }
};
