import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IUserCourse } from './CourseButton';
import ReactTooltip from 'react-tooltip';
import { Utility } from 'src/helpers/new';

interface IProps extends RouteComponentProps {
    courseData: IUserCourse;
    index: number;
    checkDisabled: (courseData: IUserCourse, index: number) => boolean | undefined;
    handleChange: (e: any) => void;
    modalFormData: any;
}

interface IState {
    availableExtensions: any[];
    isShowWarning: boolean;
}
class ExtensionForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            availableExtensions: [],
            isShowWarning: false,
        };
    }

    componentDidMount() {
        this.updateAvailableExtensions();
    }

    calculateMaxAvailableExtensionDays = () => {
        const { courseData } = this.props;
        const maximumAvailableDays = Utility.calculateRemainingAccessibleDays(courseData);
        return maximumAvailableDays;
    };

    updateAvailableExtensions = () => {
        const { courseData } = this.props;
        const extensions = courseData.offerOptions?.extension;

        if (extensions && extensions.length > 0) {
            if (courseData.maximumCourseTimeFrame !== 0) {
                const remainingAccessibleDays = Utility.calculateRemainingAccessibleDays(courseData);
                const sortedExtensions = extensions.sort((a, b) => a.time - b.time);
                let indexOfRemainingAccessibleDays = sortedExtensions.findIndex(
                    (extDetail) => extDetail.time >= remainingAccessibleDays
                );

                if (indexOfRemainingAccessibleDays === -1) {
                    indexOfRemainingAccessibleDays = sortedExtensions.length;
                }

                const availableExtensionsArray =
                    remainingAccessibleDays === 0
                        ? sortedExtensions.slice(0, indexOfRemainingAccessibleDays)
                        : sortedExtensions.slice(0, indexOfRemainingAccessibleDays + 1);

                this.setState({ availableExtensions: availableExtensionsArray });
            } else {
                this.setState({ availableExtensions: extensions });
            }
        } else {
            this.setState({ availableExtensions: [] });
        }
    };

    handleExtensionClick = (index: number) => {
        const { availableExtensions } = this.state;

        const isLastExtension = index === availableExtensions.length - 1;
        this.setState({ isShowWarning: isLastExtension && !this.state.isShowWarning });
    };

    render() {
        const { courseData, index, checkDisabled, handleChange, modalFormData } = this.props;
        const extensionTimes = this.state.availableExtensions?.map((ext) => ext.time);
        const highestExtensionTime = extensionTimes.length > 0 ? Math.max(...extensionTimes) : 0;
        return (
            <Form className='model-offer-options' onChange={handleChange}>
                {this.state.availableExtensions?.map((extDetail, i) => (
                    <Form.Row
                        key={`extension-${i}`}
                        className={`item extension-item ${
                            modalFormData?.[courseData.courseId] &&
                            modalFormData?.[courseData.courseId]?.extensionId === extDetail._id
                                ? 'checked'
                                : 'unchecked'
                        }`}
                    >
                        <Form.Check.Label>
                            {extDetail.time} day extension&nbsp;
                            <ReactTooltip effect='solid' multiline={true} />
                            <i
                                className='fa fa-circle-info'
                                data-tip={`When extending, the allotted time will be added to your remaining time in the course.`}
                            />
                        </Form.Check.Label>
                        <div className='item-detail'>
                            <div className='item-price'>${extDetail.price.toFixed(2)}</div>
                            <Form.Check.Input
                                name={courseData.courseId}
                                type={'checkbox'}
                                value={JSON.stringify({
                                    _id: courseData.courseId,
                                    extensionId: extDetail._id,
                                    purchaseType: 'extension',
                                    price: extDetail.price.toFixed(2),
                                    userPackageId: courseData.userPackageId,
                                    userCourseId: courseData._id,
                                })}
                                disabled={checkDisabled(courseData, index)}
                                onChange={() => {}}
                                onClick={() => this.handleExtensionClick(i)}
                                checked={
                                    modalFormData?.[courseData.courseId] &&
                                    modalFormData?.[courseData.courseId]?.extensionId === extDetail._id
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </Form.Row>
                ))}
                {this.state.isShowWarning &&
                    courseData.maximumCourseTimeFrame !== 0 &&
                    Utility.calculateRemainingAccessibleDays(courseData) < highestExtensionTime && (
                        <div className='warning-message'>
                            <i className='fa fa-exclamation-circle text-warning mx-1' />
                            <span className='pr-2'>
                                This course can be extended a maximum of {this.calculateMaxAvailableExtensionDays()}{' '}
                                further days. Longer extensions will not extend the course beyond this point.
                            </span>
                        </div>
                    )}
            </Form>
        );
    }
}

export default withRouter(ExtensionForm);
