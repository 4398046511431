import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './Payment.scss';
import { Row, Col } from 'react-bootstrap';
import SuggestionUpsellPrompt from '../Upsells/SuggestionUpsellPrompt';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import PaymentBlock from 'src/pages/Checkout/Common/PaymentBlock';
import CheckoutContext from 'src/pages/Checkout/CheckoutContext';

interface IState {}

interface IProps extends RouteComponentProps {
    onSetTermsAndConditions: (value: string) => void;
    pkgTermsConditionsCheckboxToggle: (value: string) => void;
}

class Payment extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    state: IState = {};

    render() {
        return (
            <>
                <Row className='payment-main-wrapper'>
                    <Col className='package-list-wrapper'>
                        <PaymentSummary isSinglePageCheckout={false} />
                    </Col>
                    <Col lg={5} className='payment-content-container'>
                        <Row className='payment-tab-wrapper'>
                            <Col className='payment-tab-heading'>
                                <h2>Payment Method</h2>
                            </Col>
                            <PaymentBlock
                                onSetTermsAndConditions={this.props.onSetTermsAndConditions}
                                pkgTermsConditionsCheckboxToggle={this.props.pkgTermsConditionsCheckboxToggle}
                                isSinglePageCheckout={false}
                            />
                        </Row>
                    </Col>
                </Row>
                {this.context.isDisplaySuggestion && <SuggestionUpsellPrompt />}
            </>
        );
    }
}

export default withRouter(Payment);
