import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import CouponArea from '../StepCheckout/OrderSummary/CouponArea';
import ReactTooltip from 'react-tooltip';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import Toggle from 'src/components/FormItems/Toggle';
import { PayPalButton } from 'react-paypal-button-v2';
import CheckoutContext from '../CheckoutContext';
import { Api, Utility } from 'src/helpers/new';
import { getState } from 'src/helpers/localStorage';
import { isEmpty } from 'lodash';
import './PaymentBlock.scss';
import OrderSummary from '../Common/OrderSummay';

export type StateUpdate = {
    [key: string]: any;
};

interface IProps extends RouteComponentProps {
    onSetTermsAndConditions: (value: string) => void;
    pkgTermsConditionsCheckboxToggle: (value: string) => void;
    isSinglePageCheckout: boolean;
}

interface IState {
    paymentMethod: string;
    isPurchaseLoading: boolean;
    orderId_paypal: string;
    termsAgreement: boolean;
}
class PaymentBlock extends Component<IProps, IState> {
    static contextType = CheckoutContext;
    state: IState = {
        // packages: [],
        paymentMethod: 'card',
        isPurchaseLoading: false,
        orderId_paypal: '',
        termsAgreement: false,
    };

    get getTotalPrice() {
        const { packages } = this.context;
        let totalPrice = 0;
        packages.map((pkg: any, index: number) => {
            totalPrice += pkg.discountedPrice ?? pkg.price;
        });

        return totalPrice;
    }

    get getCartId() {
        const cartId = this.context.isAdminPreview
            ? getState('reuPreviewCheckoutCartId')
            : getState('reuCheckoutCartId');
        const id = this.context.isAdminPreview ? cartId : getState('user')?._id ? getState('user')?._id : cartId;

        return { cartId, id };
    }

    completePurchase = async () => {
        this.setState({ isPurchaseLoading: true });
        const { id } = this.getCartId;
        const { success, response } = await Api.call('POST', `/orders/${id}?isLoggedIn=${this.getIsLoggedInFlag()}`);

        if (success) {
            this.props.history.push(
                `/checkout/payment/confirmation/${response.orderId}?packages=${
                    this.context.paymentPackages
                }&isTotalZero=${this.getTotalPrice === 0}`,
                response
            );
        }

        this.setState({ isPurchaseLoading: false });
    };

    getIsLoggedInFlag = () => {
        if (this.context.isAdminPreview) {
            return false;
        } else {
            return !!getState('user')?._id;
        }
    };

    postPaypal = async (details: any, data: any) => {
        const { success } = await Api.call('POST', 'checkout/cart/paypal', { paymentObj: { details, data } });

        if (success) {
            this.props.history.push(
                `/checkout/payment/confirmation/${this.state.orderId_paypal}?packages=${this.context.paymentPackages}`
            );
        }
    };

    checkboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        this.setState({ [e.currentTarget.name]: !this.state[e.currentTarget.name] });
    };

    initatePaypalPayment = async () => {
        const query = new URLSearchParams(this.props.location.search);
        const location = query.get('location');
        const { success: resultStatus, response: cartDetails } = await this.context.getPrice();
        const { id } = this.getCartId;
        const { success, response } = await Api.call(
            'GET',
            `checkout/cart/initiatepayment/${id}/paypal?location=${location}&isLoggedIn=${this.getIsLoggedInFlag()}`
        );

        if (success && resultStatus) {
            this.setState({ orderId_paypal: response.orderId_paypal });
            return { orderId: response?.orderId, tax: cartDetails?.tax };
        }
    };

    onTermsAndConditions = async () => {
        const { response, success } = await Api.call('GET', '/settings/terms_of_service');

        this.props.onSetTermsAndConditions(success ? response : 'Failed to load');
    };
    onPackageTermsAndConditions = async () => {
        const { response, success } = await Api.call('GET', '/settings/terms_of_service');

        this.props.onSetTermsAndConditions(success ? response : 'Failed to load');
    };
    isPackageConditionsVerified = () => {
        return this.context.pkgTermsAgreement?.every((pkg: any) =>
            this.context.isAdminPreview ? true : pkg.checked == true
        );
    };
    checkTermsCondition = () => {
        return isEmpty(this.context.packages.find((v: any) => !isEmpty(v.termsAndConditions)));
    };

    handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ paymentMethod: e.target.name });
    };

    updateState = (updates: StateUpdate) => {
        this.setState((prevState) => ({
            ...prevState,
            ...updates,
        }));
    };

    renderPaymentOptionChooser = () => {
        const { paymentMethod } = this.state;
        return (
            <Col className='payment-option-wrapper'>
                <div className='toggle-option'>
                    <Toggle
                        type='radio'
                        name='card'
                        id='card'
                        checked={paymentMethod === 'card'}
                        onChange={this.handleOptionChange}
                    />
                    <label htmlFor='card'>
                        <div className='cards-logos'>
                            <img src={process.env.PUBLIC_URL + '/visa.png'} alt='Visa logo' />
                            <img src={process.env.PUBLIC_URL + '/mastercard.png'} alt='Mastercard logo' />
                            <img src={process.env.PUBLIC_URL + '/americanexpress.png'} alt='American express logo' />
                            <img src={process.env.PUBLIC_URL + '/unionpay.svg'} alt='UnionPay logo' />
                        </div>
                    </label>
                </div>
                <div className='toggle-option'>
                    <Toggle
                        type='radio'
                        name='paypal-method'
                        id='paypal-method'
                        checked={paymentMethod === 'paypal-method'}
                        onChange={this.handleOptionChange}
                    />
                    <label htmlFor='paypal-method-card'>
                        <div className='cards-logos'>
                            <img src={process.env.PUBLIC_URL + '/paypal.svg'} alt='paypal logo' />
                        </div>
                    </label>
                </div>
                <div className='toggle-option'>
                    <Toggle
                        type='radio'
                        name='klarna'
                        id='klarna'
                        checked={paymentMethod === 'klarna'}
                        onChange={this.handleOptionChange}
                    />
                    <label htmlFor='klarna-card' className='klarna-card-label'>
                        <div className='cards-logos'>
                            <img
                                src={process.env.PUBLIC_URL + '/klarna-logo.png'}
                                alt='Klarna logo'
                                className='klarna-logo'
                            />
                            <span>
                                Pay with Klarna in 3 interest-free installments. Must be 18+, credit subject to status.
                                CapitalOne card not accepted.
                            </span>
                        </div>
                    </label>
                </div>
            </Col>
        );
    };

    renderPaymentSummary = () => {
        return (
            <OrderSummary
                getCartId={this.getCartId}
                getIsLoggedInFlag={this.getIsLoggedInFlag()}
                isSinglePageCheckout={this.props.isSinglePageCheckout}
            />
        );
    };

    renderPaymentAggreeMents = () => {
        return (
            <Col className='agreement-wrapper'>
                <label htmlFor='termsAgreement' className='lblTerms_Condition'>
                    <input
                        type='checkbox'
                        name='termsAgreement'
                        id='termsAgreement'
                        onChange={this.checkboxToggle}
                        checked={this.context.isAdminPreview ? true : this.state.termsAgreement}
                    />
                    <span className='terms-condition-wrapper'>I have read and agree to the</span>
                    <button onClick={this.onTermsAndConditions}>terms and conditions</button>
                    <span className='required-terms'>*</span>
                </label>
                {this.context.pkgTermsAgreement?.map((pkg: any) => {
                    return (
                        <div key={pkg._id}>
                            {pkg.termsAndConditions && (
                                <label htmlFor={pkg._id} className='lblTerms_Condition_pkg'>
                                    <div className='checkbox-wrapper'>
                                        <input
                                            type='checkbox'
                                            name={pkg._id}
                                            id={pkg._id}
                                            checked={this.context.isAdminPreview ? true : pkg.checked}
                                            onChange={() => this.props.pkgTermsConditionsCheckboxToggle(pkg._id)}
                                        />
                                    </div>

                                    <ConditionalWrapper
                                        condition={pkg?.termsAndConditionsHeader?.length > 130}
                                        wrapper={(children: any) => (
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id={`tooltip-pkg`}>
                                                        {pkg?.termsAndConditionsHeader}
                                                    </Tooltip>
                                                }
                                            >
                                                {children}
                                            </OverlayTrigger>
                                        )}
                                    >
                                        <div className='terms-condition-wrapper'>
                                            <button
                                                onClick={() =>
                                                    this.props.onSetTermsAndConditions(pkg.termsAndConditions)
                                                }
                                            >
                                                <span>{pkg?.termsAndConditionsHeader}</span>
                                            </button>
                                        </div>
                                    </ConditionalWrapper>
                                </label>
                            )}
                        </div>
                    );
                })}
            </Col>
        );
    };

    renderPaymentButton = (studentHasAgreed: any, allStepsCompleted: any) => {
        const { paymentMethod } = this.state;
        const { price, tax, paymentPackages } = this.context;
        const query = new URLSearchParams(this.props.location.search);
        const location = query.get('location');
        const packageName = this.context.packages[0]?.title;

        if (this.getTotalPrice === 0 && !this.context.isAdminPreview) {
            return (
                <div className='complete-purchase-button'>
                    <button
                        className={`sm  ${studentHasAgreed && allStepsCompleted ? '' : 'disabled'} ${
                            !this.state.isPurchaseLoading ? '' : 'disabled'
                        }`}
                        onClick={this.completePurchase}
                    >
                        {this.state.isPurchaseLoading && <Spinner animation='border' size='sm' />} &nbsp; Complete
                        Purchase
                    </button>
                </div>
            );
        } else if (paymentMethod === 'card') {
            return (
                <Link
                    className='stripe-button'
                    to={{
                        pathname:
                            allStepsCompleted && studentHasAgreed && !this.context.isAdminPreview
                                ? `/checkout/payment/stripe/card`
                                : `/checkout/payment/confirmation/orderId=null&packages=${paymentPackages}&title=${packageName}&isAdminPreview=true`,
                        search:
                            allStepsCompleted && studentHasAgreed && !this.context.isAdminPreview
                                ? `?location=${location}`
                                : ``,
                        state: {
                            isSinglePageCheckout: this.props.isSinglePageCheckout ?? false,
                            billingAddress: this.context.billingAddress,
                            contact: this.context.contact,
                        },
                    }}
                >
                    <button className='dark sm'>Pay by card</button>
                </Link>
            );
        } else if (paymentMethod === 'klarna') {
            return (
                <Link
                    className='stripe-button'
                    to={{
                        pathname:
                            allStepsCompleted && studentHasAgreed && !this.context.isAdminPreview
                                ? `/checkout/payment/stripe/klarna`
                                : `/checkout/payment/confirmation/orderId=null&packages=${paymentPackages}&title=${packageName}&isAdminPreview=true`,
                        search:
                            allStepsCompleted && studentHasAgreed && !this.context.isAdminPreview
                                ? `?location=${location}`
                                : ``,
                        state: {
                            isSinglePageCheckout: this.props.isSinglePageCheckout ?? false,
                            billingAddress: this.context.billingAddress,
                            contact: this.context.contact,
                        },
                    }}
                >
                    <button className='dark sm'>Pay by Klarna</button>
                </Link>
            );
        } else if (paymentMethod === 'paypal-method') {
            if (!this.context.isAdminPreview) {
                let description = ``;

                const pkgs = this.context.packages
                    .filter((pkg: { title: string }) => pkg.hasOwnProperty('packagePurchaseType'))
                    .map((item: { title: string }) => item.title);
                const courses = this.context.packages
                    .filter((pkg: { title: string }) => pkg.hasOwnProperty('purchaseType'))
                    .map((item: { title: string }) => item.title);

                if (pkgs.length && courses.length) {
                    description = `packages: ${pkgs.join(',')}\ncourses: ${courses.join(',')}`;
                } else if (pkgs.length) {
                    description = `packages: ${pkgs.join(',')}`;
                } else {
                    description = `courses: ${courses.join(',')}`;
                }

                return (
                    <div className='paypal-button'>
                        <PayPalButton
                            onSuccess={this.postPaypal}
                            createOrder={async (data: any, actions: any) => {
                                const cart = await this.initatePaypalPayment();
                                const paypalPrice = (price + cart?.tax).toFixed(2);

                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            reference_id: this.state.orderId_paypal,
                                            invoice_id: this.state.orderId_paypal,
                                            description: description.substring(0, 127),
                                            custom_id: cart?.orderId,
                                            amount: {
                                                currency_code: 'USD',
                                                value: paypalPrice,
                                            },
                                        },
                                    ],
                                    application_context: {
                                        shipping_preference: 'NO_SHIPPING',
                                    },
                                });
                            }}
                            options={{
                                clientId: `${process.env.REACT_APP_paypalClientId}`,
                                disableFunding: 'card',
                            }}
                            style={{
                                shape: 'pill',
                                color: 'silver',
                                layout: 'horizontal',
                                label: '',
                                tagline: 'false',
                                height: 55,
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <Link
                        to={
                            this.context.isAdminPreview
                                ? `/checkout/payment/confirmation/orderId=null&packages=${paymentPackages}&title=${packageName}&isAdminPreview=true`
                                : '#'
                        }
                    >
                        <button className='light sm'>
                            <img src={process.env.PUBLIC_URL + '/paypal.svg'} alt='PayPal logo' />
                        </button>
                    </Link>
                );
            }
        }
    };

    renderPaymentInfo = () => {
        return (
            <Col className='payment-info-wrapper'>
                <div>
                    <img src={process.env.PUBLIC_URL + '/payment-lock.svg'} alt='Payment lock' />
                    Guaranteed <b>safe</b> & <b>secure</b> checkout
                </div>
                <div className='content-wrapper'>
                    <p>
                        <img src={process.env.PUBLIC_URL + '/payment-thumb.svg'} alt='Payment right' />
                        <b>100% satisfaction</b> or your money back
                    </p>
                    <p>
                        <img src={process.env.PUBLIC_URL + '/payment-secure.svg'} alt='Payment secure' />
                        SSL certified checkout
                    </p>
                </div>
                <div className='stripe-text-wrapper'>
                    Powered by <b>stripe</b>
                </div>
            </Col>
        );
    };

    render() {
        const { termsAgreement } = this.state;
        const { isSinglePageCheckout } = this.props;

        const studentTermsAgreement = this.checkTermsCondition()
            ? termsAgreement
            : termsAgreement && this.isPackageConditionsVerified();

        const studentHasAgreed = this.context.isAdminPreview ? true : studentTermsAgreement;

        const isValidContact = Utility.isNoEmptyFields(['firstName', 'lastName', 'email'], this.context.contact);
        const isValidBilling = Utility.isNoEmptyFields(['zipCode', 'state'], this.context.billingAddress);

        const isValidEmail = Utility.validateEmail(this.context.contact?.email);
        const isValiZipcode = Utility.validateZipCode(
            this.context?.billingAddress?.zipCode ?? this.context?.user?.billingAddress?.zipCode
        );
        const isStateSelected = this.context.billingAddress?.state !== 'none';

        const allStepsCompleted = isSinglePageCheckout
            ? isValidContact && isValidBilling && isValidEmail && isValiZipcode && isStateSelected
            : [...this.context.tabs]
                  ?.splice(0, 3)
                  ?.every((item: { completed: boolean; title: string }) => item.completed === true);
        return (
            <Col className='payment-content-wrapper'>
                <Row className='content-wrapper'>
                    {this.getTotalPrice === 0 ? (
                        <p className='zero-purchase-wrapper'>You have a free purchase! Complete your order now</p>
                    ) : (
                        this.renderPaymentOptionChooser()
                    )}

                    {!isSinglePageCheckout && (
                        <Col className='payment-calculation-wrapper'>{this.renderPaymentSummary()}</Col>
                    )}

                    {this.renderPaymentAggreeMents()}
                    <Col className='payment-button-wrapper'>
                        <ReactTooltip effect='solid' multiline={true} />
                        <div
                            data-tip={`${
                                studentHasAgreed
                                    ? !allStepsCompleted
                                        ? isSinglePageCheckout
                                            ? `Fill up all ${
                                                  isValidEmail || isValiZipcode ? 'correct' : ''
                                              } contact details to proceed further!`
                                            : 'Please fill up details in all payment steps to proceed with payment!'
                                        : ''
                                    : 'Please tick the checkbox above before you complete your order!'
                            }`}
                        >
                            <div className={`${studentHasAgreed && allStepsCompleted ? '' : 'disabled'}`}>
                                {this.renderPaymentButton(studentHasAgreed, allStepsCompleted)}
                            </div>
                        </div>
                    </Col>
                    {!isSinglePageCheckout && (
                        <Col className='apply-discount-wrapper'>
                            <CouponArea isPayment={true} />
                        </Col>
                    )}
                    {isSinglePageCheckout && this.renderPaymentInfo()}
                </Row>
            </Col>
        );
    }
}

export default withRouter(PaymentBlock);
