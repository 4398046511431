import React, { Component } from 'react';
import { DownloadLink, FileIFrame } from 'src/components/ApiFile';
import './Document.slide.scss';
import { Modal } from 'react-bootstrap';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';
import LessonContext from '../../../../LessonContext';

export interface IProps {
    heading?: string;
    content?: string;
    sourceDocument?: string;
    theme?: string;
    info?: string;
}
interface IState {
    documentOpen: boolean;
}
export default class DocumentSlide extends Component<IProps, IState> {
    static contextType = LessonContext;

    state: IState = {
        documentOpen: false,
    };

    toggleDocument = () => {
        this.setState({ documentOpen: !this.state.documentOpen });
    };

    render() {
        const { sourceDocument, heading, content } = this.props;

        return (
            <div className={`document-type document-type--card`}>
                <SlideHeader heading={heading} subHeading={''} />
                {!!content && (
                    <div
                        className='DT-Content'
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                )}
                <div className='document-link'>
                    <button className='bp' onClick={this.toggleDocument}>
                        View document <i className='fa-solid fa-square-arrow-up-right'></i>
                    </button>
                    <DownloadLink fileId={sourceDocument}>
                        Download document <i className='fa-solid fa-cloud-arrow-down'></i>
                    </DownloadLink>
                </div>

                <Modal
                    size='lg'
                    className='document-card-modal fullHeight-Scroll'
                    show={this.state.documentOpen}
                    onHide={this.toggleDocument}
                >
                    <Modal.Header closeButton>{heading}</Modal.Header>
                    <Modal.Body>
                        <FileIFrame
                            fileId={sourceDocument}
                            inactiveTimerCallback={this.context.inactiveTimerCallback}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
