import React, { Component } from 'react';
import './Exam.slide.scss';
import { IExamContent } from '../Exam';
import { Api, EventBus, Utility } from 'src/helpers/new';
import CardsExam from './CardsExam';
import Navigation from '../../../Stages/Lessons/LessonTypes/Slide/Quiz/QuizNavigation';
import { clone, isNil } from 'lodash';
import Spinner from 'src/components/Spinner/Spinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteLeavingGuard } from 'src/components/RouteLeavingGuard';
import CourseContext from '../../../CourseContext';

export interface IExamAttempt {
    _id: string;
    questions: any[];
    answers: number[];
    passPct: number;
    status: string;
    timeLimit: number;
    startedAt: string[];
    pausedAt: string[];
    score: number;
    spentTime: number;
    review: string;
    percentageScore: number;
    totalMarks: number;
    userCourseId: string;
    userId: string;
    title: string;
    timeoutAction: string;
    revealAnswers: string;
    allowSkip: true;
    examType: 'slide' | 'inline';
    createdAt: string;
    updatedAt: string;
    completedAt: string;
}

interface IProps {
    current: IExamContent;
    bioSightOkay: boolean;
    loadExamData: () => void;
    lastAttempts: any[];
}
interface IState {
    activeQuestionCardIndex: number;
    current: IExamContent;
    marks?: number;
    isExamLoading: boolean;
    isCheckedAnswer: boolean;
    isDirty: boolean;
    blurAt?: number;
}
class ExamSlideContainer extends Component<IProps & RouteComponentProps, IState> {
    static contextType = CourseContext;

    themeRef: any = React.createRef();
    state: IState = {
        activeQuestionCardIndex: 0,
        isExamLoading: false,
        current: this.props.current,
        isCheckedAnswer: false,
        isDirty: true,
    };

    get getRemainingTime(): number {
        return Utility.getCurrentRemainingTime(this.state.current.startedAt[0], this.state.current.timeLimit);
    }

    get examProgress(): number {
        return ((this.state.activeQuestionCardIndex + 1) * 100) / this.state.current?.questions?.length;
    }

    componentDidMount(): void {
        this.setState({ current: this.props.current });
        EventBus.dispatch('check-in-exam', { inExam: true });
        if (this.getRemainingTime < 0) {
            EventBus.dispatch('confirmation-popup', {
                title: 'Out of time',
                body: 'You have run out of time for this Exam. Your answers have been submitted',
                confirm: {
                    text: 'Okay',
                    action: () => {},
                },
                doAsyncInBackground: true,
                cancel: null,
            });
            this.forceSubmitExam();
        }
        EventBus.onWindow('blur', this.handleWindowBlur);
        EventBus.onWindow('focus', this.handleWindowAgainInFocus);
    }

    componentWillUnmount(): void {
        this.handleWindowAgainInFocus();
        this.leftExamHandler();
        this.setState({ isExamLoading: false });
        EventBus.dispatch('check-in-exam', { inExam: false });
        EventBus.dispatch('show-exam-banner', { isResume: null });
        EventBus.removeFromWindow('blur');
        EventBus.removeFromWindow('focus');
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.current) !== JSON.stringify(this.props.current)) {
            this.setState({ current: this.props.current });
        }
    }

    handleWindowBlur = () => {
        this.setState({ blurAt: Date.now() });
    };

    handleWindowAgainInFocus = () => {
        if (this.state?.blurAt && this.props.current?._id) {
            const endTime = Date.now();
            const startTime = this.state.blurAt;
            const unAvailableTime = endTime - this.state.blurAt;
            this.setState({ blurAt: 0 });
            window.socket.emit('examOutOfFocusData', {
                examId: this.props.current._id,
                timetoAdd: unAvailableTime,
                startTime: startTime,
                endTime: endTime,
            });
        }
    };

    submitExam = async () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Submit Exam',
            body: 'Once you click "Submit Exam," your responses will be final, and the examination process will be concluded.',
            confirm: {
                text: 'Submit Exam',
                action: () => {
                    this.setState({ isExamLoading: true });

                    EventBus.dispatch('end-exam-proctoring');
                    window.socket.emit('submit exam', {
                        examId: this.state.current?._id,
                        courseId: this.state.current?.userCourseId,
                    });
                },
            },
            cancel: {
                action: () => {},
            },
        });
    };

    forceSubmitExam = async () => {
        this.setState({ isExamLoading: true });

        EventBus.dispatch('end-exam-proctoring');
        window.socket.emit('submit exam', {
            examId: this.state.current?._id,
            courseId: this.state.current?.userCourseId,
        });

        await Api.call('PUT', `/users/exam/${this.state.current?._id}`, {
            isExamVerified: true,
        });
    };

    setActiveCardIndex = (index: number) => {
        this.setState({
            activeQuestionCardIndex: index,
        });
    };

    onCheckAnswer = async (questionIndex: number, option: any) => {
        const currentValue = clone(this.state.current.answers)[questionIndex];
        const answers = this.state.current.answers;
        answers[questionIndex] = option;

        this.setState({
            isCheckedAnswer: true,
            current: {
                ...this.state.current,
                answers,
            },
        });

        const { success, response, message } = await Api.call(
            'GET',
            `/users/exam/${this.state.current._id}/answer/${questionIndex}/${option}`
        );

        if (!success) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: message ?? 'Something wrong on answer selection !',
            });

            const answers = clone(this.state.current.answers);
            if (!isNil(currentValue)) {
                answers[questionIndex] = currentValue;
            } else {
                delete answers[questionIndex];
            }

            this.setState({
                isCheckedAnswer: false,
                current: {
                    ...this.state.current,
                    answers,
                },
            });
        }

        if (success) {
            this.setState({
                marks: this.state.marks + response.marks,
                isCheckedAnswer: false,
                current: {
                    ...this.state.current,
                    spentTime: response.spentTime,
                },
            });
        }
    };

    renderContent = () => {
        const { current } = this.state;
        if (current.startedAt[0]) {
            return (
                <CardsExam
                    current={this.state.current}
                    forceSubmit={this.forceSubmitExam}
                    onSubmit={this.submitExam}
                    onAnswer={this.onCheckAnswer}
                    activeCardIndex={this.state.activeQuestionCardIndex}
                />
            );
        }
        return null;
    };

    getBiosightInfoBorder = () => {
        let returnHtml = null;

        if (this.context.course.biosightInfo) {
            const className = this.context.course.biosightInfo?.barColor ?? 'gray';
            const eyeStatus = this.context.course.biosightInfo?.isConnected
                ? this.props.bioSightOkay
                    ? 'okay'
                    : 'flagged'
                : 'flagged';
            returnHtml = (
                <div className={`biosight-status-bar ${className}`}>
                    <span className={eyeStatus}>
                        <i className={`fa fa-eye`} aria-hidden='true'></i>
                    </span>
                    {` `}
                    {`Proctoring status: `}&nbsp;<b> {this.context.course.biosightInfo?.message}</b>
                </div>
            );
        }

        return returnHtml;
    };

    leftExamHandler = () => {
        if (!this.context.course.biosightInfo || this.context.course.biosightInfo?.isConnected) {
            window.socket.emit('left exam', '');
        }
    };

    render() {
        const { current, isExamLoading, isDirty } = this.state;

        if (isExamLoading) return <Spinner />;
        return (
            <>
                <RouteLeavingGuard
                    when={isDirty}
                    navigate={(path: any) => this.props.history.push(path)}
                    shouldBlockNavigation={() => {
                        return isDirty;
                    }}
                    inExam={true}
                />
                <div className='lesson-container'>
                    <div className={`slide-layout ${this.props.bioSightOkay ? 'okay' : 'flagged'}`}>
                        <div className={`slide-container`}>
                            <div className='slide-container__wrapper'>
                                {this.getBiosightInfoBorder()}
                                <main ref={this.themeRef}>
                                    <div className={`exam-card`}>{this.renderContent()}</div>
                                </main>
                                {current.startedAt[0] && (
                                    <footer>
                                        <Navigation
                                            course={{}}
                                            activeCardIndex={this.state.activeQuestionCardIndex}
                                            setActiveCardIndex={this.setActiveCardIndex}
                                            questions={current.questions}
                                            answers={current.answers}
                                            handleSubmit={this.submitExam}
                                            isReview={false}
                                            allowSkip={!!current.allowSkip}
                                            isQuizLoading={false}
                                            isExam={true}
                                            isCheckedAnswer={this.state.isCheckedAnswer}
                                        />
                                        <div className='slide-container__progress'>
                                            <div style={{ width: `${this.examProgress}%` }}></div>
                                        </div>
                                    </footer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ExamSlideContainer);
