import React, { Component } from 'react';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Api, EventBus } from 'src/helpers/new';
import withContext from 'src/helpers/withContext';
import AccountContext, { IAccountContext } from '../AccountContext';
import { isValidPhoneNumber } from 'react-phone-number-input';
import momentTZ from 'moment-timezone';
import PhoneInput from 'src/components/inputs/PhoneInput';
import ReactTooltip from 'react-tooltip';
import './profileDetails.scss';

interface IState {
    loading: boolean;
    failedToLoad: boolean | string;
    email: string;
    _id: string;
    firstName: string;
    lastName: string;
    adminLevel: string;
    phoneNumber: any;
    suspended: string;
    verified: string;
    adminNotes: string;
    timeZone: string;
    showModal: boolean;
    passwordError: string;
    currentPassword: string;
    password: string;
    passwordCheck: string;
    [key: string]: any;
}

interface IProps {
    contextValue: IAccountContext;
    loggedIn: {
        APP_VERSION: string;
        token: string;
        user: any;
    };
    setLoggedIn: (payload: any) => void;
}

class ProfileDetails extends Component<IProps, IState> {
    static contextType = AccountContext;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            failedToLoad: false,
            email: '',
            _id: '',
            firstName: '',
            lastName: '',
            adminLevel: '',
            phoneNumber: '',
            suspended: '',
            verified: '',
            adminNotes: '',
            timeZone: '',
            showModal: false,
            passwordError: '',
            currentPassword: '',
            password: '',
            passwordCheck: '',
            validations: {
                phone: '',
            },
            isValid: true,
        };
    }

    async componentDidMount() {
        this.setState({
            ...this.context.detailsTabData,
            loading: false,
            failedToLoad: this.context.failedToLoadMsg,
        });
    }

    componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.contextValue.detailsTabData !== this.props.contextValue.detailsTabData) {
            this.setState({
                ...this.context.detailsTabData,
                loading: false,
                failedToLoad: this.context.failedToLoadMsg,
            });
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    handlePhoneInputChange = (value: string) => {
        this.setState({
            phoneNumber: value,
            validations: {
                ...this.state.validations,
                phone: '',
            },
        });
    };

    validateForm = () => {
        let isValid = true;
        const validations = {
            phone: '',
        };

        if (this.state.phoneNumber) {
            const isPhoneValid = isValidPhoneNumber(this.state.phoneNumber ?? '');
            if (!isPhoneValid) {
                validations.phone = 'Please enter valid phone number';
                isValid = false;
            }
        }

        if (!isValid) {
            this.setState({
                isValid,
                validations,
            });
        }

        return isValid;
    };

    handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValid = this.validateForm();

        if (!isValid) {
            return;
        }

        const { success, response, message } = await Api.call('PUT', '/users/profile', {
            email: this.state.email,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            adminLevel: this.state.adminLevel,
            phoneNumber: this.state.phoneNumber,
            timeZone: this.state.timeZone,
        });
        if (success) {
            this.props.setLoggedIn({
                ...this.props.loggedIn,
                user: {
                    ...this.props.loggedIn.user,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    file: response.file,
                },
            });
            EventBus.dispatch('toast', {
                type: 'success',
                message: message ?? 'Profile details saved successfully!',
            });
        }
    };

    toggleShowModal = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e instanceof Event) {
            e.preventDefault();
        }

        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { success, message } = await Api.call('POST', '/users/password/change', {
            currentPassword: this.state.currentPassword,
            password: this.state.password,
            passwordCheck: this.state.passwordCheck,
        });

        if (success) {
            EventBus.dispatch('toast', {
                type: 'success',
                message: message ?? 'Your password was changed!',
            });

            this.toggleShowModal();
        } else {
            this.setState({
                passwordError: message,
            });
        }
    };

    render() {
        const { message, passwordError } = this.state;
        const timeZonesList = momentTZ.tz.names();
        const isAdmin = this.props.loggedIn?.user?.userRole === 'admin';

        return (
            <section id='user-profile-details' className='user-profile-details'>
                <Row className='header-wrapper'>
                    <Col>
                        <h3>Your profile</h3>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <button className='btn bp' onClick={this.toggleShowModal}>
                            Change password
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'form' + (message ? ' form--with-error' : '')}>
                            {message && <Alert variant={message.type}>{message.text}</Alert>}
                            <div className='form__content'>
                                <form action='/' onSubmit={this.handleFormSubmit}>
                                    <Row>
                                        <Col lg={6} xs={12} md={12}>
                                            <div className='form__field'>
                                                <label htmlFor='firstName'>First name</label>
                                                <ReactTooltip effect='solid' multiline={true} />
                                                <div
                                                    data-tip={
                                                        !isAdmin
                                                            ? `To avoid account tampering you cannot edit these fields. 
                                                    If you need for these fields to be edited please reach out to support@realestateu.com`
                                                            : ''
                                                    }
                                                >
                                                    <input
                                                        type='text'
                                                        name='firstName'
                                                        onChange={this.handleInputChange}
                                                        defaultValue={this.state.firstName}
                                                        disabled={!isAdmin}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='lastName'>Last name</label>
                                                <ReactTooltip effect='solid' multiline={true} />
                                                <div
                                                    data-tip={
                                                        !isAdmin
                                                            ? `To avoid account tampering you cannot edit these fields. 
                                                    If you need for these fields to be edited please reach out to support@realestateu.com`
                                                            : ''
                                                    }
                                                >
                                                    <input
                                                        type='text'
                                                        name='lastName'
                                                        onChange={this.handleInputChange}
                                                        defaultValue={this.state.lastName}
                                                        disabled={!isAdmin}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='email'>Email</label>
                                                <ReactTooltip effect='solid' multiline={true} />
                                                <div
                                                    data-tip={
                                                        !isAdmin
                                                            ? `To avoid account tampering you cannot edit these fields. 
                                                    If you need for these fields to be edited please reach out to support@realestateu.com`
                                                            : ''
                                                    }
                                                >
                                                    <input
                                                        type='text'
                                                        name='email'
                                                        onChange={this.handleInputChange}
                                                        defaultValue={this.state.email}
                                                        disabled={!isAdmin}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='phoneNumber'>Phone number</label>
                                                <PhoneInput
                                                    id='uPhoneNumber'
                                                    value={this.state.phoneNumber}
                                                    onChange={this.handlePhoneInputChange}
                                                />
                                                {this.state.validations.phone && (
                                                    <span className='error_message'>
                                                        {this.state.validations.phone}
                                                    </span>
                                                )}
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='timeZone'>Time zone</label>
                                                <select
                                                    className={`dropdown-input`}
                                                    name='timeZone'
                                                    value={this.state.timeZone}
                                                    onChange={this.handleInputChange}
                                                >
                                                    <option value=''>Select a TimeZone</option>
                                                    {timeZonesList.map((option: any) => (
                                                        <option value={option} key={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='form__buttons'>
                                        <button type='submit' className='btn bp'>
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={this.state.showModal} onHide={this.toggleShowModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'form' + (passwordError ? ' form--with-error' : '')}>
                            {passwordError && <div className='form__error'>{passwordError}</div>}
                            <div className='form__content'>
                                <form action='/' onSubmit={this.handlePasswordChange}>
                                    {this.props.loggedIn.token && (
                                        <div className='form__field'>
                                            <label htmlFor='currentPassword'>Current password</label>
                                            <input
                                                type='password'
                                                name='currentPassword'
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    )}
                                    <div className='form__field'>
                                        <label htmlFor='password'>New password</label>
                                        <input type='password' name='password' onChange={this.handleInputChange} />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='passwordCheck'>Repeat new password</label>
                                        <input type='password' name='passwordCheck' onChange={this.handleInputChange} />
                                    </div>
                                    <div className='form__buttons'>
                                        <button className='btn bp' type='submit'>
                                            Submit
                                        </button>
                                        <button className='btn bd' onClick={this.toggleShowModal}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}

export default connect(
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    }
)(withRouter(withContext(ProfileDetails, AccountContext)));
