import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './SuggestionUpsellPrompt.scss';
import { Api, EventBus, Utility } from 'src/helpers/new';
import CheckoutContext from '../../../CheckoutContext';
import smoothscroll from 'smoothscroll-polyfill';
import { getState } from 'src/helpers/localStorage';

declare global {
    interface Window {
        __forceSmoothScrollPolyfill__: any;
    }
}

interface IProps {}
interface IState {
    showModal: boolean;
    isLoading: boolean;
}
interface CartPackage {
    _id: string;
    packageId: string;
    isBundle: boolean;
    isUpsell: boolean;
    packagePurchaseType: string;
}

smoothscroll.polyfill();
class SuggestionUpsellPrompt extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;

    state: IState = {
        showModal: true,
        isLoading: false,
    };

    addUpsellToCart = async () => {
        this.setState({ isLoading: true });
        const { topSuggested } = this.context;
        const { price, title, image } = topSuggested.upsoldPackage;

        const discountedPrice =
            price -
            (topSuggested.discountType === 'FIXED'
                ? topSuggested.discountValue
                : (price * topSuggested.discountValue) / 100);

        const packageData = {
            _id: topSuggested._id,
            title,
            image,
            price,
            discountedPrice,
            isUpsell: true,
            isBundle: false,
            packagePurchaseType: 'new',
            packageId: topSuggested.upsoldPackageId,
            upsellLocation: 'suggestion',
        };

        let packages: CartPackage[] = Array.from(this.context.packages);

        let isToPushPackage = true;

        packages = packages.map((pkg: CartPackage) => {
            if (pkg.packageId === topSuggested.upsoldPackageId) {
                isToPushPackage = false;

                if (!pkg.isBundle) {
                    pkg = { ...packageData };
                } else {
                    EventBus.dispatch('alert', {
                        type: 'error',
                        title: 'Package Already Exists!',
                        message: 'Same Package already added in cart!',
                    });
                }
            }

            return pkg;
        });

        if (isToPushPackage) {
            packages.push(packageData);
        }

        await this.context.updateCart({
            packages: [...packages.filter((pkg: CartPackage) => pkg.hasOwnProperty('packagePurchaseType'))],
        });

        EventBus.dispatch('toast', {
            type: 'success',
            message: 'Item added to the cart',
        });

        this.setState({ isLoading: false });
        this.context.updateTopSuggestion();
        this.scrollTop();
    };

    scrollTop = () => {
        const modalBody = document.getElementById('suggestion-modal-body');
        if (modalBody) {
            window.__forceSmoothScrollPolyfill__ = true;
            modalBody.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    get getCartId() {
        const cartId = this.context.isAdminPreview
            ? getState('reuPreviewCheckoutCartId')
            : getState('reuCheckoutCartId');
        let id;
        if (this.context.isAdminPreview) {
            id = cartId;
        } else {
            const user = getState('user');
            id = user?._id ?? cartId;
        }

        return { cartId, id };
    }

    getIsLoggedInFlag = () => {
        if (this.context.isAdminPreview) {
            return false;
        } else {
            return !!getState('user')?._id;
        }
    };

    onDenyUpsellCart = async () => {
        const { id } = this.getCartId;
        if (this.context.isAdminPreview) {
            this.context.updateTopSuggestion();
        } else {
            const { success } = await Api.call(
                'PATCH',
                `checkout/cart/${id}/no-thanks?isLoggedIn=${this.getIsLoggedInFlag()}`,
                {
                    packageIds: [this.context.topSuggested.upsoldPackageId],
                    device: Utility.isMobileView() ? 'mobile' : 'desktop',
                }
            );
            if (success) {
                this.context.updateTopSuggestion();
            }
        }
        this.scrollTop();
    };

    render() {
        const { topSuggested } = this.context;
        let discountedPrice = topSuggested?.upsoldPackage?.price;
        if (topSuggested?.discountLocations?.suggestion) {
            if (topSuggested.discountType === 'FIXED') {
                discountedPrice -= topSuggested?.discountValue;
            } else {
                discountedPrice -= (topSuggested?.upsoldPackage?.price * topSuggested?.discountValue) / 100;
            }
        }

        return (
            <div className='suggestion-upsell'>
                <Modal className='suggestion-upsell-modal' show={this.state.showModal}>
                    <Modal.Header>
                        <Modal.Title>Frequently bought together!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body id='suggestion-modal-body'>
                        <div className='suggestion-upsell__modal-body-wrapper'>
                            <div className='suggestion-upsell__item'>
                                <main className='suggestion-upsell__itemData'>
                                    <span
                                        className='suggestion-upsell__description'
                                        dangerouslySetInnerHTML={{ __html: topSuggested?.description }}
                                    />
                                </main>
                                {topSuggested.image[0] && (
                                    <div className='suggestion-upsell__itemImage'>
                                        <img src={topSuggested.image[0]} alt={'Not found'} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='addtocart-button-wrapper'>
                            <div className='price-wrapper'>
                                {discountedPrice !== topSuggested.upsoldPackage.price &&
                                topSuggested.discountLocations.suggestion ? (
                                    <>
                                        <h5>{global.USCurrency.format(topSuggested.upsoldPackage.price)}</h5>
                                        <h4>{global.USCurrency.format(discountedPrice)}</h4>
                                    </>
                                ) : (
                                    <h4>{global.USCurrency.format(discountedPrice)}</h4>
                                )}
                            </div>

                            <div className='button-wrapper'>
                                <Button
                                    className={`bp add-to-cart`}
                                    onClick={this.addUpsellToCart}
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? (
                                        <>
                                            <Spinner animation='border' size='sm' /> Adding
                                        </>
                                    ) : (
                                        `Add to cart`
                                    )}
                                </Button>
                                <div className='no-thanks-button-wrapper'>
                                    <Button
                                        className={`btn ${this.state.isLoading ? 'button--disabled' : ''}`}
                                        onClick={this.onDenyUpsellCart}
                                        disabled={this.state.isLoading}
                                    >
                                        No thanks
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default SuggestionUpsellPrompt;
