import React, { Component } from 'react';
import LessonContext from '../../../../LessonContext';
import NavigationCalendar, { TCalendarLegend } from 'src/components/_New/Navigation/Calendar';
import {
    CurrentQuestion,
    AnsweredQuestion,
    UnansweredQuestion,
    CorrectQuestion,
    IncorrectQuestion,
} from './ItemComponents';
import {
    AnsweredQuestionLegend,
    CorrectQuestionLegend,
    IncorrectQuestionLegend,
    UnansweredQuestionLegend,
} from './LegendComponents';
import './QuizCalendar.scss';
import { EventBus } from 'src/helpers/new';
import { LockedCard } from '../../Footer/Navigation/ItemComponents';

interface IProps {
    course: any;
    questions: any[];
    activeCardIndex: number;
    setActiveCardIndex: (e: number) => any;
    answers: any[];
    isReview: boolean;
    allowSkip: boolean;
    isPreview: boolean;
}

interface IState {
    expanded: boolean;
}

interface ICalendarQuestion {
    index: number;
    currentQuestion: boolean;
    answered: boolean;
    colour: 'green' | 'red' | '';
    correct: boolean;
    locked: boolean;
}

export default class QuizCalendar extends Component<IProps, IState> {
    static contextType = LessonContext;

    state: IState = {
        expanded: false,
    };

    handleToggleClick = () => {
        this.setState((prevState) => ({
            ...prevState,
            expanded: !prevState.expanded,
        }));
    };

    get calendar(): { questions: ICalendarQuestion[]; legend: TCalendarLegend } {
        const { questions, answers, activeCardIndex } = this.props;

        const calendarQuestions = questions.map((question, index): ICalendarQuestion => {
            let locked = true;
            if (this.props.isPreview) {
                locked = false;
            } else {
                if (!this.props.allowSkip) {
                    if (answers[this.props.activeCardIndex] !== undefined) {
                        if (index === this.props.activeCardIndex + 1 || index <= answers.length) {
                            locked = false;
                        } else {
                            locked = true;
                        }
                    }
                } else {
                    locked = false;
                }
            }

            return {
                index,
                currentQuestion: index === activeCardIndex,
                answered: answers[index] !== undefined,
                colour: answers[index] !== undefined ? 'green' : '',
                correct: false,
                locked,
            };
        });

        const legend: TCalendarLegend = [
            {
                matches: (question: ICalendarQuestion) =>
                    !question.currentQuestion && !question.answered && question.locked,
                onClick: () => {
                    EventBus.dispatch('alert', {
                        type: 'error',
                        title: 'Skipping question not allowed!',
                        message:
                            "You can't skip this question, please answer all of the questions in order to submit your attempt",
                    });
                },
                legendComponent: null,
                itemComponent: LockedCard,
            },
            {
                matches: (question: ICalendarQuestion) => question.currentQuestion,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: null,
                itemComponent: CurrentQuestion,
                alwaysVisible: true,
            },
            {
                matches: (question: ICalendarQuestion) => question.answered,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: AnsweredQuestionLegend,
                itemComponent: AnsweredQuestion,
            },
            {
                matches: () => true,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: UnansweredQuestionLegend,
                itemComponent: UnansweredQuestion,
            },
        ];

        return { questions: calendarQuestions, legend };
    }

    get reviewCalendar(): { questions: ICalendarQuestion[]; legend: TCalendarLegend } {
        const { questions, answers, activeCardIndex } = this.props;

        const calendarQuestions = questions.map((question, index): ICalendarQuestion => {
            const correct = answers[index] === question.correctOptionIndex;

            return {
                index,
                currentQuestion: index === activeCardIndex,
                answered: answers[index] !== undefined,
                colour: correct ? 'green' : 'red',
                correct,
                locked: false,
            };
        });

        const legend: TCalendarLegend = [
            {
                matches: (question: ICalendarQuestion) => question.currentQuestion,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: null,
                itemComponent: CurrentQuestion,
                alwaysVisible: true,
            },
            {
                matches: (question: ICalendarQuestion) => question.correct,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: CorrectQuestionLegend,
                itemComponent: CorrectQuestion,
            },
            {
                matches: () => true,
                onClick: (_, index: number) => {
                    this.handleToggleClick();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: IncorrectQuestionLegend,
                itemComponent: IncorrectQuestion,
            },
        ];

        return { questions: calendarQuestions, legend };
    }

    render() {
        const { questions, legend } = this.props.isReview ? this.reviewCalendar : this.calendar;

        return (
            <>
                <div className={'navigation-calendar-wrapper'} onClick={this.handleToggleClick}>
                    <i className='fa fa-calendar' />
                    <span className={'navigation-slide-index-text'}>
                        {this.props.activeCardIndex + 1} of {questions.length}
                    </span>
                </div>
                {!this.state.expanded && (
                    <div
                        className='card-container__feedback__toggle card-container__feedback__up-icon-toggle'
                        onClick={this.handleToggleClick}
                    >
                        <i className='fa fa-chevron-up up-icon-padding' />
                    </div>
                )}
                {this.state.expanded && (
                    <div className='navigation-calendar-slide-index-wrapper'>
                        <div className='card-container__feedback__toggle' onClick={this.handleToggleClick}>
                            <i className='fa fa-chevron-down' />
                        </div>
                        <div className={'calendar-content-wrapper'}>
                            <NavigationCalendar basic={questions} legend={legend} />
                        </div>
                    </div>
                )}
            </>
        );
    }
}
