interface IState {
    questions: any[];
}

interface ActionType {
    type: 'SET_SECRET_QUESTIONS';
    payload: any;
}

export const secretQuestion = (
    state: IState = {
        questions: [],
    },
    action: ActionType
) => {
    if (action.type === 'SET_SECRET_QUESTIONS') {
        return {
            ...state,
            questions: [...action.payload],
        };
    } else {
        return state;
    }
};
