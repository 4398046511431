import React, { Component } from 'react';
import CheckoutContext from '../CheckoutContext';
import { Api, EventBus, Utility } from 'src/helpers/new';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import CouponArea from '../StepCheckout/OrderSummary/CouponArea';
import './TaxCalculation.scss';

interface IProps extends RouteComponentProps {
    couponDiscount: number;
    promoDiscount: number;
    totalPrice: number;
    getCartId: any;
    getIsLoggedInFlag: any;
    isSinglePageCheckout: boolean;
}
interface IState {
    taxes: number;
}
class TaxCalculation extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    prevContext: any;
    packageId = '';
    bundleId = '';

    state: IState = {
        taxes: 0,
    };

    get isGetPriceCall() {
        const isValidContact = Utility.isNoEmptyFields(['firstName', 'lastName', 'email'], this.context.contact);
        const isValidBilling = Utility.isNoEmptyFields(['zipCode', 'state'], this.context.billingAddress);
        const isValidEmail = Utility.validateEmail(this.context.contact?.email);
        const isValiZipcode = Utility.validateZipCode(
            this.context?.billingAddress?.zipCode ?? this.context?.user?.billingAddress?.zipCode
        );
        const isStateSelected = this.context.billingAddress?.state !== 'none';
        return isValidContact && isValidBilling && isValidEmail && isValiZipcode && isStateSelected;
    }
    componentDidMount() {
        EventBus.on('set-tax-value', this.updateTaxString);
        const query = new URLSearchParams(this.props.location.search);
        const params = ['packageId', 'bundleId'];
        [this.packageId, this.bundleId] = params.map((param) => query.get(param) ?? '');

        const newCartData = this.props.location.state || this.packageId || this.bundleId;
        if (this.isGetPriceCall && !newCartData) {
            this.context.getPrice();
        }

        this.prevContext = this.context;
    }

    componentDidUpdate() {
        if (this.context.couponCode !== this.prevContext.couponCode || this.context.isPriceUpdate) {
            if (this.isGetPriceCall) {
                this.context.getPrice();
            }
            if (this.context.isPriceUpdate) {
                this.context.updateCheckoutState({ isPriceUpdate: false });
            }
        }
        this.prevContext = this.context;
    }

    componentWillUnmount() {
        EventBus.remove('set-tax-value', this.updateTaxString);
    }

    updateTaxString = (event: any) => {
        const taxes = event.detail;
        this.setState({
            taxes,
        });
    };

    render() {
        const { couponDiscount, promoDiscount, totalPrice, isSinglePageCheckout } = this.props;
        return (
            <>
                {(this.state.taxes && this.context.currentTab !== 0) || (this.state.taxes && isSinglePageCheckout) ? (
                    <Col className='col-wrapper'>
                        <span>Tax</span>
                        <span>{global.USCurrency.format(this.state.taxes)}</span>
                    </Col>
                ) : null}

                {couponDiscount ||
                promoDiscount ||
                (this.state.taxes && this.context.currentTab !== 0) ||
                (this.state.taxes && isSinglePageCheckout) ? (
                    <hr />
                ) : null}

                {isSinglePageCheckout && (
                    <Col className='apply-discount-wrapper'>
                        <CouponArea isPayment={true} />
                    </Col>
                )}

                <Col className='col-wrapper'>
                    <span className='bold-text'>Total to pay</span>
                    <span>{global.USCurrency.format(totalPrice + this.state.taxes)}</span>
                </Col>
            </>
        );
    }
}

export default withRouter(TaxCalculation);
