import React, { Component } from 'react';
import { Popover, Row, Col } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './ProfilePopOver.scss';
import NameAvatar from './NameAvatar';
import { Api, EventBus } from 'src/helpers/new';
import { clearState, getState } from 'src/helpers/localStorage';
import { set } from 'lodash';

export interface IUserLoggedIn {
    APP_VERSION: string;
    token: string;
    user?: {
        email: string;
        adminLevel: number;
        firstName: string;
        passwordExpiredAt: string;
        timeZone: string;
        userRole: string;
        lastName: string;
        image: string;
        file?: {
            fileName: string;
            url: string[];
        };
        _id: string;
        maintenance: {
            startTime: string;
            endTime: string;
            allOperationMsg: string;
            maintenanceScheduleMsg: string;
        };
        nmlsUserId?: string;
        enrolledBioSigTypes?: string[];
        secretQuestionsSubmittedAt?: Date;
    };
    maintenance?: {
        startTime: string;
        endTime: string;
        allOperationMsg: string;
        maintenanceScheduleMsg: string;
    };
}
export interface ILoggedIn {
    setLoggedIn: (payload: any) => void;
    loggedIn: IUserLoggedIn;
}
interface IProps extends RouteComponentProps, ILoggedIn {
    toggleProfileMenu: () => void;
    onSetShowUploadPhoto: (show: boolean) => void;
}
interface IState {}

class ProfilePopOver extends Component<IProps, IState> {
    state: IState = {};

    navigateToProfile = () => {
        this.props.history.replace('/profile/details');
    };

    showUploadPrompt = () => {
        this.props.onSetShowUploadPhoto(true);
    };

    confirmLogout = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Logout',
            body: `Are you sure want to Logout?`,
            confirm: {
                text: 'Yes',
                action: this.logOut,
            },
            cancel: {
                text: 'No',
                action: () => {},
            },
        });
    };

    logOut = async () => {
        if (getState('authToken')) {
            const { success, message } = await Api.call('POST', '/users/logout');
            if (success) {
                this.handleLogout(message);
            }
        } else {
            this.handleLogout('');
        }
    };

    handleLogout = (message: string) => {
        EventBus.dispatch('toast', {
            type: 'success',
            message: !!message ? message : "You've been successfully logged out.",
        });
        clearState();
        this.props.setLoggedIn({
            token: null,
            user: null,
        });
        EventBus.dispatch('show-exam-banner', { isResume: null });
        window.socket.emit('logout', '');
        if (window.chatsocket) {
            window.chatsocket.close();
            set(window, 'chatsocket', undefined);
        }
        this.props.history.replace('/');
    };

    render() {
        const { loggedIn } = this.props;
        return (
            <Popover placement='bottom' id='user-bar-profile-menu'>
                <Popover.Content>
                    <Row className='profile-container'>
                        <Col className='profile-details-container'>
                            <div className='profile-details-wrapper'>
                                <div className='profile-photo-container'>
                                    {loggedIn?.user?.file?.url[0] ? (
                                        <img
                                            className='profile-photo'
                                            src={loggedIn?.user?.file?.url[0]}
                                            alt='profile photo'
                                        />
                                    ) : (
                                        <NameAvatar
                                            className='profile-avatar'
                                            firstName={loggedIn?.user?.firstName ?? ''}
                                            lastName={loggedIn?.user?.lastName ?? ''}
                                        />
                                    )}
                                    <div className='overlay' onClick={this.showUploadPrompt}>
                                        <i className='fa-solid fa-pen' />
                                    </div>
                                </div>
                                <div className='profile-info'>
                                    <h3>
                                        {loggedIn?.user?.firstName ?? ''} {loggedIn?.user?.lastName ?? ''}
                                    </h3>
                                    <p className='profile-email'>{loggedIn?.user?.email}</p>
                                    <p className='profile-role'>
                                        <img
                                            src={process.env.PUBLIC_URL + '/user-group-solid.svg'}
                                            alt='User Group icon'
                                            className='bar-icon'
                                        />
                                        <span>{(loggedIn?.user?.userRole ?? 'student').toUpperCase()}</span>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='profile-navigation' onClick={this.navigateToProfile}>
                                <i className='fa-solid fa-circle-user' /> <span>My Profile</span>
                            </div>
                        </Col>
                        <Col className='profile-sign-out-wrapper' onClick={this.confirmLogout}>
                            <span>Sign Out</span>
                        </Col>
                    </Row>
                </Popover.Content>
            </Popover>
        );
    }
}

export default connect(
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    }
)(withRouter(ProfilePopOver));
