import React, { Component, ReactNode } from 'react';
import { CourseButton, IUserCourse } from '../CourseButton';
import { ProgressBar } from '../ProgressBar';
import './Course.scss';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Utility } from 'src/helpers/new';
import courseStatusIndicator from 'src/helpers/CourseStatusIndicator';
import { DateFormatHelper } from 'src/helpers/dateFormatHelper';
import ExamHelper from 'src/pages/Course/Stages/Exam/ExamHelper';
import ExtensionRepurchase from 'src/pages/Checkout/ExtensionRepurchase';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import shortEnglishHumanizer from 'src/helpers/shortEnglishHumanizer';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { isNil } from 'lodash';
const fileDownload = require('js-file-download');

interface IProps extends RouteComponentProps {
    course: IUserCourse;
    allCourses: IUserCourse[];
    packageId: string;
    userPackageSuspendedAt?: Date;
}

export interface IDisplayTooltip {
    isShowTooltip: boolean;
    Msg: string;
    icon: string;
}

interface IState {
    packageId: string;
}
class Course extends Component<IProps, IState> {
    state: IState = {
        packageId: '',
    };

    get getCertificateExpiry() {
        const { offerConditions } = this.props.course;

        return offerConditions &&
            (!offerConditions['certificateExpiry'] || offerConditions['certificateExpiry'] !== 'none')
            ? offerConditions['certificateExpiry']
            : '';
    }

    getExamStatus() {
        const { isShowTooltip, Msg, icon } = ExamHelper.processExamStatus(this.props.course);

        if (isShowTooltip) {
            return (
                <OverlayTrigger
                    placement={Utility.isMobileView() ? 'bottom' : 'right'}
                    overlay={<Tooltip id={`exam-status-tooltip`}>{Msg}</Tooltip>}
                    trigger={['hover', 'focus']}
                >
                    <img className='exam-status' src={`/${icon}`} alt='exam-status' />
                </OverlayTrigger>
            );
        } else if (!isShowTooltip) {
            return <img className='exam-status' src={`/${icon}`} alt='exam-status' />;
        }

        return <div className='exam-not-available' />;
    }

    formatDate = (date: string) => {
        const mainDate = DateFormatHelper.format(date, DateFormatHelper.formats.day);
        return date ? mainDate : '-';
    };

    downloadCertificate = (course: IUserCourse) => {
        if (course?.certificateData?.url) {
            axios
                .get(course.certificateData.url, {
                    responseType: 'blob',
                })
                .then((res: any) => {
                    fileDownload(res.data, course.certificateData.fileName);
                });
        }
    };

    onIndicatorClicked = (course: IUserCourse) => {
        if (course?.status === 'EXAM_PASSED') {
            this.downloadCertificate(course);
        }
    };

    renderStatus = (course: IUserCourse, examStatus: any, expiryDate?: string, certificateExpiry?: string) => {
        return (
            <div className='status'>
                {examStatus && (
                    <ConditionalWrapper
                        condition={course?.status === 'EXAM_PASSED' && isNil(course?.certificateData?.url)}
                        wrapper={(children: any) => (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`tooltip-next-lesson`}>
                                        {'Certificate not available for this course!'}
                                    </Tooltip>
                                }
                            >
                                {children}
                            </OverlayTrigger>
                        )}
                    >
                        <div
                            className={`course-status ${
                                course.status === 'EXAM_PASSED'
                                    ? ` ${
                                          isNil(course?.certificateData?.url)
                                              ? 'exam-certificate-btn--disabled'
                                              : 'pass-status'
                                      }`
                                    : 'all-status'
                            }`}
                            onClick={() => this.onIndicatorClicked(course)}
                        >
                            {course.status === 'EXAM_PASSED' ? (
                                <div>
                                    Certificate <Fa icon={faDownload} />
                                </div>
                            ) : (
                                examStatus
                            )}
                        </div>
                    </ConditionalWrapper>
                )}
                {expiryDate && <div className='course-status all-status expiry-date '>{expiryDate}</div>}
                {certificateExpiry && (
                    <div
                        className={`course-status all-status ${this.getCertificateExpiry ? 'certificate-expiry' : ''}`}
                        onClick={this.openCertificateRepurchase}
                    >
                        {certificateExpiry}
                    </div>
                )}
            </div>
        );
    };

    openCertificateRepurchase = () => {
        if (this.getCertificateExpiry) {
            this.setState({
                packageId: this.props.packageId,
            });
        }
    };

    render(): ReactNode {
        const { course, allCourses, packageId } = this.props;
        const examStatus = courseStatusIndicator.courseExamStatus(
            course.status,
            course.attemptLimit,
            course.examAttempts,
            course.examReviewStatus ?? ''
        );
        const expiryDate = courseStatusIndicator.getCourseExpiryDate(course.expiresAt);
        const certificateExpiry = courseStatusIndicator.getCertificateExpired(course.certificateExpiresAt);

        const totalHours = shortEnglishHumanizer(course?.lessons?.totalRequiredTime * 60_000, {
            units: ['h'],
            round: true,
        });
        const progressStrRow =
            totalHours == '0h'
                ? `${course.percentageProgress || 0}%`
                : `${course.percentageProgress || 0}% of
                    ${totalHours}`;

        return (
            <div className='course'>
                <Row className='justify-content-between'>
                    <Col sm={8}>
                        <div className='course-wrapper'>
                            <div className='course-content-wrapper'>
                                <h6>{course.title}</h6>
                                <ProgressBar
                                    progress={course.percentageProgress}
                                    displayProgress={progressStrRow}
                                    passedAt={
                                        course.passedAt && !ExamHelper.getCourseExamReview(course)
                                            ? this.formatDate(course.passedAt)
                                            : ''
                                    }
                                    isDisplayTooltip={
                                        totalHours != '0h' &&
                                        course.percentageProgress > 0 &&
                                        course.percentageProgress < 30
                                    }
                                />
                            </div>
                            {this.props.course?.courseType === 'standard' ? (
                                this.getExamStatus()
                            ) : (
                                <div className='exam-not-available' />
                            )}
                        </div>
                        {!Utility.isMobileView() &&
                            this.renderStatus(course, examStatus, expiryDate, certificateExpiry)}
                    </Col>
                    <Col
                        sm='auto'
                        className={Utility.isMobileView() && (examStatus || expiryDate) ? 'content-alignment' : ''}
                    >
                        {Utility.isMobileView() && this.renderStatus(course, examStatus, expiryDate, certificateExpiry)}
                        <CourseButton
                            userCourse={course}
                            allCourses={allCourses}
                            packageId={packageId}
                            userPackageSuspendedAt={this.props.userPackageSuspendedAt}
                        />
                    </Col>
                </Row>
                {this.state.packageId && (
                    <ExtensionRepurchase
                        redirectUrl={this.props.location.pathname}
                        userPackageId={this.state.packageId}
                        onClose={() => {
                            this.setState({ packageId: '' });
                        }}
                        {...this.props}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(Course);
