import React, { Component } from 'react';
import './MessageContainer.scss';
import { Col, Row } from 'react-bootstrap';
import NameAvatar from '../Menus/NameAvatar';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IUserLoggedIn } from '../Menus/ProfilePopOver';

export interface ChatMessage {
    _id?: string;
    chatConversation?: string;
    sender: {
        _id: string;
        firstName: string;
        lastName: string;
        image?: string;
        file?: {
            fileName: string;
            url: string[];
        };
    };
    message: string;
    timestamp: string;
}

interface IProps extends ChatMessage {
    currentUserId: string;
    loggedIn: IUserLoggedIn;
}

interface IState {
    showFullMessage: boolean;
}

class MessageContainer extends Component<IProps & RouteComponentProps, IState> {
    state: IState = {
        showFullMessage: false,
    };

    toggleReadMore = () => {
        this.setState((prevState) => ({
            showFullMessage: !prevState.showFullMessage,
        }));
    };

    parseTruncatedMessage = (str?: string) => {
        if (!str) return '';
        return str.replace(/\n/g, '<br />');
    };

    render() {
        const { message, timestamp, sender, currentUserId, loggedIn } = this.props;
        const { showFullMessage } = this.state;
        const truncatedMessage = showFullMessage ? message : message?.slice(0, 200);

        return (
            <Row className='message-item-container'>
                <Col
                    xs={1}
                    sm={1}
                    md={1}
                    className={`sender-photo ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                >
                    {sender?.file?.url[2] ? (
                        <img className='profile-photo' src={sender?.file?.url[2]} alt='profile photo' />
                    ) : (
                        <NameAvatar
                            className='profile-avatar'
                            firstName={sender?.firstName ?? ''}
                            lastName={sender?.lastName ?? ''}
                        />
                    )}
                </Col>
                <Col
                    xs={10}
                    sm={10}
                    md={10}
                    className={`message-container ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                >
                    <p className='sender-name'>{(sender?.firstName ?? '') + ' ' + (sender?.lastName ?? '')}</p>

                    <div
                        className='sender-message'
                        dangerouslySetInnerHTML={{ __html: this.parseTruncatedMessage(truncatedMessage) }}
                    />
                    <div className='bottom-panel'>
                        <div>
                            {!showFullMessage && message.length > 200 && (
                                <span className='read-more' onClick={this.toggleReadMore}>
                                    Read more...
                                </span>
                            )}
                        </div>
                        <div className='timestamp'>{DateTimeFormatHelper.format(timestamp, 'hh:mm A')}</div>
                    </div>
                </Col>
                <Col
                    xs={1}
                    sm={1}
                    md={1}
                    className={`sender-photo-hidden ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                >
                    {loggedIn?.user?.file?.url[0] ? (
                        <img className='profile-photo' src={loggedIn?.user?.file?.url[0]} alt='profile photo' />
                    ) : (
                        <NameAvatar
                            className='profile-avatar'
                            firstName={loggedIn?.user?.firstName ?? ''}
                            lastName={loggedIn?.user?.lastName ?? ''}
                        />
                    )}
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: any) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setCourseChat: (payload: any) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    }
)(withRouter(MessageContainer));
