import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './UpsellCartButton.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICartDetail } from '../../Home';
import { EventBus } from 'src/helpers/new';

interface IProps extends RouteComponentProps {
    cartDetails: ICartDetail;
}
interface IState {}
class UpsellCartButton extends Component<IProps, IState> {
    state: IState = {};

    onCartClick = () => {
        if (this.props.cartDetails?.itemsCount > 0) {
            this.props.history.replace(`/checkout?location=cart`);
        } else {
            EventBus.dispatch('toast', {
                type: 'warning',
                message: 'Your cart is empty',
            });
        }
    };

    render() {
        return (
            <div className='upsell-cart-button'>
                <span className='count'>{this.props.cartDetails?.itemsCount ?? 0}</span>
                <Button className='cart-button' onClick={this.onCartClick}>
                    <i className='fa-solid fa-cart-shopping'></i>
                </Button>
            </div>
        );
    }
}

export default withRouter(UpsellCartButton);
