import React from 'react';
import { ICourseChat, LessonCompletedContent, UnlockedNextLessonContent } from './CourseManager';
export interface IExamStatus {
    available: boolean;
    message: string;
}
export interface IContext {
    course: any;
    unlockedLessons?: string[];
    completedLessons?: string[];
    examStatus?: IExamStatus;
    unlockLesson?: (unlockedLessonContent: UnlockedNextLessonContent) => void;
    unlockLessonMessages?: (unlockedLessonMessage: UnlockedNextLessonContent) => void;
    completeLesson?: (userLessonContent: LessonCompletedContent) => void;
    unlockExam?: (unlockExam: IExamStatus) => void;
    syncCourseData?: (courseId: string) => void;
    isAdmin?: boolean;
    isSideBarHidden?: boolean;
    unlockNextLessonTooltip?: string;
    toggleSideBarHidden?: (value: boolean) => void;
    changeExpiresAt?: (expiresAt: any) => void;
    setCourseConversation?: (data: ICourseChat) => void;
    updateCourseInfo?: (key: string, value: string) => void;
}

const context: IContext = {
    course: null,
    unlockedLessons: [],
    completedLessons: [],
};

export default React.createContext(context);
