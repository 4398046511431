import React, { Component, ReactNode } from 'react';
import { Api, EventBus, Utility } from 'src/helpers/new';
import './ExamBanner.scss';
import { Button } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { first, isNil } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface ICurrentExam {
    startedAt: string[];
    timeLimit: number;
    title: string;
    userCourseId: string;
    _id: string;
}
interface IState {
    showBanner: boolean;
    timeRemaining: number;
    currentExam?: ICurrentExam;
}

interface IProps extends RouteComponentProps {
    isShowExamBanner: boolean | null;
    loggedIn: IUserLoggedIn;
    setLoggedIn: (payload: any) => void;
}

class ExamBanner extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showBanner: false,
            timeRemaining: 0,
        };
    }

    getTimeRemaining(currentExam: ICurrentExam): number {
        if (isNil(currentExam?.startedAt[0]) || !currentExam) return 0;
        return Utility.getCurrentRemainingTime(currentExam?.startedAt[0], currentExam.timeLimit);
    }

    componentDidMount = async () => {
        if (this.props.loggedIn.user && isNil(this.props.isShowExamBanner)) {
            await this.getActiveExam();
        }
    };

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            prevProps.loggedIn.user?._id !== this.props.loggedIn.user?._id ||
            (prevProps.isShowExamBanner !== this.props.isShowExamBanner && isNil(this.props.isShowExamBanner))
        ) {
            await this.getActiveExam();
        }
    }

    getActiveExam = async () => {
        const { success, response } = await Api.call('GET', '/users/exams/active');
        if (success && response.length > 0) {
            this.setState({
                showBanner: true,
                currentExam: first(response),
                timeRemaining: this.getTimeRemaining(first(response) as ICurrentExam),
            });
            EventBus.dispatch('show-exam-banner', { isResume: true });
        } else {
            this.setState({
                showBanner: false,
            });
            EventBus.dispatch('show-exam-banner', { isResume: false });
        }
    };

    navigateToExam = () => {
        const { currentExam } = this.state;
        if (currentExam && currentExam.userCourseId) {
            if (this.state.timeRemaining > 0) {
                const data = {
                    isResumeExam: true,
                };
                this.props.history.replace(`/courses/${currentExam.userCourseId}/exam-summary`, data);
            } else {
                this.props.history.replace(`/courses/${currentExam.userCourseId}/exam-summary`);
            }
        }
    };

    handleTimeExpired = () => {
        this.setState({ timeRemaining: 0 });
    };

    render(): ReactNode {
        const { showBanner, currentExam, timeRemaining } = this.state;
        return (
            showBanner &&
            currentExam && (
                <div className='exam-banner-container'>
                    <div className='banner-content'>
                        {timeRemaining > 0 ? (
                            <span>
                                {`Exam in-progress:`}
                                <Countdown
                                    date={DateTimeFormatHelper.getCurrentTimeStamp() + timeRemaining}
                                    onComplete={this.handleTimeExpired}
                                    renderer={({ hours, minutes, seconds }) => {
                                        return (
                                            <span>
                                                {hours.toString().padStart(2, '0')}:
                                                {minutes.toString().padStart(2, '0')}:
                                                {seconds.toString().padStart(2, '0')}
                                            </span>
                                        );
                                    }}
                                />{' '}
                                {`remaining`}
                            </span>
                        ) : (
                            <span>{`You've run out of time for the exam.`}</span>
                        )}

                        <Button className='bp' onClick={this.navigateToExam}>
                            {timeRemaining > 0 ? 'Resume Exam' : 'Check Summary'}
                        </Button>
                    </div>
                </div>
            )
        );
    }
}

export default connect((state: any) => ({ loggedIn: state.loggedIn }), {
    setLoggedIn: (payload: any) => ({
        type: 'SET_LOGGED_IN',
        payload,
    }),
})(withRouter(ExamBanner));
