import React, { Component, ReactNode } from 'react';
import { Api } from 'src/helpers/new';
import PackageCard, { IPackage } from './PackageCard';
import './PackageList.scss';
import { concat } from 'lodash';
import { Spinner } from 'src/components/Spinner';
import { Row, Col } from 'react-bootstrap';

interface Iprops {
    userId: string;
}
interface IState {
    packages: IPackage[];
    isLoading: boolean;
    page: number;
    isMoreLoading: boolean;
    totalPages?: number;
}

export default class PackageList extends Component<Iprops, IState> {
    state: IState = {
        packages: [],
        isLoading: true,
        page: 1,
        isMoreLoading: false,
    };
    componentDidMount() {
        this.loadUserPackages();
    }

    loadUserPackages = async (): Promise<void> => {
        const { success, response } = await Api.call('get', `users/${this.props.userId}/packages`);
        if (success) {
            this.setState({
                packages: response.docs,
                isLoading: false,
                totalPages: response.totalPages ?? this.state.page,
            });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    };

    listPackages = (userPackage: IPackage) => {
        return <PackageCard userPackage={userPackage} key={userPackage._id} />;
    };

    packagePaginate = async () => {
        if (this.state.page === this.state.totalPages) return;
        this.setState({ page: this.state.page + 1, isMoreLoading: true });

        const { success, response } = await Api.call(
            'GET',
            `users/${this.props.userId}/packages?page=${this.state.page + 1}`
        );

        if (success) {
            this.setState({
                isMoreLoading: false,
                packages: concat(this.state.packages, response.docs),
            });
        } else {
            this.setState({
                isMoreLoading: false,
            });
        }
    };

    render(): ReactNode {
        const { packages, isLoading, isMoreLoading } = this.state;

        return (
            <div className='package-list'>
                <h2>Your Courses</h2>
                <Row className='justify-content-between flex-column'>
                    <Col sm='auto'>{isLoading ? <Spinner /> : packages.map(this.listPackages)}</Col>
                    <Col sm='auto'>
                        {isMoreLoading ? (
                            <Spinner />
                        ) : (
                            !isLoading &&
                            this.state.page !== this.state.totalPages &&
                            this.state.totalPages && (
                                <div className='view-more-button'>
                                    <button onClick={this.packagePaginate}>View more</button>
                                </div>
                            )
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}
