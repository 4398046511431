import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { isNil } from 'lodash';

export default class courseStatusIndicator {
    static isCourseExpired(expiresAt: any) {
        return DateTimeFormatHelper.diff(
            DateTimeFormatHelper.currentDate(),
            DateTimeFormatHelper.getDate(expiresAt),
            'minutes'
        );
    }

    static courseExamStatus(status: string, attemptLimit: number, examAttempts: number, examReviewStatus: string) {
        if (status === 'FAILED' && attemptLimit === examAttempts) {
            return 'Exam failed';
        } else if (
            status === 'EXAM_PASSED' &&
            !isNil(examReviewStatus) &&
            ['APPROVED', 'NOT_REQUIRED'].includes(examReviewStatus)
        ) {
            return 'Exam passed';
        }
    }

    static getCourseExpiryDate(expiresAt: Date) {
        if (isNil(expiresAt)) {
            return ``;
        }
        const courseExpiredDate = DateTimeFormatHelper.format(expiresAt, 'MM/DD/YY');
        const daysRemaining = DateTimeFormatHelper.diff(
            DateTimeFormatHelper.currentDate(),
            DateTimeFormatHelper.getDate(expiresAt),
            'days'
        );
        if (this.isCourseExpired(expiresAt) > 1 && daysRemaining < 14) {
            return `Expires ${courseExpiredDate}`;
        } else if (this.isCourseExpired(expiresAt) < 1) {
            return `Expired`;
        }
    }

    static getCertificateExpired(certificateExpiresAt: Date) {
        if (
            certificateExpiresAt &&
            DateTimeFormatHelper.diff(
                DateTimeFormatHelper.currentDate(),
                DateTimeFormatHelper.getDate(certificateExpiresAt),
                'days'
            ) < 1
        ) {
            return 'Certificate Expired';
        }
    }
}
