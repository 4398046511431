import React, { Component } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import './CourseButton.scss';
import './ERModal.scss';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setState } from 'src/helpers/localStorage';
import { IUserCourse } from './CourseButton';
import ExtensionForm from './ExtensionForm';
import RepurchaseForm from './RepurchaseForm';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { isNil } from 'lodash';
import { Utility } from 'src/helpers/new';

interface IProps extends RouteComponentProps {
    showResaleOffers: boolean;
    togglePreview: () => void;
    allCourses: IUserCourse[] | undefined;
    getExamStatus?: (status: string) => 'Failed' | 'Complete' | 'Incomplete';
    userCourse?: IUserCourse;
    packageId?: string;
}

interface IState {
    modalFormData: any;
}

class ERModal extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modalFormData: {},
        };
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (prevProps.showResaleOffers !== this.props.showResaleOffers) {
            this.setState({
                modalFormData: {},
            });
        }
    };

    findRemainingDays = (date: Date) => {
        return Math.floor(
            moment(date).diff(new Date(), 'minutes') < 1 ? 0 : moment(date).diff(new Date(), 'minutes') / 1440
        );
    };

    getPurchaseType = (courseData: IUserCourse) => {
        if (
            courseData.status &&
            courseData.status === 'FAILED' &&
            courseData.attemptLimit === courseData.examAttempts
        ) {
            return 'fail';
        } else if (
            courseData.certificateExpiresAt &&
            DateTimeFormatHelper.diff(
                DateTimeFormatHelper.currentDate(),
                DateTimeFormatHelper.getDate(courseData.certificateExpiresAt),
                'days'
            ) < 1
        ) {
            return 'certificateExpiry';
        } else if (courseData.status && courseData.status === 'EXAM_PASSED') {
            return 'pass';
        } else if (
            courseData.expiresAt &&
            moment(courseData.expiresAt).diff(new Date(), 'minutes') < 1 &&
            !isNil(courseData.expiresAt)
        ) {
            return 'expiry';
        } else {
            return 'expiry';
        }
    };

    handleChange = (e: any) => {
        const modalValues = JSON.parse(e.target.value);

        if (
            this.state.modalFormData[e.target.name] &&
            JSON.stringify(this.state.modalFormData[e.target.name]) == JSON.stringify(modalValues)
        ) {
            const newObject = {
                ...this.state.modalFormData,
            };

            delete newObject[e.target.name];

            this.setState({
                modalFormData: { ...newObject },
            });
        } else {
            this.setState({
                modalFormData: {
                    ...this.state.modalFormData,
                    [e.target.name]: modalValues,
                },
            });
        }
    };

    handleRadioReset = () => {
        this.setState({
            modalFormData: {},
        });
    };

    handleSubmit = () => {
        let isCartItemExists = false;
        if (Object.keys(this.state.modalFormData).length > 0) {
            const data = {
                ...Object.values(this.state.modalFormData).filter((data: any) => {
                    if (data?.purchaseType !== 'no-repurchase') {
                        isCartItemExists = true;
                        return true;
                    } else {
                        return false;
                    }
                }),
            };

            if (isCartItemExists) {
                setState('cartPackageId', this.props.packageId);
                this.props.history.push(`/checkout`, data);
            } else {
                this.props.togglePreview();
            }
        }
    };

    isCheckOutButtonDisabled = () => {
        let enabled = false;
        if (Object.keys(this.state.modalFormData).length > 0) {
            Object.values(this.state.modalFormData).forEach((data: any) => {
                if (data?.purchaseType !== 'no-repurchase') {
                    enabled = true;
                }
            });
        }
        return !enabled;
    };

    checkDisabled = (courseData: IUserCourse, index: number) => {
        return false;
    };

    render() {
        const mainCourses = this.props.allCourses
            ? this.props.allCourses
            : this.props.userCourse
            ? [this.props.userCourse]
            : [];
        let subTotal = 0;
        Object.values(this.state.modalFormData ?? []).forEach((obj: any) => {
            subTotal += +obj.price;
        });

        return (
            <Modal
                size='lg'
                show={this.props.showResaleOffers}
                className='resale-options fullHeight-Scroll extension-model'
                onHide={this.props.togglePreview}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header className='header' closeButton>
                    <Modal.Title className='title'>
                        <img className='logo-img' src='/logo.png' alt='RealEstateU logo' />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-content'>
                    <div className='modal-container'>
                        <header>Choose how to continue your studies</header>
                        <div className='modal-offer-container'>
                            {mainCourses?.map((courseData: IUserCourse, index: number) => (
                                <Row className='modal-offer-row' key={index}>
                                    <Col className='modal-offer-details'>
                                        <div className='course-title'>{courseData.title}</div>
                                        <div className='course-days-remaining'>
                                            {courseData.expiresAt ? (
                                                <>
                                                    {this.findRemainingDays(courseData.expiresAt)}&nbsp;
                                                    {this.findRemainingDays(courseData.expiresAt) <= 1 ? 'day' : 'days'}
                                                    &nbsp;remaining
                                                </>
                                            ) : (
                                                'This course has no expiry'
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        {courseData.maximumCourseTimeFrame === 0 ||
                                        Utility.calculateRemainingAccessibleDays(courseData) > 7 ? (
                                            <>
                                                {!!this.getPurchaseType(courseData) ? (
                                                    //  @ts-ignore
                                                    courseData.offerConditions?.[[this.getPurchaseType(courseData)]] ===
                                                    'repurchase' ? (
                                                        <RepurchaseForm
                                                            courseData={courseData}
                                                            index={index}
                                                            checkDisabled={this.checkDisabled}
                                                            handleChange={this.handleChange}
                                                            modalFormData={this.state.modalFormData}
                                                        />
                                                    ) : //  @ts-ignore
                                                    courseData.offerConditions?.[[this.getPurchaseType(courseData)]] ===
                                                          'extension' &&
                                                      (courseData.extensionLimit
                                                          ? courseData.extensionLimit - courseData.extensionLimitUsed
                                                          : 1000000 - courseData.extensionLimitUsed) > 0 ? (
                                                        <ExtensionForm
                                                            courseData={courseData}
                                                            index={index}
                                                            checkDisabled={this.checkDisabled}
                                                            handleChange={this.handleChange}
                                                            modalFormData={this.state.modalFormData}
                                                        />
                                                    ) : (
                                                        <div className='no-option-message'>
                                                            * This course cannot be extended or repurchased.
                                                        </div>
                                                    )
                                                ) : courseData.offerOptions &&
                                                  courseData.expiresAt &&
                                                  courseData.offerOptions.extension.length > 0 &&
                                                  (courseData.extensionLimit
                                                      ? courseData.extensionLimit - courseData.extensionLimitUsed
                                                      : 1000000 - courseData.extensionLimitUsed) > 0 ? (
                                                    <ExtensionForm
                                                        courseData={courseData}
                                                        index={index}
                                                        checkDisabled={this.checkDisabled}
                                                        handleChange={this.handleChange}
                                                        modalFormData={this.state.modalFormData}
                                                    />
                                                ) : (
                                                    <div className='no-option-message'>
                                                        * This course cannot be extended or repurchased.
                                                    </div>
                                                )}
                                            </>
                                        ) : Utility.calculateRemainingAccessibleDays(courseData) !== 0 ? (
                                            <>
                                                <ExtensionForm
                                                    courseData={courseData}
                                                    index={index}
                                                    checkDisabled={this.checkDisabled}
                                                    handleChange={this.handleChange}
                                                    modalFormData={this.state.modalFormData}
                                                />
                                                <RepurchaseForm
                                                    courseData={courseData}
                                                    index={index}
                                                    checkDisabled={this.checkDisabled}
                                                    handleChange={this.handleChange}
                                                    modalFormData={this.state.modalFormData}
                                                />
                                            </>
                                        ) : (
                                            <RepurchaseForm
                                                courseData={courseData}
                                                index={index}
                                                checkDisabled={this.checkDisabled}
                                                handleChange={this.handleChange}
                                                modalFormData={this.state.modalFormData}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='footer'>
                    <div className='subtotal'>
                        subtotal&nbsp;&nbsp;<b>${subTotal}</b>
                    </div>
                    <Button disabled={this.isCheckOutButtonDisabled()} onClick={this.handleSubmit}>
                        Checkout
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(ERModal);
