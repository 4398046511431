import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Spinner } from 'react-bootstrap';
import UserLayout from '../../layouts/User';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import ProfileDetails from './ProfileDetails/ProfileDetails';
import ProfileCommissions from './ProfileCommissions/ProfileCommissions';
import ProfileAddress from './ProfileAddress/ProfileAddress';
import ProfileCertificates from './ProfileCertificates/ProfileCertificates';
import AccountContext from './AccountContext';
import { Api } from 'src/helpers/new';
import { getState } from 'src/helpers/localStorage';

class Profile extends Component {
    state = {
        notificationTabData: [],
        detailsTabData: {},
        failedToLoadMsg: '',
    };

    componentDidMount = () => {
        this.fetchUserDetails();
    };

    fetchUserDetails = async () => {
        const { response, message, success } = await Api.call('GET', '/users/' + getState('user')._id);

        if (success) {
            this.setState({
                detailsTabData: response,
                notificationTabData: response?.mailingLists,
            });
        } else {
            this.setState({
                failedToLoadMsg: message,
            });
        }
        return;
    };

    render() {
        const { notificationTabData, detailsTabData, failedToLoadMsg } = this.state;
        const { fetchUserDetails } = this;
        if (this.state.loading) {
            return (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        }

        const isShowCommision = process.env.REACT_APP_PROFILE_COMMISSION_SHOW == 'false' ? false : true;
        return (
            <div
                style={{
                    padding: '20px',
                    background: '#f5fafd',
                    height: '100%',
                }}
            >
                <AccountContext.Provider
                    value={{
                        notificationTabData,
                        detailsTabData,
                        failedToLoadMsg,
                        fetchUserDetails,
                    }}
                >
                    <UserLayout>
                        {[
                            '/profile/details',
                            '/profile/addresses',
                            '/profile/commissions',
                            '/profile/notifications',
                            '/profile/settings',
                            '/profile/certificates',
                        ].includes(this.props.location.pathname) && (
                            <Nav variant='tabs' activeKey={this.props.location.pathname}>
                                <Nav.Item key='profile'>
                                    <Nav.Link as={Link} eventKey={`/profile/details`} to={`/profile/details`}>
                                        Details
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item key='profile-address'>
                                    <Nav.Link as={Link} eventKey={`/profile/addresses`} to={`/profile/addresses`}>
                                        Addresses
                                    </Nav.Link>
                                </Nav.Item>
                                {isShowCommision && (
                                    <Nav.Item key='profile-commissions'>
                                        <Nav.Link
                                            as={Link}
                                            eventKey={`/profile/commissions`}
                                            to={`/profile/commissions`}
                                        >
                                            Commissions
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                <Nav.Item key='profile-certificates'>
                                    <Nav.Link as={Link} eventKey={`/profile/certificates`} to={`/profile/certificates`}>
                                        Certificates
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        )}
                        <div
                            style={{
                                background: '#fff',
                                padding: '20px',
                            }}
                            className='profile-wrapper'
                        >
                            <Switch>
                                <Route exact path='/profile/details' component={ProfileDetails} />
                                <Route exact path='/profile/addresses' component={ProfileAddress} />
                                <Route exact path='/profile/commissions' component={ProfileCommissions} />
                                <Route exact path='/profile/certificates' component={ProfileCertificates} />
                            </Switch>
                        </div>
                    </UserLayout>
                </AccountContext.Provider>
            </div>
        );
    }
}

export default connect((state) => ({
    loggedIn: state.loggedIn,
}))(withRouter(Profile));
