import React, { Component } from 'react';
import './Page/IFrame.page.scss';
import IFramePage, { IProps as IIFramePageProps } from './Page/IFrame.page';
import IFrameSlide from './Slide/IFrame.slide';
import CourseContext from 'src/pages/Course/CourseContext';

type TProps = IIFramePageProps;

export default class IFrame extends Component<TProps> {
    static readonly contextType = CourseContext;

    renderCard() {
        switch (this.context.course.lessonType) {
            case 'page':
                return <IFramePage {...this.props} />;
            case 'slide':
                return <IFrameSlide {...this.props} />;
            default:
                return <></>;
        }
    }

    render() {
        if (!this.context.course) return null;
        const { lessonType } = this.context.course;
        return (
            <div className={`iframe-${lessonType} theme-${(this.props.theme ?? '').toLowerCase()}`}>
                {this.renderCard()}
            </div>
        );
    }
}
