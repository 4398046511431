import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Cropper, { Area, Point } from 'react-easy-crop';
import './CropImageModal.scss';

interface IProps {
    imgCropModalOpen: boolean;
    displayUploadUrl: string;
    handleCropModal: (isopen: boolean, croppedImage: File) => void;
    uploadFilePath: File | null;
}
interface IState {
    zoom: number;
    crop: Point;
    croppedImage?: Area;
}

class CropImageModal extends Component<IProps, IState> {
    state: IState = {
        zoom: 1,
        crop: { x: 0, y: 0 },
    };

    onCropChange = (crop: Point) => {
        this.setState({ crop });
    };

    onZoomChange = (zoom: number) => {
        this.setState({ zoom });
    };

    onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
        this.setState({ croppedImage: croppedAreaPixels });
    };

    createImage = (url: string) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.src = url;
        });

    getRadianAngle = (degreeValue: number) => {
        return (degreeValue * Math.PI) / 180;
    };

    rotateSize = (width: number, height: number, rotation: number) => {
        const rotRad = this.getRadianAngle(rotation);
        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    };

    getCroppedImage = async (imageSrc: string, pixelCrop: any, fileName: string, fileType: string) => {
        const image: any = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }

        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(image.width, image.height, 0);
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.translate(-image.width / 2, -image.height / 2);
        ctx.drawImage(image, 0, 0);

        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        ctx.putImageData(data, 0, 0);

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (file: Blob | null) => {
                    if (file) {
                        resolve(new File([file], fileName, { type: fileType }));
                    } else {
                        reject(new Error('Failed to generate file.'));
                    }
                },
                fileType,
                0.1
            );
        });
    };

    handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newZoom = parseFloat(event.target.value);
        this.onZoomChange(newZoom);
    };

    render() {
        const { imgCropModalOpen, displayUploadUrl, uploadFilePath } = this.props;
        const { crop, zoom } = this.state;

        return (
            <>
                <Modal className='crop-photo-update-modal' show={imgCropModalOpen} backdrop={false}>
                    <Modal.Header>
                        <Modal.Title>Crop Profile Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='crop-container-wrapper'>
                            <div className='crop-container'>
                                <Cropper
                                    image={displayUploadUrl}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                    cropShape='round'
                                />
                            </div>
                            <Form className='image-zoom-wrapper'>
                                <Form.Group controlId='imageZoomSlider'>
                                    <Form.Label>Zoom:</Form.Label>
                                    <Form.Control
                                        type='range'
                                        min='1'
                                        max='3'
                                        step='0.1'
                                        value={zoom}
                                        onChange={this.handleZoomChange}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='button-wrapper'>
                            <Button
                                className={`bp confirm-profile`}
                                onClick={async () => {
                                    const croppedImage: any = await this.getCroppedImage(
                                        this.props.displayUploadUrl,
                                        this.state.croppedImage,
                                        uploadFilePath?.name ?? '',
                                        uploadFilePath?.type ?? ''
                                    );

                                    this.props.handleCropModal(false, croppedImage);
                                    this.setState({ zoom: 1 });
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default CropImageModal;
