import React, { Component } from 'react';
import { Spinner } from 'src/components/Spinner';
import { Api, EventBus } from 'src/helpers/new';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './Checkout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getState } from 'src/helpers/localStorage';
import './Stripe.scss';
import { BillingAddress, Contact } from 'src/pages/Checkout/CheckoutTypeHelper';

interface LocationState {
    billingAddress?: BillingAddress;
    contact?: Contact;
    isSinglePageCheckout: boolean;
}

interface IProps {
    match: {
        params: {
            packageId: string;
            stripePaymentMethod: string;
        };
    };
}

type Tprops = IProps & RouteComponentProps;

interface IState {
    isLoading: boolean;
    cart: any;
    paymentIntentId: string;
    title: string;
    zipCode: string;
    country: string;
    orderId: string;
    clientSecret: string;
    packages: string[];
    pkgs: any[];
    tax: number;
}

class Stripe extends Component<Tprops, IState> {
    state = {
        isLoading: true,
        cart: {},
        paymentIntentId: '',
        title: '',
        zipCode: '',
        country: '',
        orderId: '',
        clientSecret: '',
        packages: [],
        pkgs: [],
        tax: 0,
    };
    stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

    async componentDidMount() {
        this.loadData();
    }

    get getTotalPrice() {
        const { pkgs } = this.state;
        let totalPrice = 0;
        pkgs.forEach((pkg: any) => {
            totalPrice += pkg.discountedPrice ?? pkg.price;
        });

        return totalPrice;
    }

    async loadData() {
        const cartId = getState('reuCheckoutCartId');
        const query = new URLSearchParams(this.props.location.search);
        const location = query.get('location');
        const stripePaymentMethod = this.props.match.params.stripePaymentMethod;

        if (!cartId) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: 'Cart not found.',
            });
            this.props.history.push('/');
        }
        await this.getTax();
        const id = getState('user')?._id ? getState('user')?._id : cartId;
        const { success, response } = await Api.call(
            'GET',
            `checkout/cart/initiatepayment/${id}/stripe?stripePaymentMethod=${stripePaymentMethod}&location=${location}&isLoggedIn=${!!getState(
                'user'
            )?._id}`
        );

        if (success) {
            const packages = response.cart.packages.map((item: any) => item.packageId);
            this.setState({
                isLoading: false,
                cart: response.cart,
                paymentIntentId: response.paymentIntentId,
                title: response.cart?.packages[0]?.title,
                zipCode: response.cart?.billingAddress?.zipCode,
                country: response.cart?.billingAddress?.country,
                orderId: response.orderId,
                clientSecret: response.clientSecret,
                packages: packages,
                pkgs: [...(response.cart?.packages ?? []), ...(response.cart?.courses ?? [])],
            });
        }
    }

    getTax = async () => {
        const cartId = getState('reuCheckoutCartId');
        const query = new URLSearchParams(this.props.location.search);
        const location = query.get('location');
        const locationState = (this.props.location?.state as LocationState) || {};

        const id = getState('user')?._id ? getState('user')?._id : cartId;
        const { success, response } = await Api.call(
            'POST',
            `checkout/cart/${id}/getPrice?location=${location}&isLoggedIn=${!!getState('user')?._id}`,
            {
                singlePageCheckout: locationState?.isSinglePageCheckout ?? false,
                billingAddress: locationState?.billingAddress ?? {},
                contact: locationState.contact ?? {},
            }
        );

        if (success) {
            this.setState({
                tax: response.tax,
            });
        }
    };

    onBackToSummaryPage = async () => {
        const { success } = await Api.call('put', `orders/${this.state.orderId}`);

        if (success && this.props.history) {
            this.props.history.replace(`/checkout?location=cart`);
        }
    };

    render() {
        const { isLoading, zipCode, title, country, orderId, clientSecret, paymentIntentId } = this.state;
        if (isLoading) {
            return <Spinner />;
        } else {
            const { packageId } = this.props.match.params;

            const elementsOptions: any = {
                clientSecret: clientSecret,
                appearance: {
                    theme: 'none',
                    variables: {
                        colorPrimary: '#0570de',
                        colorBackground: '#ffffff',
                        colorText: '#30313d',
                        colorDanger: '#df1b41',
                        fontSizeBase: '16px',
                        fontFamily: 'Ideal Sans, system-ui, sans-serif',
                        spacingUnit: '7.5px',
                        borderRadius: '4px',
                    },
                },
            };

            return (
                <div className='stripe-checkout-container'>
                    <div className='product-title'>
                        <h4>{title}</h4>
                    </div>

                    <div className='stripe-checkout-main'>
                        <div className='stripe-total-container'>
                            <span>Total</span>
                            <span>${this.getTotalPrice + this.state.tax}</span>
                        </div>
                        <Elements stripe={this.stripePromise} options={elementsOptions}>
                            <CheckoutForm
                                packageId={packageId}
                                zipCode={zipCode}
                                country={country}
                                orderId={orderId}
                                paymentIntentId={paymentIntentId}
                                packages={this.state.packages}
                            />
                        </Elements>

                        <button className='return-stripe-back-button' onClick={this.onBackToSummaryPage}>
                            <i className='fa fa-chevron-left mr-1' /> {` `}
                            Change payment method
                        </button>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(Stripe);
