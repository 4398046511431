import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import './Mnemonic.slide.scss';
import MnemonicList from './MnemonicList';
import LessonContext from '../../../../LessonContext';
import { Api } from 'src/helpers/new';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';
import { IUserLessonCardData } from '../../../UserLessonCard';
import { isEmpty } from 'lodash';
interface IRouteProps {
    lessonId: string;
}

export interface IProps {
    mnemonicList: any[];
    heading?: string;
    subHeading?: string;
    content?: string;
    cardId: string;
    allowSkipNodes: boolean;
    theme?: string;
    userLessonCardData: IUserLessonCardData;
}
interface IMnemonicList {}
interface IState {
    activeItemIndex: number[] | null;
    title: string;
    message: string;
    viewedNodes: string[];
    totalNodes: number;
    isBlockedByNodes: boolean;
}
export type TProps = IProps & RouteComponentProps<IRouteProps>;

export class MnemonicCard extends Component<TProps, IState> {
    static readonly contextType = LessonContext;

    state: IState = {
        activeItemIndex: null,
        title: '',
        message: '',
        viewedNodes: this.props.userLessonCardData?.hotspot?.viewedNodes?.[this.props.cardId] ?? [],
        totalNodes: this.props?.mnemonicList?.reduce?.((count, row) => count + row.length, 0) ?? 0,
        isBlockedByNodes: false,
    };

    componentDidMount() {
        if (!this.props.allowSkipNodes) {
            this.setIsBlockedByNodes?.(true);
        }
    }

    componentDidUpdate(prevProps: TProps, prevState: IState) {
        if (prevProps.cardId !== this.props.cardId) {
            this.setState(
                {
                    viewedNodes: this.props.userLessonCardData?.hotspot?.viewedNodes?.[this.props.cardId] ?? [],
                    totalNodes: this.props?.mnemonicList?.reduce?.((count, row) => count + row.length, 0) ?? 0,
                },
                () => {
                    if (
                        !this.props.allowSkipNodes &&
                        this.state?.viewedNodes?.length !== this.state.totalNodes &&
                        this.state?.isBlockedByNodes !== true
                    ) {
                        this.setIsBlockedByNodes?.(true);
                    }
                }
            );
        }

        if (
            !this.props.allowSkipNodes &&
            this.state?.viewedNodes?.length === this.state.totalNodes &&
            this.state?.isBlockedByNodes !== false
        ) {
            this.setIsBlockedByNodes?.(false);
            this.setViewedNodes?.(this.props.cardId, this.state?.viewedNodes);
        }
    }

    setActiveItemIndex = (activeItemIndex: number[]) => async (e: any) => {
        const [rowIndex, itemIndex] = activeItemIndex,
            { title, content: message } = this.props.mnemonicList[rowIndex][itemIndex],
            viewedNodes = [...this.state.viewedNodes];

        if (activeItemIndex !== null && !viewedNodes.includes(`${rowIndex}-${itemIndex}`)) {
            const { cardId } = this.props,
                { lessonId } = this.props?.match?.params ?? '',
                { success, response } = await Api.call(
                    'post',
                    `/users/lessons/${lessonId}/cards/${cardId}/hotspots/${rowIndex}-${itemIndex}/viewed`
                );

            if (success) {
                if (response && !isEmpty(response.updatedCards)) {
                    this.context.updateUserLessonCards(response.updatedCards);
                }
                this.context.setTooltip(response.tooltip);
                viewedNodes.push(`${rowIndex}-${itemIndex}`);
            }
        }

        this.setState({
            activeItemIndex,
            title,
            message,
            viewedNodes,
        });
    };

    unsetActiveItemIndex = () => {
        this.setState({
            activeItemIndex: null,
            title: '',
            message: '',
        });
    };

    setViewedNodes = (cardId: string, viewedNodes: any) => {
        this.setState((prevState) => ({
            ...prevState,
            viewedNodes: viewedNodes,
        }));
    };

    setIsBlockedByNodes = (isBlockedByNodes: boolean) => {
        this.setState((prevState) => ({
            ...prevState,
            isBlockedByNodes,
        }));
    };

    render() {
        const { heading, subHeading, content, mnemonicList } = this.props,
            { activeItemIndex, title, message } = this.state;

        return (
            <>
                <SlideHeader heading={heading} subHeading={subHeading} />
                <div className='slide-wrapper'>
                    {content && <div className='content' dangerouslySetInnerHTML={{ __html: content }} />}
                    <MnemonicList
                        activeItemIndex={activeItemIndex}
                        rows={mnemonicList}
                        onItemClick={this.setActiveItemIndex}
                        viewedNodes={this.state.viewedNodes}
                    />
                    {activeItemIndex !== null && (
                        <div className='popup'>
                            <span onClick={this.unsetActiveItemIndex}>
                                <i className='fa-solid fa-times' />
                            </span>
                            <h1>{title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: message }} />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withRouter(MnemonicCard);
