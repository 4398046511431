import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { EventBus } from 'src/helpers/new';
import CourseContext from 'src/pages/Course/CourseContext';

interface IProps {
    lessonContext: any;
    activeCardIndex: number;
    visible: boolean;
    middleware: (e?: boolean) => boolean;
}
interface IRouteProps {
    courseId: any;
}
interface IState {
    disabled: string;
}
export type TProps = IProps & RouteComponentProps<IRouteProps>;

class PreviousButton extends Component<TProps, IState> {
    static contextType = CourseContext;
    state = {
        disabled: '',
    };

    get isPreviousLessonAvailable(): boolean {
        return this.context.unlockedLessons?.includes(this.props?.lessonContext?.previousLesson?._id);
    }

    get isFirstCard(): boolean {
        return this.props.activeCardIndex === 0;
    }

    get progressionBlocked(): boolean {
        if (this.isFirstCard) return !this.isPreviousLessonAvailable;
        else return false;
    }

    handleClick = () => {
        if (this.progressionBlocked) return;
        if (!!this.state.disabled) {
            return;
        }
        const proceedToPreviousLesson = typeof this.props.middleware === 'function' ? this.props.middleware() : true;
        if (!proceedToPreviousLesson) {
            return;
        }

        const { userChapterId: chapterId, _id: lessonId } = this.props.lessonContext.previousLesson;
        EventBus.dispatch('change-lesson', {
            lessonId: lessonId + '/last',
            chapterId: chapterId,
        });
    };
    render() {
        const { visible, activeCardIndex, children } = this.props;
        const { previousLesson } = this.props.lessonContext;
        if (activeCardIndex === 0 && (!previousLesson || !previousLesson._id)) return <></>;

        return (
            <div
                className={`lesson-button lesson-button--previous${!visible ? ' lesson-button--hidden' : ''}${
                    this.progressionBlocked ? ' lesson-button--disabled' : ''
                }`}
                onClick={(e) => {
                    !!visible && this.handleClick();
                }}
            >
                {children ? (
                    children
                ) : (
                    <>
                        <i className='fa-solid fa-chevron-left' />
                        Previous
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(PreviousButton);
