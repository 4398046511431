import React, { Component } from 'react';
import FormElement from './FormElement';
import './FormBuilder.scss';
import { isEmpty, isNil, each, first, filter, omit, some, values } from 'lodash';
import { EventBus } from 'src/helpers/new';

export default class FormBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            fields: Array.isArray(props.fields)
                ? Object.fromEntries(
                      props.fields.reduce((fields, field) => {
                          if (field.key) {
                              const fieldHasMultipleCheckboxes =
                                  field.inputType === 'checkbox' &&
                                  field?.extra?.multiple &&
                                  field?.extra?.options.length > 0;

                              if (fieldHasMultipleCheckboxes) {
                                  let multipleCheckboxFields = [];
                                  field.extra.options.forEach((option) => {
                                      multipleCheckboxFields.push({ [option.value]: false });
                                  });
                                  fields.push([field.key, multipleCheckboxFields]);
                              } else {
                                  fields.push([field.key, null]);
                              }
                          }

                          return fields;
                      }, [])
                  )
                : [],
        };
    }

    handleInputChange = (key, value) => {
        const val = {
            ...this.state.fields,
            [key]: value,
        };

        this.setState({
            fields: val,
            errors: omit(this.state.errors, [key]),
        });
    };

    muplitpleCheckboxChange = (fieldKey, checkboxKey, value) => {
        let allCheckboxOptions = this.state.fields[fieldKey];

        const val = {
            ...this.state.fields,
            [fieldKey]: allCheckboxOptions.map((thisItem) => {
                if (Object.keys(thisItem)[0] === checkboxKey) {
                    thisItem[checkboxKey] = value;
                }
                return thisItem;
            }),
        };

        this.setState({
            fields: val,
        });
    };

    handleMultipleChange = (item, multiple) => {
        this.setState({
            items: this.state.items.map((thisItem) => {
                if (item.uuid === thisItem.uuid) {
                    thisItem.extra.multiple = JSON.parse(multiple);
                }
                return thisItem;
            }),
        });
    };

    getLabel = (val) => {
        let errorMessage = val.errorMessage;

        if (!errorMessage) {
            switch (val.inputType) {
                case 'date of birth':
                    break;
                case 'dropdown':
                    break;
                case 'date':
                    break;
                case 'image':
                    errorMessage = `Please select ${val.label}`;
                    break;
                case 'file':
                    errorMessage = `Please upload file to continue.`;
                    break;
                case 'checkbox':
                    errorMessage = `Please check the box to continue.`;
                    break;
                case 'radio':
                    errorMessage = `Please check the button to continue.`;
                    break;
                default:
                    errorMessage = `Please enter ${val.label}`;
                    break;
            }
        }

        return errorMessage;
    };

    validate = (stateFields, propsFields) => {
        let errors = {};
        each(stateFields, (v, k) => {
            const value = first(filter(propsFields, (val, key) => val.key === k));
            let checkVal = v;
            if (value.inputType === 'checkbox') {
                if (Array.isArray(checkVal)) {
                    checkVal = some(checkVal, (obj) => values(obj).includes(true));
                }
            }

            if (value.required) {
                if (isNil(v) || v === '' || (value.inputType === 'checkbox' && !checkVal)) {
                    errors[k] = this.getLabel(value);
                }
            }
        });

        return errors;
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { onSubmit, fields } = this.props;
        const errors = this.validate(this.state.fields, fields);

        if (!isEmpty(errors)) {
            this.setState({ errors: errors });
        } else {
            if (onSubmit && typeof onSubmit === 'function') {
                onSubmit(this.state.fields);
            }
        }
    };

    onDisagreeClick = () => {
        EventBus.dispatch('toast', {
            type: 'warning',
            message: `You won't be able to continue without accepting the terms!`,
        });
        return;
    };

    render() {
        const { fields, header: Header, isSubmitButton } = this.props;

        return (
            <div className='form-builder'>
                {Header && <Header />}
                <main>
                    {Array.isArray(fields) && fields.length > 0 ? (
                        <div>
                            {fields.map((field, i) => (
                                <FormElement
                                    {...field}
                                    error={this.state.errors[field.key]}
                                    fieldKey={field.key}
                                    key={`${field.key}-${i}`}
                                    value={this.state.fields[field.key]}
                                    onChange={this.handleInputChange}
                                    onMultipleCheckboxChange={this.muplitpleCheckboxChange}
                                />
                            ))}
                        </div>
                    ) : (
                        <div />
                    )}
                </main>
                <footer>
                    {isSubmitButton ? (
                        <button className='bp' onClick={this.onSubmit}>
                            {this.props.submitText ?? 'Submit'}
                        </button>
                    ) : (
                        <div className='agree-disagree-button'>
                            <button className='bp agree-button' onClick={this.onSubmit}>
                                {this.props.submitText ?? 'I agree'}
                            </button>
                            <button className='bp disagree-button' onClick={this.onDisagreeClick}>
                                {this.props.submitText ?? 'I disagree'}
                            </button>
                        </div>
                    )}
                </footer>
            </div>
        );
    }
}
