import React from 'react';
import { Prompt } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import './RouteLeavingGuard.scss';
import { EventBus } from 'src/helpers/new';
export default class RouteLeavingGuard extends React.Component {
    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,

        avoidRouteLeavingGuard: false,
    };

    showModal = (location) =>
        this.setState({
            modalVisible: true,
            lastLocation: location,
        });

    closeModal = (callback) => {
        this.setState({
            modalVisible: false,
        });
    };

    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;

        if (nextLocation.state?.avoidRouteLeavingGuard === true) {
            this.setState(
                {
                    avoidRouteLeavingGuard: true,
                },
                () => {
                    this.props.navigate({
                        ...nextLocation,
                        state: {
                            ...nextLocation.state,
                            avoidRouteLeavingGuard: false,
                        },
                    });
                }
            );

            return false;
        }

        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    };

    handleConfirmNavigationClick = () => {
        const { navigate, inExam } = this.props;
        const { lastLocation } = this.state;

        if (lastLocation) {
            this.setState(
                {
                    confirmedNavigation: true,
                },
                () => {
                    if (inExam) {
                        EventBus.dispatch('end-exam-proctoring');
                    }
                    navigate(lastLocation.pathname + lastLocation.search);
                }
            );
        }
    };

    render() {
        const { when, inExam, inQuiz } = this.props;
        const { modalVisible, avoidRouteLeavingGuard } = this.state;
        return (
            <>
                <Prompt when={when && !avoidRouteLeavingGuard} message={this.handleBlockedNavigation} />
                <ConfirmationModal
                    show={modalVisible}
                    hideModal={this.closeModal}
                    confirmAction={this.handleConfirmNavigationClick}
                    titleText={inExam ? 'Leave Exam' : inQuiz ? 'Leave Quiz?' : 'Leave site?'}
                    bodyText={
                        inExam
                            ? [
                                  <span>
                                      Are you sure you want to exit the exam? Please note, the exam timer will continue
                                      running in the background!
                                      <br />
                                  </span>,
                                  <span>Your answers will be auto submitted once timer run out !!!</span>,
                              ]
                            : inQuiz
                            ? 'Are you sure you wish to leave? you will be able to resume later if your time has not expired'
                            : 'Changes you made will not be saved.'
                    }
                    confirmButtonText='Leave'
                    className={inExam ? 'exam-route-confimation' : ''}
                />
            </>
        );
    }
}
