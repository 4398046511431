import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api } from 'src/helpers/new';
import './ApplicationStatus.scss';

interface IProps extends RouteComponentProps {}
interface IState {
    allOperationMsg: string;
    endTime: string;
    maintenanceScheduleMsg: string;
    ongoingIncidentMsg: string;
    startTime: string;
}
export class ApplicationStatus extends Component<IProps, IState> {
    state: IState = {
        allOperationMsg: '',
        endTime: '',
        maintenanceScheduleMsg: '',
        ongoingIncidentMsg: '',
        startTime: '',
    };

    async componentDidMount() {
        const { success, response } = await Api.call('GET', '/settings/maintenance');
        if (success) {
            this.setState({
                ...response,
            });
        }
    }

    renderBlock = (type: string, data: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(`<div>${data}</div>`, 'text/html');
        const parentEle = doc.querySelector('div');
        const childCount = parentEle ? parentEle.childElementCount : 0;

        return (
            <div className={`status-card ${type}`}>
                <div className='status-content'>
                    {type === 'critical' ? (
                        <div className={`status-type-icon status-type-icon--${type}`}>
                            <i className='fa fa-triangle-exclamation fa-2xl' />
                        </div>
                    ) : (
                        <div className={`status-type-icon status-type-icon--${type}`}>
                            <i className='fa fa-circle-info fa-2xl' />
                        </div>
                    )}
                    {data && (
                        <span
                            className={`status-data ${childCount === 1 ? 'remove-content-space' : ''}`}
                            dangerouslySetInnerHTML={{ __html: data }}
                        />
                    )}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className='status-wrapper'>
                <div className='status-container'>
                    {this.state.allOperationMsg && this.renderBlock('info', this.state.allOperationMsg)}
                    {this.state.maintenanceScheduleMsg && this.renderBlock('alert', this.state.maintenanceScheduleMsg)}
                    {this.state.ongoingIncidentMsg && this.renderBlock('critical', this.state.ongoingIncidentMsg)}
                </div>
            </div>
        );
    }
}

export default withRouter(ApplicationStatus);
