import './scss/App.scss';
import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainLayout from './layouts/Main/Main';
import { Login, ResetPassword, ChangePassword, Profile, VerifyAccount, ApplicationStatus } from './pages/Account';
import { Home, Course } from './pages';
import { Notifications } from './pages/Notifications';
import { Policy } from './pages/Policy';
import { NotFound } from './pages/NotFound';
import Checkout from './pages/Checkout';
import Stripe from './pages/Checkout/StepCheckout/Tabs/Payment/Stripe/Stripe';
import Confirmation from './pages/Checkout/StepCheckout/Tabs/Payment/Confirmation/Confirmation';
import PublicProfileNotifications from './pages/Account/PublicProfileNotifications/PublicProfileNotifications';
import PreviewCourse from 'src/pages/Admin/Courses/Lesson/PreviewCourse';
import { Spinner } from './components/Spinner';
import ErrorBoundary from './GlobalHandlers/ErrorBoundry';
import CookieConfig from './layouts/Main/CookieConfig/CookieConfig';
import { Utility } from './helpers/new';

const Admin = lazy(() => import('./pages/Admin/Admin.jsx'));

export default class App extends Component {
    getUsersPermissions = (name) => Utility.getUsersPermissions(name);

    render() {
        if (!navigator.cookieEnabled) {
            return <CookieConfig />;
        }

        return (
            <ErrorBoundary>
                <Router>
                    <div id='app'>
                        <MainLayout>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/extension/:userPackageId' component={Home} />
                                <Route path='/courses/:courseId/:courseSection?' component={Course} />
                                <Route exact path='/login' component={Login} />
                                <Route exact path='/status' component={ApplicationStatus} />
                                <Route exact path='/profile/:tab' component={Profile} />
                                <Route exact path='/reset-password' component={ResetPassword} />
                                <Route exact path='/change-password' component={ChangePassword} />
                                <Route exact path='/verify-account' component={VerifyAccount} />
                                {this.getUsersPermissions('courses')?.includes('viewCourses') && (
                                    <Route
                                        path='/admin/preview/:courseId/:courseParam?/:chapterId?/(lessons)?/:lessonId?/(cards)?/:cardIndex?'
                                        component={PreviewCourse}
                                    />
                                )}
                                <Route
                                    path='/admin'
                                    render={() => (
                                        <Suspense fallback={<Spinner />}>
                                            <Admin />
                                        </Suspense>
                                    )}
                                />
                                <Route exact path='/notifications' component={Notifications} />
                                <Route exact path='/checkout/' component={Checkout} />
                                <Route exact path='/checkout/payment/stripe/:stripePaymentMethod' component={Stripe} />
                                <Route exact path='/checkout/payment/confirmation/:orderId' component={Confirmation} />
                                <Route path='/terms_of_service'>
                                    <Policy keyword='terms_of_service' title='Terms Of Service' />
                                </Route>
                                <Route path='/privacy_policy'>
                                    <Policy keyword='privacy_policy' title='Privacy Policy' />
                                </Route>
                                <Route path='/unsubscribe-notifications' component={PublicProfileNotifications}></Route>
                                <Route path='*' component={NotFound} />
                            </Switch>
                        </MainLayout>
                    </div>
                </Router>
            </ErrorBoundary>
        );
    }
}
