import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './SinglePageCheckout.scss';
import { Col, Row } from 'react-bootstrap';
import PaymentBlock from '../Common/PaymentBlock';
import PaymentSummary from '../StepCheckout/Tabs/Payment/PaymentSummary/PaymentSummary';
import { Spinner } from 'src/components/Spinner';
import OrderSummary from '../Common/OrderSummay';
import { Utility } from 'src/helpers/new';
import CheckoutContext from '../CheckoutContext';
import { Contact } from '../CheckoutTypeHelper';
import usStates from 'src/helpers/usStates';
import { concat, set } from 'lodash';
import { CheckoutValidateHelper } from '../StepCheckout/Tabs/CheckoutValidateHelper';

interface IProps extends RouteComponentProps {
    onSetTermsAndConditions: (value: string) => void;
    pkgTermsConditionsCheckboxToggle: (value: string) => void;
    status: string;
    getCartId: any;
    getIsLoggedInFlag: any;
}
interface IState {
    isShowOrderSummary: boolean;
    isCheckEmail: boolean;
}
class SinglePageCheckout extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    state: IState = {
        isShowOrderSummary: false,
        isCheckEmail: false,
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const contact = { ...this.context.contact };
        contact[e.target.name as keyof Contact] =
            e.target.name === 'email' ? e.target.value.toLowerCase() : e.target.value;
        const updateState =
            e.target.name === 'email'
                ? {
                      validation: { ...this.context.validation, emailMessage: '', inVaild: false },
                      contact: contact,
                  }
                : { contact: contact };

        this.setState({ isCheckEmail: true });
        this.context.updateCheckoutState(updateState);
    };

    toggleOrderSummary = () => {
        this.setState((prevState) => ({
            ...prevState,
            isShowOrderSummary: !prevState.isShowOrderSummary,
        }));
    };

    renderPaymentSummary = () => {
        const { isShowOrderSummary } = this.state;
        const { price, tax } = this.context;
        return (
            <Col
                className={`right-container ${
                    !isShowOrderSummary && Utility.isMobileView() ? 'show-summary-block' : ''
                }`}
            >
                <Col className='right-container-wrapper'>
                    {Utility.isMobileView() && (
                        <div className='order-summary-text'>
                            <button className='summary-text-wrapper' onClick={this.toggleOrderSummary}>
                                <span>{`${isShowOrderSummary ? 'Hide' : 'Show'} order summary`}</span>
                                {'  '}
                                <i className={`fa fa-chevron-${isShowOrderSummary ? 'up' : 'down'}`} />
                            </button>
                            <p>${(price + tax).toFixed(2)}</p>
                        </div>
                    )}
                    <Row
                        className={`right-content-wrapper ${
                            Utility.isMobileView() && !isShowOrderSummary ? 'hidden-container' : 'visible-container'
                        }`}
                    >
                        <Col className='payment-summary-wrapper'>
                            <PaymentSummary isSinglePageCheckout={true} />
                        </Col>
                        <hr className='summary-divider' />
                        <Col className='order-summary-wrapper'>
                            <OrderSummary
                                getCartId={this.props.getCartId}
                                getIsLoggedInFlag={this.props.getIsLoggedInFlag}
                                isSinglePageCheckout={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Col>
        );
    };

    onBillingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const user = { ...this.context.user };
        const billingAddress = { ...this.context.billingAddress };

        set(user, `billingAddress.${e.target.name}`, e.target.value);
        set(billingAddress, e.target.name, e.target.value);

        this.context.updateCheckoutState({
            user: user,
            billingAddress: billingAddress,
            validation: { ...this.context.validation, zipcodeMessage: '', inVaild: false },
        });
    };

    updatePrice = () => {
        const messages = { ...this.context.validation };
        if (!(this.context?.billingAddress?.zipCode ?? this.context?.user?.billingAddress?.zipCode)) return;
        if (
            !Utility.validateZipCode(
                this.context?.billingAddress?.zipCode ?? this.context?.user?.billingAddress?.zipCode
            )
        ) {
            messages.zipcodeMessage = 'Zip code allowed only for US origin.';
            messages.inVaild = true;
        } else {
            messages.zipcodeMessage = '';
            messages.inVaild = false;
        }
        this.context.updateCheckoutState({
            isPriceUpdate: true,
            validation: messages,
        });
    };

    checkedEmail = async () => {
        const messages = { ...this.context.validation };
        if (!Utility.validateEmail(this.context.contact?.email)) {
            messages.emailMessage = 'Invalid email.';
            messages.inVaild = true;
        } else if (this.state.isCheckEmail) {
            const { inUse } = await CheckoutValidateHelper.checkEmail(
                this.context.contact?.email,
                this.context.user?._id ?? ''
            );
            if (inUse) {
                messages.emailMessage = 'Email Already Registered';
                messages.inVaild = false;
            }
        } else {
            messages.emailMessage = '';
            messages.inVaild = false;
        }

        if (this.context.updateCheckoutState) {
            this.context.updateCheckoutState({
                validation: messages,
            });
            this.setState({ isCheckEmail: false });
        }
    };

    renderPaymentScreen = () => {
        const { status } = this.props;
        const { state } = this.context;
        if (status === 'LOADING') return <Spinner />;

        if (status === 'EMPTY') {
            return (
                <div className='empty-cart-wrapper'>
                    <h1 className='heading'>
                        The fastest and most affordable way to become a licensed real estate agent
                        {state ? ` in ${state}` : ''}!
                    </h1>
                    <div className='content-wrapper'>
                        <h4>Your cart is empty</h4>
                        <a href='https://realestateu.com/' target='_blank' rel='noreferrer'>
                            Browse our packages
                        </a>
                    </div>
                </div>
            );
        }

        const {
            contact,
            user,
            billingAddress,
            validation: { emailMessage, inVaild, zipcodeMessage },
        } = this.context;

        return (
            <>
                <Row className='single-page-checkout-heading-wrapper'>
                    <Col>
                        <h1 className='heading'>
                            The fastest and most affordable way to become a licensed real estate agent
                            {billingAddress?.state !== 'none' || user?.billingAddress?.state
                                ? ` in ${billingAddress?.state ?? user?.billingAddress?.state}`
                                : ''}
                            !
                        </h1>
                    </Col>
                </Row>
                <Row className='single-page-checkout-container'>
                    <Col
                        className={`left-container ${
                            !this.state.isShowOrderSummary && Utility.isMobileView() ? 'left-show-summary-block' : ''
                        }`}
                    >
                        <Row className='left-content-wrapper'>
                            <Col className='contact-wrapper'>
                                <h1>Contact</h1>
                                <div className='email-field-wrapper'>
                                    <input
                                        type='email'
                                        value={contact?.email}
                                        onChange={this.handleInputChange}
                                        name='email'
                                        id='cEmail'
                                        placeholder='Email'
                                        className={emailMessage && inVaild ? 'form_error' : ''}
                                        onBlur={this.checkedEmail}
                                    />
                                    {emailMessage && <p className='error'>{emailMessage}</p>}
                                </div>

                                <div className='field-wrapper'>
                                    <input
                                        className='margin-right-box'
                                        type='text'
                                        value={contact?.firstName}
                                        onChange={this.handleInputChange}
                                        name='firstName'
                                        id='cFirstName'
                                        placeholder='First name'
                                    />
                                    <input
                                        type='text'
                                        value={contact?.lastName}
                                        onChange={this.handleInputChange}
                                        name='lastName'
                                        id='clastName'
                                        placeholder='Last name'
                                    />
                                </div>
                                <div className='field-wrapper'>
                                    <select
                                        name='state'
                                        id='aState'
                                        className={`margin-right-box ${
                                            billingAddress?.state === 'none' || !user?.billingAddress?.state
                                                ? 'no-content-selected'
                                                : ''
                                        }`}
                                        value={billingAddress?.state ?? user?.billingAddress?.state ?? 'none'}
                                        onChange={this.onBillingChange}
                                        onBlur={this.updatePrice}
                                    >
                                        {concat([{ value: 'none', key: 'none' }], usStates).map(
                                            ({ key, value }: { key: string; value: string }) => {
                                                if (key === 'none') {
                                                    return (
                                                        <option value={value} key={key} disabled selected>
                                                            {`State`}
                                                        </option>
                                                    );
                                                }
                                                return (
                                                    <option value={key} key={key}>
                                                        {key}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                    <div className='zipcode-wrapper'>
                                        <input
                                            type='text'
                                            name='zipCode'
                                            value={billingAddress?.zipCode ?? user?.billingAddress?.zipCode}
                                            id='aZipCode'
                                            onChange={this.onBillingChange}
                                            onKeyPress={(event) => {
                                                if (!/\d/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder='Zip code'
                                            onBlur={this.updatePrice}
                                            className={zipcodeMessage && inVaild ? 'form_error' : ''}
                                        />
                                        {zipcodeMessage && <p className='error'>{zipcodeMessage}</p>}
                                    </div>
                                </div>
                            </Col>
                            <Col className='payment-wrapper'>
                                <h1>Payment</h1>
                                <PaymentBlock
                                    onSetTermsAndConditions={this.props.onSetTermsAndConditions}
                                    pkgTermsConditionsCheckboxToggle={this.props.pkgTermsConditionsCheckboxToggle}
                                    isSinglePageCheckout={true}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {this.renderPaymentSummary()}
                </Row>
            </>
        );
    };
    render() {
        return this.renderPaymentScreen();
    }
}

export default withRouter(SinglePageCheckout);
