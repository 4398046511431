import React, { Component } from 'react';
import Slide from './Slide';
import Quiz from './Quiz/Quiz';
import LessonContext from '../../LessonContext';
import { EventBus } from 'src/helpers/new';
import StudentPreviewControls from '../../StudentPreviewController/StudentPreviewControls';
import SideBarToggle from '../../NavigationSideBar/SideBarToggle/SideBarToggle';
import Header from './Header';
import './SlideContainer.scss';
import { Banner } from 'src/pages/Home/Banner';

interface IProps {
    course: any;
    lesson: any;
    latestAttemptData?: any;
    adminEssayCardIndex?: any;
}

interface IState {
    isQuiz: boolean;
    cardIndex: number;
    cardId: string;
    attemptId?: string;
}

export default class SlideContainer extends Component<IProps, IState> {
    static contextType = LessonContext;

    state: IState = {
        cardIndex: 0,
        isQuiz: false,
        cardId: '',
    };

    componentDidMount() {
        EventBus.on('enter-quiz', this.enterQuiz);
        EventBus.on('leave-quiz', this.leaveQuiz);
        EventBus.on('review-quiz', this.reviewQuiz);
    }

    componentWillUnmount() {
        EventBus.remove('enter-quiz', this.enterQuiz);
        EventBus.remove('leave-quiz', this.leaveQuiz);
        EventBus.remove('review-quiz', this.reviewQuiz);
    }

    enterQuiz = (event: any) => {
        const { cardId, cardIndex } = event.detail;
        this.setState({
            isQuiz: true,
            cardId,
            cardIndex,
        });
    };

    leaveQuiz = () => {
        this.setState({
            isQuiz: false,
            attemptId: '',
        });
        this.context.loadUpdatedLesson();
    };

    reviewQuiz = (e: Event) => {
        const { attemptId, cardId, cardIndex } = (e as CustomEvent).detail;
        this.setState({ isQuiz: true, attemptId, cardId, cardIndex });
    };

    render() {
        const { isQuiz, cardIndex, cardId, attemptId } = this.state;
        const { isAdminPreview, chapter, requiredTime } = this.context.lesson;
        const chapterName = chapter && chapter.length > 0 ? chapter[0]?.doc?.title : '';
        const courseTitle = this.props.course.title;

        return (
            <div className='slide-layout'>
                {isAdminPreview && (
                    <div className='slide-preview-settings-container'>
                        <StudentPreviewControls />
                    </div>
                )}
                <SideBarToggle hideOnOpen={true} />
                <div className={`slide-container`}>
                    <div className='slide-container__wrapper'>
                        <Header
                            lessonName={this.context.lesson.title}
                            lessonType={this.context.lesson.lessonType}
                            chapterName={chapterName}
                            courseTitle={courseTitle}
                            lessonId={this.context.lesson._id}
                            requiredTime={requiredTime}
                            isAdminPreview={isAdminPreview}
                        />
                        {!isAdminPreview && <Banner channel={'lessons'} />}
                        {isQuiz ? (
                            <Quiz cardId={cardId} course={this.props.course} attemptId={attemptId} withHeader={false} />
                        ) : (
                            <Slide
                                {...this.props}
                                quizCardIndex={cardIndex}
                                adminEssayCardIndex={this.props?.adminEssayCardIndex}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
