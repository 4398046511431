import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditialWrapper';
import CourseContext from 'src/pages/Course/CourseContext';

interface IProps {
    id: string;
    lessonContext: any;
    handleClick: () => void;
}

export default class NextLessonButton extends Component<IProps> {
    static contextType = CourseContext;

    get isLessonLocked(): boolean {
        return !this.context.unlockedLessons?.includes(this.props?.lessonContext?.nextLesson?._id);
    }

    get isShowTooltip(): boolean {
        return (
            this.context.unlockNextLessonTooltip &&
            !this.context.unlockedLessons?.includes(this.props?.lessonContext?.nextLesson?._id)
        );
    }

    get isNextLessonAvailble(): boolean {
        const { nextLesson } = this.props.lessonContext;
        return !nextLesson?._id && !!nextLesson;
    }

    get showTooltipContent(): any {
        return this.isNextLessonAvailble ? 'disabled' : this.showTooltip(this.context.unlockNextLessonTooltip ?? '');
    }

    showTooltip = (showTooltip: string) => {
        return <span dangerouslySetInnerHTML={{ __html: showTooltip }} />;
    };

    render() {
        return (
            <ConditionalWrapper
                condition={this.isNextLessonAvailble || this.isShowTooltip}
                wrapper={(children: any) => (
                    <OverlayTrigger overlay={<Tooltip id={`tooltip-next-lesson`}>{this.showTooltipContent}</Tooltip>}>
                        {children}
                    </OverlayTrigger>
                )}
            >
                <button
                    onClick={!this.isLessonLocked ? this.props.handleClick : () => {}}
                    className={`next ${this.isLessonLocked ? 'next-disabled' : ''}`}
                >
                    Next
                </button>
            </ConditionalWrapper>
        );
    }
}
