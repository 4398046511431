import moment, { Moment } from 'moment-timezone';

export class DateFormatHelper {
    static readonly formats = {
        default: 'MM/DD/YYYY, hh:mm:ss A',
        day: 'MM/DD/YYYY',
    };

    static getUserTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    static format(dateString: string | Moment, format?: string) {
        return moment(dateString).format(format || this.formats.default || 'll');
    }

    static utcToUserTimeZone(dateString: Moment, format?: string) {
        return moment(dateString)
            .tz(this.getUserTimeZone())
            .format(format || this.formats.default);
    }

    static userTimeZoneToUTC(dateString: string, format?: string) {
        return moment(dateString, format).tz(this.getUserTimeZone()).utc().format();
    }
}
