import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { GlobalHandlers } from './GlobalHandlers';
import './scss/index.scss';
import { store } from './store';
import { APP_VERSION } from 'src/helpers/new/AppConfig';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: APP_VERSION,
});

global.USCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

Number.prototype.cleanRound = function (dp) {
    return Math.round((this + Number.EPSILON) * 10 ** dp) / 10 ** dp;
};

// this is the actual start

ReactDOM.render(
    <Provider store={store}>
        <GlobalHandlers />
        <App />
    </Provider>,
    document.getElementById('root')
);
