import React, { Component } from 'react';
import CourseContext from 'src/pages/Course/CourseContext';
import { Header } from 'src/pages/Course/Stages/Lessons/Cards/Components';

export interface IIFrame {
    iframeLink: string;
    borderOption?: string;
    iframeHeight?: string;
}
export interface IProps {
    cardId: string;
    theme?: string;
    heading?: string;
    subHeading?: string;
    content?: string;
    iframe?: IIFrame;
}
interface IState {}

export default class IFramePage extends Component<IProps, IState> {
    static contextType = CourseContext;

    getBorderClz = () => {
        const { iframe } = this.props;
        if (iframe?.borderOption) {
            if (iframe?.borderOption === 'GRAY') {
                return 'gray-border-clz';
            } else if (iframe?.borderOption === 'NAVY') {
                return 'navy-border-clz';
            } else {
                return 'no-border-clz';
            }
        }
        return 'no-border-clz';
    };

    render() {
        const { heading, subHeading, content, iframe } = this.props;
        return (
            <>
                {(heading || subHeading) && <Header heading={heading} subHeading={subHeading} />}
                <div className={`iframe-wrapper ${heading || subHeading || content ? 'py-0' : ''}`}>
                    <iframe
                        className={`${this.getBorderClz()}`}
                        style={{
                            height: `calc(${iframe?.iframeHeight ?? 50}vh - 0px)`,
                        }}
                        src={iframe?.iframeLink}
                    />
                </div>

                {content && (
                    <div className='content'>
                        <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
                    </div>
                )}
            </>
        );
    }
}
