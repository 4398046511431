import React, { Component } from 'react';
import QuestionSlide from './Slide/Question.slide';
import CourseContext from 'src/pages/Course/CourseContext';
import { IUserLessonCardData } from '../../UserLessonCard';
import QuestionPage from './Page/Question.page';

interface IProps {
    cardIndex?: string;
    heading?: string;
    subHeading?: string;
    nextCardAvailable?: string;
    setNextCardAvailable?: string;
    bgColor?: string;
    fgColor?: string;
    questions?: any;
    questionAttempt?: any;
    id?: string;
    detailedQuestion?: string;
    questionType?: string;
    correctFeedback?: string;
    incorrectFeedback?: string;
    content?: string;
    result?: any;
    userAnswers?: any;
    theme?: string;
    userLessonCardData: IUserLessonCardData;
}

export default class Question extends Component<IProps> {
    static contextType = CourseContext;

    renderCard() {
        switch (this.context.course.lessonType) {
            case 'page':
                return <QuestionPage />;
            case 'slide':
                return <QuestionSlide {...this.props} />;
            default:
                return <></>;
        }
    }

    render() {
        return (
            <div
                className={`question-${this.context.course.lessonType} theme-${(
                    this.props.theme ?? ''
                ).toLocaleLowerCase()}`}
            >
                {this.renderCard()}
            </div>
        );
    }
}
