import React, { Component } from 'react';
import './CookieConfig.scss';
import { Accordion, Card } from 'react-bootstrap';

interface IProps {}
interface IState {
    isVisible: number;
}
export default class CookieConfig extends Component<IProps, IState> {
    browserNames = ['Chrome', 'Safari', 'Edge', 'Firefox'];
    state: IState = {
        isVisible: -1,
    };

    toggleAccordion = (idx: number) => {
        this.setState({ isVisible: idx === this.state.isVisible ? -1 : idx });
    };

    displayAccordionToggle = () => {
        return this.browserNames.map((value: string, idx: number) => {
            return (
                <>
                    <Accordion.Toggle
                        as={'span'}
                        eventKey={'' + idx}
                        className='browser-name'
                        onClick={() => {
                            this.toggleAccordion(idx);
                        }}
                    >
                        {value}
                        <i className={`ml-2 fa fa-chevron-${idx === this.state.isVisible ? 'up' : 'down'}`} />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={'' + idx}>
                        <Card.Body>{this.displayEnableSteps(idx)}</Card.Body>
                    </Accordion.Collapse>
                </>
            );
        });
    };

    displayImageSteps = (displayStr: string, imageName: string) => {
        return (
            <div className='content-container'>
                <span
                    dangerouslySetInnerHTML={{
                        __html: displayStr,
                    }}
                />
                <img src={process.env.PUBLIC_URL + '/' + imageName} alt='Safari setting' className='setting-image' />
            </div>
        );
    };

    displayEnableSteps = (idx: number) => {
        if (idx === 0) {
            return (
                <ol className='list-cookie-steps'>
                    <li>{`At the top-right, click the 'three dots' menu icon, then click 'Settings.'`}</li>
                    <li>{`Click 'Privacy and security.'`}</li>
                    <li>{`Click 'Third-party cookies'.`}</li>
                    <li>
                        {this.displayImageSteps(
                            `Select 'Allow third-party cookies' or 'Block third-party cookies in Incognito mode.'`,
                            `chrome-cookie-enable.png`
                        )}
                    </li>
                    <li>{`Reload RealEstateU URL`}</li>
                </ol>
            );
        } else if (idx === 1) {
            return (
                <ol className='list-cookie-steps'>
                    <li>
                        {this.displayImageSteps(
                            `At the top-left, click 'Safari', then click 'Settings' in the dropdown menu.`,
                            `safari-settings.png`
                        )}
                    </li>
                    <li>{`Click 'Privacy'.`}</li>
                    <li>
                        {this.displayImageSteps(
                            `Uncheck the option for 'Block all cookies' next to 'Cookies and website data'`,
                            `enable-safari-cookie.png`
                        )}
                    </li>
                    <li>{`Reload RealEstateU URL`}</li>
                </ol>
            );
        } else if (idx === 2) {
            return (
                <ol className='list-cookie-steps'>
                    <li>{`At the top-right, click the 'three dots' menu icon, then click 'Settings.'`}</li>
                    <li>{`Click 'Cookies and site permissions.'`}</li>
                    <li>{`Click 'Manage and delete cookies and site data.'`}</li>
                    <li>
                        {this.displayImageSteps(
                            `Click the toggle for 'Allow site to save and read cookie data (recommended).'`,
                            `edage-cookie-enable.png`
                        )}
                    </li>
                    <li>{`Reload RealEstateU URL`}</li>
                </ol>
            );
        } else if (idx === 3) {
            return (
                <ol className='list-cookie-steps'>
                    <li>{`At the top-right, click the 'three lines' menu icon, then click 'Settings.'`}</li>
                    <li>{`Click 'Privacy & Security.'`}</li>
                    <li>{this.displayImageSteps(`Click 'Standard.'`, `firefox-cookie-enable.png`)}</li>
                    <li>{`Reload RealEstateU URL`}</li>
                </ol>
            );
        }
    };

    render() {
        return (
            <div className='cookie-wrapper'>
                <header>
                    <img className='logo-img' src={'/logo.png'} alt='RealEstateU logo' />
                </header>
                <div className={`cookie-alert cookie-alert--visible`}>
                    <h1>{`Enable browser cookies to continue`}</h1>
                    <span
                        className='display-message'
                        dangerouslySetInnerHTML={{
                            __html: `It looks like your browser is blocking cookies. RealEstateU courses require browser cookies in order to work properly. See the instructions below for how to enable cookies for your browser.`,
                        }}
                    />

                    <Accordion className='display-browser-list'>{this.displayAccordionToggle()}</Accordion>
                </div>
            </div>
        );
    }
}
