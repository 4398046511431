import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './ErrorPage.scss';

interface IProps {}
interface IState {}

export default class ErrorPage extends Component<IProps, IState> {
    state: IState = {};

    onClick = () => {
        window.location.href = '/';
    };
    render() {
        return (
            <div className='customr-error-page-wrapper'>
                <header>
                    <img className='logo-img' src={'/logo.png'} alt='RealEstateU logo' />
                </header>
                <main>
                    <div className='content-wrapper'>
                        <i className='fa-solid fa-bug' />
                        <h1>Oh no!</h1>
                        <span>Something went wrong, Please try again !</span>
                        <button onClick={this.onClick}>Home Page</button>
                    </div>
                </main>
            </div>
        );
    }
}
