import React, { Component } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.scss';
import CustomHeader from './CustomHeader';

// https://reactdatepicker.com/#example-select-time-only

type DateType = null | Date | string;
interface IProps {
    onChange: (date: any) => any;
    onSelect?: (date: Date, event: React.SyntheticEvent<any> | undefined) => void;
    onHandleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => any;
    onHandleCalendarClose?: () => any;
    date: DateType;
    timeCaption?: string;
    isToShowTimeSelect?: boolean;
    isToShowOnlyTimeSelect?: boolean;
    timeIntervals?: number;
    dateFormat?: string;
    timeFormat?: string;
    isClearable?: boolean;
    isShowCalenderIcon?: boolean;
    closeOnScroll?: boolean;
    minDate?: null | Date | string;
    maxDate?: null | Date | string;
    dateMode?: 'lower' | 'upper' | 'both' | null;
    showDisabledMonthNavigation?: boolean;
    disabled?: boolean;
    placeholderText?: string;
    disabledKeyboardNavigation?: boolean;
    showWeekNumbers?: boolean;
    shouldCloseOnSelect?: boolean;
    minTime?: Date;
    maxTime?: Date;
    error?: string;
    label?: string;
    isFormElement?: boolean;
    description?: string;
    id?: string;
    isCustomHeader?: boolean;
    // unsupported will can support later
    // calendarContainer
    // onCalendarClose
    // onCalendarOpen
    // customInput
    // renderCustomHeader
    // renderDayContents --Custom Date tooltip on day hover
    // calendarClassName
    // className red border
    // dayClassName
    // timeClassName
    // customTimeInput
    // selectsRange
    // selectsStart
    // selectsEnd
    // excludeDates
    // selectsDisabledDaysInRange
    // inline
    // withPortal
    // excludeDateIntervals
    // excludeDates
    // showMonthYearPicker
    // excludeTimes
    // filterDate
    // filterTime
    // fixedHeight
    // onChangeRaw
    // highlightDates
    // includeDates
    // includeTimes
    // injectTimes
    // showTimeInput
    // locale
    // showFullMonthYearPicker
    // showTwoColumnMonthYearPicker
    // showFourColumnMonthYearPicker
    // showMonthDropdown
    // useShortMonthInDropdown
    // monthsShown
    // showYearDropdown
    // showPopperArrow
    // onBlur
    // openToDate
    // showQuarterYearPicker
    // readOnlyf
    // showPreviousMonths
    //  minTime={setHours(setMinutes(new Date(), 0), 17)}
    //  maxTime={setHours(setMinutes(new Date(), 30), 20)}
    // strictParsing
    // todayButton
    // showYearPicker
    // yearDropdownItemNumber
    // dropdownMode
    // yearItemNumber
    // calendarStartDay
    // form
}

interface IState {
    focused: boolean;
}

export default class DateTimePicker extends Component<IProps, IState> {
    renderCustomHeader = (params: ReactDatePickerCustomHeaderProps) => {
        const { dateMode } = this.props;
        return <CustomHeader params={params} dateMode={dateMode ?? ''} />;
    };
    render() {
        const {
            date,
            timeCaption,
            isToShowTimeSelect,
            isToShowOnlyTimeSelect,
            timeIntervals,
            dateFormat,
            timeFormat,
            isClearable,
            isShowCalenderIcon,
            closeOnScroll,
            minDate,
            maxDate,
            showDisabledMonthNavigation,
            disabled,
            placeholderText,
            disabledKeyboardNavigation,
            showWeekNumbers,
            shouldCloseOnSelect,
            minTime,
            maxTime,
            label,
            isFormElement,
            description,
            id,
            isCustomHeader,
        } = this.props;

        return (
            <>
                {this.props.label ? (
                    <label htmlFor={id}>
                        {isFormElement ? (
                            <label>
                                {label ?? ''}
                                {!!description && <small>{description}</small>}
                            </label>
                        ) : (
                            <strong>{label ?? ''}</strong>
                        )}
                    </label>
                ) : (
                    <></>
                )}
                <DatePicker
                    selected={date ? new Date(date) : null}
                    onChange={this.props.onChange}
                    showTimeSelect={isToShowTimeSelect ?? false}
                    showTimeSelectOnly={isToShowOnlyTimeSelect ?? false}
                    timeIntervals={timeIntervals ?? 15}
                    timeCaption={timeCaption ?? 'Time'}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    isClearable={isClearable ?? false}
                    showIcon={isShowCalenderIcon ?? false}
                    closeOnScroll={closeOnScroll ?? false}
                    minDate={minDate ? new Date(minDate) : null}
                    maxDate={maxDate ? new Date(maxDate) : null}
                    minTime={minTime}
                    maxTime={maxTime}
                    showDisabledMonthNavigation={showDisabledMonthNavigation ?? true}
                    disabled={disabled ?? false}
                    placeholderText={placeholderText ?? 'Click Here To Select Date'}
                    disabledKeyboardNavigation={disabledKeyboardNavigation ?? false}
                    showWeekNumbers={showWeekNumbers ?? false}
                    shouldCloseOnSelect={shouldCloseOnSelect ?? true}
                    onKeyDown={this.props.onHandleKeyDown}
                    onCalendarClose={this.props.onHandleCalendarClose}
                    onSelect={this.props.onSelect}
                    renderCustomHeader={isCustomHeader ? this.renderCustomHeader : undefined}
                />

                {this.props.error && <small className={'mt-2 form-builder__error-color'}>{this.props.error}</small>}
            </>
        );
    }
}
