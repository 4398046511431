import React, { Component } from 'react';
import ERModal from '../Home/packages/CourseButton/ERModal';
import { RouteComponentProps } from 'react-router-dom';
import { Api } from 'src/helpers/new';
import { Modal, Button } from 'react-bootstrap';
import './ExtensionRepurchase.scss';

interface IProps {
    redirectUrl?: string;
    userPackageId: string;
    onClose?: () => void;
}

type TProps = IProps & RouteComponentProps;

interface IState {
    showModal: boolean;
    isLoading: boolean;
    courses: any[];
    showErrorModal: boolean;
}

export default class ExtensionRepurchase extends Component<TProps, IState> {
    state = {
        showModal: false,
        isLoading: false,
        courses: [],
        showErrorModal: false,
    };

    togglePreview = () => {
        this.setState({ showModal: !this.state.showModal });
        if (this.props.redirectUrl) {
            this.props.history.replace(this.props.redirectUrl);
        } else {
            this.props.history.replace('/');
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    toggleErrorModal = () => {
        this.setState({ showErrorModal: !this.state.showErrorModal });
        if (this.props.redirectUrl) {
            this.props.history.replace(this.props.redirectUrl);
        } else {
            this.props.history.replace('/');
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    async componentDidMount() {
        this.setState({ isLoading: true });

        const { success, response } = await Api.call('GET', `/users/package/${this.props.userPackageId}`);

        if (success) {
            this.setState({
                isLoading: false,
                courses: response.courses ?? [],
                showModal: response.isValid,
                showErrorModal: !response.isValid,
            });
        }
    }

    render() {
        const { showModal } = this.state;

        return (
            <>
                <ERModal
                    showResaleOffers={showModal}
                    togglePreview={this.togglePreview}
                    allCourses={this.state.courses}
                    packageId={this.props.userPackageId}
                />
                <Modal
                    show={this.state.showErrorModal}
                    onHide={this.toggleErrorModal}
                    className='extension-error-modal'
                >
                    <Modal.Body>
                        <p>
                            <strong>You don&apos;t own this package</strong>
                        </p>
                        <p>
                            You can&apos;t extend this course since you don&apos;t own it. Please contact support if
                            further assistance is needed
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='bp' onClick={this.toggleErrorModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
