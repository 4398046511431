import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import CourseManager from 'src/pages/Course/CourseManager';
import { Lessons } from 'src/pages/Course/Stages';
import { Spinner } from 'src/components/Spinner';

interface IRouteProps {
    match: {
        params: {
            courseId: string;
            chapterId?: string;
            lessonId?: string;
        };
    };
    location: {
        search: {
            chapterId: string;
            lessonId: string;
        };
    };
    history: {
        push: (path: string) => void;
        replace: (path: string) => void;
    };
}

interface IState {
    chapters: IChapterLesson;
    lessons: IChapterLesson;
    isLoading: boolean;
    chapterId: string;
    lessonId: string;
}

interface IChapterLesson {
    [key: string]: string;
}

interface IUserChapter {
    _id: string;
    courseChapterId: string;
}

interface IUserLesson {
    _id: string;
    courseLessonId: string;
}

export default class PreviewCourse extends Component<IRouteProps, IState> {
    state: IState = {
        chapters: {},
        lessons: {},
        isLoading: true,
        chapterId: '',
        lessonId: '',
    };

    async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true });
        EventBus.onWindow('message', this.handleWindowMessage);
        await this.loadIdMap();
    }

    componentWillUnmount(): void {
        EventBus.removeFromWindow('message', this.handleWindowMessage);
    }

    loadIdMap = async () => {
        const { courseId } = this.props.match.params;
        const query = new URLSearchParams(this.props.location.search);
        const chapterId = query.get('chapterId');
        const lessonId = query.get('lessonId');
        const { success, response } = await Api.call('get', `/users/courses/${courseId}/preview/map`);

        if (success) {
            const chapters = response.userChapters.reduce((acc: IChapterLesson, userChapter: IUserChapter) => {
                return { ...acc, [userChapter.courseChapterId]: userChapter._id };
            }, {});
            const lessons = response.userLessons.reduce((acc: IChapterLesson, userLesson: IUserLesson) => {
                return { ...acc, [userLesson.courseLessonId]: userLesson._id };
            }, {});
            if (chapterId && lessonId) {
                this.setState({
                    chapters,
                    lessons,
                    isLoading: false,
                    chapterId: chapters[chapterId],
                    lessonId: lessons[lessonId],
                });
            } else {
                this.setState({
                    chapters,
                    lessons,
                    isLoading: false,
                });
            }
        }
    };

    handleWindowMessage = (event: MessageEvent) => {
        if (event.origin !== process.env.REACT_APP_URL) return;
        if (!event.data.updateData) return;

        const { updateTarget, updateData } = event.data;
        updateData.lessonId = this.state.lessons[updateData.lessonId];

        if (updateTarget === 'card') {
            EventBus.dispatch('update-admin-preview-card', updateData);
        } else if (updateTarget === 'lesson') {
            updateData.chapterId = this.state.chapters[updateData.chapterId];
            EventBus.dispatch('update-admin-preview-lesson', updateData);
        } else if (updateTarget === 'sync') {
            updateData.chapterId = this.state.chapters[updateData.chapterId];
            EventBus.dispatch('admin-preview-change-lesson', updateData, 0);
        }
    };

    handleCourseLoad = (course: any) => {
        const { chapterId, lessonId } = this.state;
        const userLessonId = this.props.match.params?.lessonId;
        const userChapterId = this.props.match.params?.chapterId;
        if (chapterId || lessonId) {
            this.props.history.replace(`/admin/preview/${course._id}/chapters/${chapterId}/lessons/${lessonId}`);
        } else {
            this.props.history.replace(
                `/admin/preview/${course._id}/chapters/${userChapterId}/lessons/${userLessonId}`
            );
        }
    };

    render() {
        const { isLoading } = this.state;
        if (isLoading) return <Spinner />;
        return (
            <CourseManager courseId={this.props.match.params.courseId} onCourseLoad={this.handleCourseLoad}>
                <Lessons />
            </CourseManager>
        );
    }
}
