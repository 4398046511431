import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import LessonContext from '../../../../LessonContext';
import NextButton from '../NextButton';
import PreviousButton from '../PreviousButton';
import EventBus from 'src/helpers/eventBus';
import { StartExam } from 'src/pages/Course/Stages/Lessons/StartExam';
import SlideCalendar from './SlideCalendar';

interface IRouteProps {
    lessonId: string;
}
export interface IProps {
    setActiveCardIndex: (e: number) => any;
    activeCardIndex: number;
    course: any;
    cardType: string;
}
export type TProps = IProps & RouteComponentProps<IRouteProps>;
interface IState {}

class Navigation extends Component<TProps, IState> {
    slideCalendarRef = React.createRef<any>();
    static contextType = LessonContext;

    get isFinalExamActive(): boolean {
        return this.context.cards.length - 1 === this.props.activeCardIndex && !this.context.isAdminPreview;
    }

    nextLessonMiddleware = () => {
        const cardsLength = this.context.cards.length,
            { activeCardIndex } = this.props;

        if (this.slideCalendarRef?.current?.state?.expanded) {
            this.slideCalendarRef.current.toggleCalendar();
        }

        if (this.context.isAdmin) {
            const { cards } = this.context;
            const { activeCardIndex } = this.props;
            const nextCards = cards.slice(activeCardIndex + 1, cards.length);
            const cardIndex = nextCards.findIndex(
                (card: any) => !(card.cardType === 'QUIZ' || card.cardType === 'SINGLE_QUESTION')
            );

            if (cardIndex === -1) return false;

            const nextCardIndex = cardIndex + activeCardIndex + 1;
            this.props.setActiveCardIndex(nextCardIndex);
            return false;
        }

        if (activeCardIndex !== cardsLength - 1) {
            this.props.setActiveCardIndex(activeCardIndex + 1);

            return false;
        }
        return true;
    };

    previousLessonMiddleWare = () => {
        const { activeCardIndex } = this.props;
        const cardsLength = this.context.cards.length;

        if (this.slideCalendarRef?.current?.state?.expanded) {
            this.slideCalendarRef.current.toggleCalendar();
        }

        if (this.context.isAdmin) {
            const { cards } = this.context;
            const prevCards = cards.slice(0, activeCardIndex);
            const cardIndex = prevCards
                .reverse()
                .findIndex((card: any) => !(card.cardType === 'QUIZ' || card.cardType === 'SINGLE_QUESTION'));

            if (cardIndex === -1) return false;

            const nextCardIndex = prevCards.length - cardIndex - 1;
            this.props.setActiveCardIndex(nextCardIndex);
            return false;
        }

        if (activeCardIndex !== 0) {
            this.props.setActiveCardIndex(activeCardIndex - 1);

            return false;
        }
        this.props.setActiveCardIndex(cardsLength - 1);

        return true;
    };

    render() {
        const { activeCardIndex, course } = this.props,
            { previousLesson, feedback, nextLesson } = this.context;

        if (feedback?.show) {
            return <></>;
        }

        return (
            <div className='slide-container__navigation'>
                <PreviousButton
                    lessonContext={this.context}
                    activeCardIndex={activeCardIndex}
                    visible={activeCardIndex !== 0 || previousLesson}
                    middleware={this.previousLessonMiddleWare}
                />

                {!(nextLesson && nextLesson._id) && course?.courseType != 'optional' && (
                    <StartExam
                        lessonContext={this.context}
                        isFinalExamActive={this.isFinalExamActive}
                        examBtnCls={'lesson-button'}
                    />
                )}

                <SlideCalendar
                    activeCardIndex={activeCardIndex}
                    setActiveCardIndex={this.props.setActiveCardIndex}
                    ref={this.slideCalendarRef}
                />

                <NextButton
                    lessonContext={this.context}
                    activeCardIndex={activeCardIndex}
                    middleware={this.nextLessonMiddleware}
                />
            </div>
        );
    }
}

export default withRouter(Navigation);
