import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Api } from 'src/helpers/new';
import { SingleQuestion, BooleanAnswer, SwitchChoice, FillGap } from './QuestionTypes';
import './Question.slide.scss';

import { EventBus } from 'src/helpers/new';
import { QuestionContext } from './QuestionContext';
import LessonContext from 'src/pages/Course/Stages/Lessons/LessonContext';
import { IUserLessonCardData } from '../../../UserLessonCard';
import { get, isEmpty } from 'lodash';

interface IRouteProps {
    lessonId: string;
}

interface IState {
    bgColor?: string;
    fgColor?: string;
    questionType?: string;
    questions?: string;
    multipleAnswers?: boolean;
    questionResults?: any;
    result?: boolean;
    userAnswers?: any;
}

interface IProps {
    cardIndex?: string;
    heading?: string;
    subHeading?: string;
    nextCardAvailable?: string;
    setNextCardAvailable?: string;
    bgColor?: string;
    fgColor?: string;
    questions?: any;
    questionAttempt?: any;
    id?: string;
    detailedQuestion?: string;
    questionType?: string;
    correctFeedback?: string;
    incorrectFeedback?: string;
    content?: string;
    result?: any;
    userAnswers?: any;
    userLessonCardData: IUserLessonCardData;
}

export type TProps = IProps & RouteComponentProps<IRouteProps>;

class QuestionSlide extends Component<TProps, IState> {
    static contextType = LessonContext;

    isMultipleAnswers = (questions: any) => {
        if (questions) {
            if (this.props.questionType === 'SWITCH_CHOICE' || this.props.questionType === 'FILL_GAP') {
                return false;
            }
            if (questions?.[0]?.answer.length === 1) {
                return false;
            }
        }
        return true;
    };

    state: IState = {
        bgColor: '#25255e',
        fgColor: 'white',
        questionType: this.props.questionType ?? 'SINGLE_QUESTION',
        questions: this.props.questions ?? [],
        multipleAnswers: this.isMultipleAnswers(this.props.questions),
        questionResults: [],
        result:
            this.props?.userLessonCardData?.question && this.props?.userLessonCardData?.question?.status === 'passed',
        userAnswers: this.getUserAnswered,
    };

    get getUserAnswered(): any[] {
        const questions = get(this.props?.userLessonCardData, 'question.questions', []);
        let answers = Object.assign(
            {},
            questions.map((q: any) => q.userAnswer.map((v: any) => v.answer))
        );

        if (this.props.questionType === 'SWITCH_CHOICE' && isEmpty(answers)) {
            const defaultAnswers = {};
            this.props.questions.map((q: any, index: any) => Object.assign(defaultAnswers, { [index]: [0] }));

            answers = defaultAnswers;
        }
        return answers;
    }

    componentDidMount() {
        const questionAttempt = this.props?.userLessonCardData?.question;

        if (this.context.setFeedback) {
            if (!questionAttempt?.status || questionAttempt?.status === 'passed') {
                this.context.setFeedback({
                    expanded: false,
                    show: false,
                    correct: questionAttempt?.status === 'passed' ? true : false,
                    isAttempted: questionAttempt ? true : false,
                    nextCardAvailable: this.props.nextCardAvailable,
                    attemptStatus: '',
                });
            } else {
                if (questionAttempt) {
                    this.context.setFeedback({
                        correct: false,
                        show: false,
                        title: "That's not quite right",
                        content: this.props.incorrectFeedback,
                        expanded: false,
                        nextCardAvailable: this.props.nextCardAvailable,
                        isAttempted: true,
                        attemptStatus: '',
                    });
                }
            }
        }
        if (this.props.questionType === 'SWITCH_CHOICE') {
            this.setState({
                userAnswers: this.getUserAnswered,
            });
        }
        EventBus.on('base-question-card-retry', this.retry);
        EventBus.dispatch('disable-button', {
            disabled: 'You must answer this question correctly in other to progress any further',
        });
    }

    componentWillUnmount() {
        EventBus.remove('base-question-card-retry', this.retry);
        if (this.context.setFeedback) {
            this.context.setFeedback({
                expanded: false,
                show: false,
            });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        const newState: any = {};
        const questionAttempt = this.props?.userLessonCardData?.question;

        if (JSON.stringify(prevProps.result) !== JSON.stringify(this.props.result)) {
            newState.result = this.props.result;
        }

        if (JSON.stringify(prevProps.userAnswers) !== JSON.stringify(this.props.userAnswers)) {
            newState.userAnswers = this.props.userAnswers;
        }

        if (JSON.stringify(prevProps.id) !== JSON.stringify(this.props.id)) {
            if (this.context.setFeedback) {
                if (questionAttempt?.status || questionAttempt?.status === 'passed') {
                    this.context.setFeedback({
                        expanded: false,
                        show: false,
                        correct: questionAttempt?.status === 'passed' ? true : false,
                        isAttempted: questionAttempt ? true : false,
                        nextCardAvailable: this.props.nextCardAvailable,
                        attemptStatus: '',
                    });
                } else {
                    if (questionAttempt) {
                        this.context.setFeedback({
                            correct: false,
                            show: true,
                            title: "That's not quite right",
                            content: this.props.incorrectFeedback,
                            expanded: false,
                            nextCardAvailable: this.props.nextCardAvailable,
                            isAttempted: questionAttempt ? true : false,
                            attemptStatus: '',
                        });
                    }
                }
            }

            newState.questions = this.props.questions;
            newState.result = questionAttempt?.status === 'passed';
            newState.questionResults = !newState.result ? questionAttempt?.questions ?? [] : [];
            newState.multipleAnswers = this.isMultipleAnswers(this.props.questions);
            newState.userAnswers = this.props.questionType === 'SWITCH_CHOICE' ? this.getUserAnswered : {};
        }

        if (prevProps.questionType !== this.props.questionType) {
            newState.questionType = this.props.questionType;
        }

        if (Object.keys(newState).length !== 0) {
            this.setState(newState);
        }
    }

    retry = () => {
        this.setState(
            {
                result: false,
                userAnswers: this.props.questionType === 'SWITCH_CHOICE' ? this.getUserAnswered : {},
                questionResults: [],
            },
            () => {
                this.context.setFeedback({
                    expanded: false,
                    show: false,
                });
            }
        );
    };

    isOptionSelectedAnswer = (questionIndex: number, optionIndex: number) =>
        this.state?.questionResults?.[questionIndex]?.userAnswer?.findIndex?.(
            (userAnswer: any) => userAnswer.answer === optionIndex
        ) !== -1;

    isOptionSelected = (questionIndex: number, optionIndex: number) =>
        this.state?.userAnswers?.[questionIndex]?.includes(optionIndex);

    getSelectedOption = (questionIndex: number) => this.state?.userAnswers?.[questionIndex]?.[0] ?? 0;

    isSelectedCorrect = (questionIndex: number, optionIndex: number) => {
        const option = this.state?.questionResults?.[questionIndex]?.userAnswer?.find?.(
            (userAnswer: any) => userAnswer.answer === optionIndex
        );

        if (option === undefined) {
            return '';
        }

        return option.isCorrect ? 'correct' : 'incorrect';
    };

    selectAnswer = (questionIndex: any, e: any, value: any) => {
        if (value === undefined) {
            value = parseInt(e.target.value);
        }

        if (!this.state.questionResults.length) {
            const userAnswers = this.state.userAnswers;

            const questionAnswer = userAnswers[questionIndex] ?? [],
                index = questionAnswer.indexOf(value);

            if (!this.state.multipleAnswers) {
                if (value < 0 || index !== -1) {
                    delete userAnswers[questionIndex];
                } else {
                    userAnswers[questionIndex] = [value];
                }
            } else {
                if (index !== -1) {
                    questionAnswer.splice(index, 1);
                } else {
                    questionAnswer.push(value);
                }

                if (questionAnswer.length === 0) {
                    delete userAnswers[questionIndex];
                } else {
                    userAnswers[questionIndex] = questionAnswer;
                }
            }

            this.setState({ userAnswers });
        }
    };

    checkAnswers = async () => {
        const payload = {
                answers: this.state.userAnswers,
                userLessonId: this.props.match.params.lessonId,
            },
            { success, response } = await Api.call(
                'post',
                `/courses/lessons/cards/${this.props.id}/checkanswers`,
                payload
            );
        if (success) {
            const userQuestionData = response.updatedCards.find((v: any) => v.lessonCardId === this.props.id);

            const result = userQuestionData?.question?.status === 'passed';

            if (response && !isEmpty(response.updatedCards)) {
                this.context.updateUserLessonCards(response.updatedCards);
            }

            this.context.setTooltip(response.tooltip);
            this.setState(
                {
                    result: result,
                    questionResults: userQuestionData?.question?.questions ?? [],
                },
                () => {
                    if (result) {
                        this.context.setFeedback({
                            correct: true,
                            show: true,
                            title: "That's correct!",
                            content: this.props.correctFeedback,
                            nextCardAvailable: this.props.nextCardAvailable,
                            isAttempted: true,
                            attemptStatus: '',
                        });
                    } else {
                        this.context.setFeedback({
                            correct: false,
                            show: true,
                            title: "That's not quite right",
                            content: this.props.incorrectFeedback,
                            expanded: true,
                            nextCardAvailable: this.props.nextCardAvailable,
                            isAttempted: true,
                            attemptStatus: '',
                        });
                    }
                }
            );
        }
    };

    getResultIcon = (questionIndex: number, optionIndex: number) => {
        switch (this.isSelectedCorrect(questionIndex, optionIndex)) {
            case 'correct':
                return <FontAwesomeIcon icon={faCheck} />;
            case 'incorrect':
                return <FontAwesomeIcon icon={faTimes} />;
            default:
                return <></>;
        }
    };

    renderQuestionCard = () => {
        switch (this.state.questionType) {
            case 'SINGLE_QUESTION':
                return <SingleQuestion />;
            case 'DOUBLE_CHOICE':
                return <BooleanAnswer />;
            case 'FILL_GAP':
                return <FillGap />;
            case 'SWITCH_CHOICE':
                return <SwitchChoice />;
        }
    };

    isToShowCheckAnswer = () => {
        if (this.state.questionType !== 'SWITCH_CHOICE') {
            if (
                this.state.questions &&
                this.state.userAnswers &&
                this.state.questions.length !== Object.keys(this.state.userAnswers).length
            ) {
                return false;
            }
        }

        return true;
    };

    render() {
        return (
            <>
                <QuestionContext.Provider
                    value={{
                        heading: this.props.heading,
                        subHeading: this.props.subHeading,
                        content: this.props.content,
                        questions: this.state.questions,
                        bgColor: this.state.bgColor,
                        fgColor: this.state.fgColor,
                        result: this.state.result,
                        detailedQuestion: this.props.detailedQuestion,
                        multipleAnswers: this.state.multipleAnswers,
                        questionResults: this.state.questionResults,
                        userAnswers: this.state.userAnswers,
                        selectAnswer: this.selectAnswer,
                        isOptionSelected: this.isOptionSelected,
                        isOptionSelectedAnswer: this.isSelectedCorrect,
                        isSelectedCorrect: this.isSelectedCorrect,
                        getSelectedOption: this.getSelectedOption,
                        getResultIcon: this.getResultIcon,
                        retry: this.retry,
                    }}
                >
                    {this.renderQuestionCard()}
                    {this.isToShowCheckAnswer() && (
                        <div className={`check-answer-container`}>
                            {!this.state.questionResults.length && (
                                <button onClick={this.checkAnswers}>
                                    Check Answer{this.state.multipleAnswers ? '' : 's'}
                                </button>
                            )}
                        </div>
                    )}
                </QuestionContext.Provider>
            </>
        );
    }
}

export default withRouter(QuestionSlide);
