import React, { Component } from 'react';
import { IQuizQuestion } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz/QuizQuestionList/QuizQuestion';
import './FillGapQuestion.scss';

interface IProps {
    question: IQuizQuestion;
    answer: undefined | number;
    questionIndex: number;
    readOnly: boolean;
    handleAnswer: (questionIndex: number, answer: number) => void;
    results: { correct: boolean; feedback: string; answerMessage: string };
    revealAnswers: boolean;
}

export default class FillGapQuestion extends Component<IProps> {
    handleAnswer = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.handleAnswer(this.props.questionIndex, parseInt(e.target.value));
    };

    get question(): React.ReactElement[] {
        const { question, answer, questionIndex, readOnly } = this.props;

        if (readOnly) {
            return [<React.Fragment key={`question`}>{`${questionIndex + 1}. ${question.title}`}</React.Fragment>];
        } else {
            return question.title.split('___').reduce((acc: any[], questionSection: any, index: number) => {
                if (index === 0) acc.push(`${questionIndex + 1}. `);

                acc.push(<React.Fragment key={`question-part-${index}`}>{questionSection}</React.Fragment>);

                if (index !== 0) return acc;

                acc.push(
                    <select key={'question-select'} onChange={this.handleAnswer} value={answer}>
                        <option value={-1}></option>
                        {question.options.map((option: any, index: number) => {
                            if (option.length > 0) {
                                return (
                                    <option key={index} value={index}>
                                        {option}
                                    </option>
                                );
                            }
                        })}
                    </select>
                );

                return acc;
            }, []);
        }
    }

    getOption = (userAnswer: number | undefined, index: number, content: any, optionIndex: number) => {
        const question = this.props.question;
        let classes = '';

        const mainContent = (
            <span>
                <span className='option-index'>
                    <b>{optionIndex}. </b>
                </span>
                <span className='option-content'>{content}</span>
            </span>
        );

        let updatedOption = <div className='option-content-wrapper'>{mainContent}</div>;

        if (this.props.readOnly) {
            if (index === userAnswer) {
                if (userAnswer === question.correctOptionIndex) {
                    classes = 'correct-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was correct</span>
                        </div>
                    );
                }

                if (userAnswer !== question.correctOptionIndex) {
                    classes = 'incorrect-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was incorrect</span>
                        </div>
                    );
                }
            }

            if (index === question.correctOptionIndex && index !== userAnswer && this.props.revealAnswers === true) {
                classes = 'reveal-correct-feedback';
                updatedOption = (
                    <div className='option-content-wrapper'>
                        {mainContent}
                        <span className='feedback-text'>The correct answer</span>
                    </div>
                );
            }
        }

        return { classes, updatedOption };
    };

    render() {
        const { correct, feedback, answerMessage } = this.props.results;
        let optionIndex = 1;

        return (
            <div className='fill-gap-question-slide'>
                <div className='quiz-question'>{this.question}</div>

                {this.props.readOnly && (
                    <>
                        <div className='options'>
                            {this.props.question.options.map((option: any, index: number) => {
                                if (option.length > 0) {
                                    const opt = this.getOption(this.props.answer, index, option, optionIndex);
                                    optionIndex++;
                                    return (
                                        <div key={index} className={`option ${opt.classes}`}>
                                            {opt.updatedOption}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div className={`quiz-feedback ${correct ? 'correct' : 'incorrect'}`}>
                            {feedback} {answerMessage}
                        </div>
                    </>
                )}
            </div>
        );
    }
}
