import React, { Component } from 'react';
import TransitionPage from './Page/Transition.page';
import TransitionSlide, { IProps as ITransitionSlideProps } from './Slide/Transition.slide';
import CourseContext from 'src/pages/Course/CourseContext';
import './Page/Transition.page.scss';
import './Slide/Transition.slide.scss';

type TProps = ITransitionSlideProps;

export default class Transition extends Component<TProps> {
    static readonly contextType = CourseContext;

    componentDidMount() {
        const { iconPosition = 'TOP' } = this.props;
        document.documentElement.style.setProperty(
            '--transition-slide-background-image-position',
            `to ${iconPosition.toLowerCase()}`
        );
    }

    renderCard() {
        switch (this.context.course.lessonType) {
            case 'page':
                return <TransitionPage {...this.props} />;
            case 'slide':
                return <TransitionSlide {...this.props} />;
            default:
                return <></>;
        }
    }
    render() {
        const { iconPosition = 'TOP' } = this.props;
        return (
            <div
                className={`transition-${this.context.course.lessonType} theme-${(
                    this.props.theme ?? ''
                ).toLocaleLowerCase()} ${iconPosition.toLowerCase()}`}
            >
                {this.renderCard()}
            </div>
        );
    }
}
