import React, { ChangeEvent, Component } from 'react';
import { Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import PhoneNumberInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import './PhoneInput.scss';
import 'react-phone-number-input/style.css';

interface IProps {
    value: string;
    placeholder?: string;
    id: string;
    onChange: (value: string) => void;
    className?: string;
}
interface IState {
    value: string;
    selectedCountry: any;
    isFocus: boolean;
    searchValue: string;
}
class PhoneInput extends Component<IProps, IState> {
    searchInputRef: any = React.createRef();
    state: IState = {
        value: '',
        selectedCountry: {
            value: 'US',
            label: '',
        },
        isFocus: false,
        searchValue: '',
    };

    componentDidMount(): void {
        this.getSelectedCountry(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.value && prevProps.value !== this.props.value) {
            this.getSelectedCountry(this.props.value);
        }
        if (this.state.isFocus && !prevState.isFocus) {
            this.searchInputRef.current?.focus();
        }
    }

    getSelectedCountry = (val: string) => {
        let selectedCountry = {
            value: 'US',
            label: '',
        };
        let value = val;
        if (val) {
            const validateNumber = parsePhoneNumber(val);
            if (validateNumber && validateNumber?.nationalNumber) {
                selectedCountry = {
                    value: validateNumber?.country ?? 'US',
                    label: '',
                };
                value = validateNumber.number;
                this.setState({ value, selectedCountry });
            }
        }
    };

    handlePhoneInputChange = (value: string) => {
        this.setState({
            value: value ?? '',
        });
        this.props.onChange(value ?? '');
    };

    handleCountryChange = (selectedOption: any) => {
        this.setState({ selectedCountry: selectedOption, isFocus: false, searchValue: '', value: '' });
        this.props.onChange('');
    };

    handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchValue: event.target.value,
        });
    };

    toggleDropdown = (isOpen: boolean) => {
        this.setState({ isFocus: isOpen });
    };

    clearFilter = () => {
        if (this.state.searchValue) {
            this.setState({ searchValue: '' });
        }
    };

    getCountryFlag = (countryCode: string) => {
        return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;
    };

    CountrySelectComponent = ({ options }: any) => {
        const { selectedCountry, searchValue } = this.state;
        const filteredOptions = options.filter(
            (country: any) =>
                country.label.toLowerCase().includes(searchValue.toLowerCase()) && country.label && country?.value
        );
        return (
            <div className='country-select-wrapper'>
                <Dropdown onToggle={this.toggleDropdown}>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                        <div className='selected-flag'>
                            {this.state.selectedCountry ? (
                                <img
                                    className='PhoneInputCountryIconImg'
                                    alt='Bahrain'
                                    src={this.getCountryFlag(this.state.selectedCountry.value)}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <InputGroup>
                            <FormControl
                                type='text'
                                placeholder='Search country'
                                value={this.state.searchValue}
                                onChange={this.handleSearchChange}
                                ref={this.searchInputRef}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon
                                        icon={searchValue ? faTimes : faSearch}
                                        onClick={this.clearFilter}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        {filteredOptions.map((country: any) => {
                            return (
                                <Dropdown.Item
                                    key={country.value}
                                    onClick={() => this.handleCountryChange(country)}
                                    active={country.value === selectedCountry.value}
                                >
                                    <div className='country-label'>
                                        <img
                                            className='PhoneInputCountryIconImg'
                                            alt={country.label}
                                            src={this.getCountryFlag(country.value)}
                                        />
                                        {country.label} +{getCountryCallingCode(country.value)}
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                        {filteredOptions.length === 0 && <div className='no-country-found'>No Records</div>}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };
    render() {
        const { value } = this.state;
        return (
            <PhoneNumberInput
                id={this.props.id}
                className={`${this.props.className} number-input-text`}
                defaultCountry={this.state.selectedCountry ? this.state.selectedCountry.value : 'US'}
                value={value ?? ''}
                onChange={this.handlePhoneInputChange}
                international={false}
                limitMaxLength={true}
                useNationalFormatForDefaultCountryValue={true}
                smartCaret={true}
                countrySelectComponent={this.CountrySelectComponent}
            />
        );
    }
}

export default PhoneInput;
