import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { EventBus, Utility } from 'src/helpers/new';
import { ExamQuestion, IExamContent } from '../Exam';
import './CardsExam.scss';
// import FillGapQuestion from '../../Lessons/LessonTypes/Slide/Quiz/FillGapQuestion';
import { FormGroup, Form } from 'react-bootstrap';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface IProps {
    current: IExamContent;
    forceSubmit: () => void;
    onSubmit: () => void;
    onAnswer: (questionIndex: number, userAnswer: number) => void;
    activeCardIndex: number;
}
interface IState {}

export default class CardsExam extends Component<IProps, IState> {
    state: IState = {};

    get timeRemaining(): number {
        return Utility.getCurrentRemainingTime(this.props.current.startedAt[0], this.props.current.timeLimit);
    }

    paddingZero = (number: any) => {
        if (number.toString().length === 1) {
            return `0${number}`;
        }

        return number;
    };

    handleTimeExpired = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Out of time',
            body: 'You have run out of time for this Exam. Your answers have been submitted',
            confirm: {
                text: 'Okay',
                action: () => {},
            },
            cancel: null,
        });
        this.props.forceSubmit();
    };

    get currentQuestion(): ExamQuestion {
        const { questions } = this.props.current,
            questionIndex = this.props.activeCardIndex;
        let question: any = {};

        if (questions) {
            question = questions[questionIndex];
        }
        return question;
    }

    get currentAnswer(): undefined | number {
        return this.props.current.answers[this.props.activeCardIndex];
    }

    getOption = (userAnswer: number | undefined, optionIndex: number, content: any) => {
        const classes = '';
        const updatedOption = (
            <div className='option-content-wrapper'>
                <span>{content}</span>
            </div>
        );

        return { classes, updatedOption };
    };

    handleAnswer = (questionIndex: number, userAnswer: number) => {
        this.props.onAnswer(questionIndex, userAnswer);
    };

    render() {
        const { current } = this.props;
        return (
            <>
                <div className={`cards-exam`}>
                    <header>
                        <div className='cards-exam__tracker'>
                            {
                                <>
                                    <span>You have answered </span>
                                    <strong>
                                        {this.props.current.answers.filter((answer: any) => answer !== null)?.length}
                                    </strong>
                                    / {current.questions?.length}
                                </>
                            }
                        </div>
                        <div className='cards-exam__timer'>
                            {
                                <>
                                    <span>Time remaining:&nbsp;</span>

                                    <Countdown
                                        date={DateTimeFormatHelper.getCurrentTimeStamp() + this.timeRemaining}
                                        onComplete={this.handleTimeExpired}
                                        renderer={({ hours, minutes, seconds }) => {
                                            return (
                                                <span>
                                                    {hours.toString().padStart(2, '0')}:
                                                    {minutes.toString().padStart(2, '0')}:
                                                    {seconds.toString().padStart(2, '0')}
                                                </span>
                                            );
                                        }}
                                    />
                                </>
                            }
                        </div>
                    </header>
                    <div>
                        {/* TODO: When allowing fill and the Gap uncomment this and changes the question array as needed  */}
                        {/* {this.currentQuestion?.title?.includes('___') ? (
                            <FillGapQuestion
                                question={this.currentQuestion}
                                answer={this.currentAnswer}
                                questionIndex={this.props.activeCardIndex}
                                readOnly={false}
                                handleAnswer={this.handleAnswer}
                                results={results}
                                revealAnswers={this.revealAnswers}
                            />
                        ) : ( */}
                        <FormGroup className='question-wrap'>
                            <Form.Label className='pb-2'>
                                {this.props.activeCardIndex + 1}. {this.currentQuestion?.title}
                            </Form.Label>
                            {this.currentQuestion?.options?.map((option: any, optionIndex: number) => {
                                const opt = this.getOption(this.currentAnswer, optionIndex, option);
                                return (
                                    <div
                                        key={optionIndex}
                                        onClick={(e) => {
                                            if (this.currentAnswer !== optionIndex) {
                                                this.handleAnswer(this.props.activeCardIndex, optionIndex);
                                            }
                                        }}
                                    >
                                        <Form.Check
                                            key={optionIndex}
                                            id={`Q${this.props.activeCardIndex}A${optionIndex}`}
                                            type='radio'
                                            name={option.title}
                                            label={opt.updatedOption}
                                            className={`cards-quiz--option ${opt.classes}`}
                                            onChange={() => {
                                                if (this.currentAnswer !== optionIndex) {
                                                    this.handleAnswer(this.props.activeCardIndex, optionIndex);
                                                }
                                            }}
                                            checked={this.currentAnswer === optionIndex}
                                        />
                                    </div>
                                );
                            })}
                        </FormGroup>
                        {/* )} */}
                    </div>
                </div>
            </>
        );
    }
}
