import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import Countdown from 'react-countdown';
import CourseContext from 'src/pages/Course/CourseContext';
import PageVisibility from 'react-page-visibility';

interface IProps {
    lessonId: string;
    requiredTime: number;
}

interface IState {
    timeRemaining: number;
}

export default class LessonTimer extends Component<IProps, IState> {
    static readonly contextType = CourseContext;
    date = Date.now();
    state: IState = {
        timeRemaining: 0,
    };

    timerRef = React.createRef();

    componentDidMount() {
        window.socket.on('user-lesson-entered', this.setTimerUsingUserLessonEntered);
        window.socket.on('user-lesson-timer-complete', this.clearTimer);

        EventBus.dispatch('timer-mounted');
        if (this.context.course.pauseOnMediaStop) {
            EventBus.on('pause-on-media-stop', this.handlePauseTimer);
            EventBus.on('resume-on-media-stop', this.handleResumeTimer);
        }
        if (this.context.course.outOfFocusPause) {
            EventBus.onWindow('blur', this.handlePauseTimer);
            EventBus.onWindow('focus', this.handleResumeTimer);
        }

        window.socket.on('disconnect', this.handleOffline);
    }

    shouldComponentUpdate(nextProps: IProps, nextState: IState) {
        if (nextProps.lessonId !== this.props.lessonId || nextState.timeRemaining !== this.state.timeRemaining)
            return true;
        else return false;
    }

    componentWillUnmount() {
        window.socket.off('disconnect', this.handleOffline);
        if (this.context.course.outOfFocusPause) {
            EventBus.removeFromWindow('blur', this.handlePauseTimer);
            EventBus.removeFromWindow('focus', this.handleResumeTimer);
        }

        if (this.context.course.pauseOnMediaStop) {
            EventBus.remove('pause-on-media-stop');
            EventBus.remove('resume-on-media-stop');
        }
    }

    handleOffline = () => {
        if ((this.timerRef as any) && (this.timerRef as any)?.current?.isPaused() === false) {
            (this.timerRef as any)?.current?.pause();
        }
    };

    handlePauseTimer = () => {
        if ((this.timerRef as any) && (this.timerRef as any)?.current?.isPaused() === false) {
            window.socket.emit('leave-user-lesson', this.props.lessonId);
            (this.timerRef as any)?.current?.pause();
        }
    };

    handleResumeTimer = () => {
        if ((this.timerRef as any) && (this.timerRef as any)?.current?.isPaused() === true) {
            window.socket.emit('enter-user-lesson', {
                lessonId: this.props.lessonId,
                courseType: this.context.course.courseType,
            });
            (this.timerRef as any)?.current?.start();
        }
    };

    setTimerUsingUserLessonEntered = (timeRemaining: number) => {
        if (timeRemaining < 0) {
            timeRemaining = 0;
        }

        this.date = Date.now();
        this.setState({ timeRemaining });
    };

    clearTimer = () => {
        this.setState({ timeRemaining: 0 });
    };

    handleVisibilityChange = (isVisible: any) => {
        if (!isVisible && this.context.course.outOfFocusPause) {
            this.handlePauseTimer();
        }
    };

    render() {
        const { timeRemaining } = this.state;
        return (
            <>
                {this.props.requiredTime != 0 && (
                    <PageVisibility onChange={this.handleVisibilityChange}>
                        {timeRemaining === 0 || this.context.course.isAdminPreview ? (
                            <span>00:00:00</span>
                        ) : (
                            <>
                                <Countdown
                                    ref={this.timerRef as any}
                                    date={this.date + timeRemaining}
                                    renderer={({ hours, minutes, seconds }) => {
                                        return (
                                            <span>
                                                {hours.toString().padStart(2, '0')}:
                                                {minutes.toString().padStart(2, '0')}:
                                                {seconds.toString().padStart(2, '0')}
                                            </span>
                                        );
                                    }}
                                />
                            </>
                        )}
                    </PageVisibility>
                )}
            </>
        );
    }
}
