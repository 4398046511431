import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import './SideBarToggle.scss';
import CourseContext from 'src/pages/Course/CourseContext';

interface IProps {
    hideOnOpen?: boolean;
}

interface IState {}

export default class SideBarToggle extends Component<IProps, IState> {
    static contextType = CourseContext;

    componentDidMount() {
        EventBus.on('toggle-nav-sidebar', this.updateSidebarHidden);
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (this.context.isSideBarHidden !== nextContext.isSideBarHidden) {
            return true;
        }
        return false;
    }

    componentWillUnmount(): void {
        EventBus.remove('toggle-nav-sidebar', this.updateSidebarHidden);
    }

    updateSidebarHidden = (e: Event) => {
        const visible = (e as CustomEvent).detail;
        this.context.toggleSideBarHidden(visible);
    };

    toggleSidebar = () => {
        EventBus.dispatch('toggle-nav-sidebar', !this.context.isSideBarHidden);
    };

    render() {
        const { hideOnOpen } = this.props;
        const sideBarHidden = this.context.isSideBarHidden;

        if (hideOnOpen && !sideBarHidden) return null;

        return (
            <i className='sidebar-toggle' onClick={this.toggleSidebar}>
                {sideBarHidden ? (
                    <img className='menu-unfoldline' src={`/menu-foldline.svg`} alt='menu' />
                ) : (
                    <img className='menu-foldline' src={`/menu-foldline.svg`} alt='menu' />
                )}
            </i>
        );
    }
}
