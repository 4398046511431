import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import { RouteComponentProps } from 'react-router-dom';
import FormBuilder from 'src/components/FormBuilder/FormBuilder';
import { Spinner } from 'src/components/Spinner';
import CourseContext from 'src/pages/Course/CourseContext';
import CourseBreadcrumbs from 'src/pages/Course/Components/CourseBreadcrumbs';

interface IRouteProps {
    courseId: string;
}

type TProps = RouteComponentProps<IRouteProps>;

type syncAsyncFunction = (() => void) | (() => Promise<void>);

interface IState {
    fields: any[];
    isLoading: boolean;
    fileFields: any[];
}
export default class PreExamForm extends Component<TProps, IState> {
    static readonly contextType = CourseContext;

    state: IState = {
        fields: [],
        isLoading: true,
        fileFields: [],
    };

    async componentDidMount() {
        const { courseId } = this.props.match.params;
        if (this.context?.course?.isPreExamFormSaved) {
            this.props.history.push({
                pathname: `/courses/${courseId}/chapters/${this.context.course.lastChapterId}/lessons/${this.context.course.lastLessonId}`,
                state: {
                    isToBypassBiosigOnComponentMount: true,
                },
            });
        }
        const { success, response } = await Api.call('GET', `/users/pre-exam/${courseId}`);

        if (success) {
            if (response?.fields?.length > 0) {
                this.setState((prevState) => {
                    const fileFields = prevState.fileFields;
                    response?.fields?.map((field: any) => {
                        if (field.inputType === 'file') {
                            fileFields.push(field.key);
                        }
                    });
                    return { isLoading: false, fields: response.fields, fileFields };
                });
            } else {
                this.handleSubmit({});
            }
        }
    }

    handleSubmit = async (fields: any): Promise<void> => {
        this.handleProctoring(() => this.submit(fields));
    };

    submit = async (fields: any) => {
        const { courseId } = this.props.match.params;

        if (this.state.fileFields.length > 0) {
            const uploadPromises = this.state.fileFields?.map(async (field) => {
                if (fields[field] instanceof File) {
                    const fileData = new FormData();
                    fileData.append('file', fields[field]);
                    const { success, response } = await Api.call('POST', '/files', fileData);
                    if (success) {
                        fields[field] = response.fileId;
                    }
                }
            });
            await Promise.all(uploadPromises);
        }

        const { success, response } = await Api.call('post', `/users/pre-exam/${courseId}`, fields);
        this.context.updateCourseInfo('isPreExamFormSaved', response.saved);
        if (success) {
            const { course } = this.context;
            const {
                proctoringSettings: { bioSight },
            } = course;
            const isbioSight = bioSight === 'biosight';

            if (!course?.isProctorPass && course.proctorPass?.content && course.proctorPass?.price > 0 && isbioSight) {
                this.props.history.replace(`/courses/${courseId}/proctoring-pass`);
            } else {
                this.props.history.push(`/courses/${courseId}/exam-summary`);
            }
        }
    };

    get preExamNeedsProctoring(): boolean {
        const { proctoring = {}, proctoringSettings = {} } = this.context.course;

        return (
            proctoringSettings &&
            proctoringSettings.preExam === 'biosig' &&
            !(proctoring.preExam && proctoring.preExam.success === true)
        );
    }

    handleProctoring(callback: syncAsyncFunction = () => {}) {
        if (this.preExamNeedsProctoring) {
            EventBus.dispatch('require-auth', { stage: 'preExam', callback });
        } else {
            callback();
        }
    }

    render() {
        const { isLoading, fields } = this.state;
        const { title } = this.context.course;
        if (isLoading) return <Spinner />;

        return (
            <FormBuilder
                onSubmit={this.handleSubmit}
                fields={fields}
                submitText='Submit'
                isSubmitButton={true}
                header={() => <CourseBreadcrumbs centered firstItem={title} secondItem='Pre Exam Form' />}
            />
        );
    }
}
