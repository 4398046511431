import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/store/reducers/rootReducer';
import { PackageList } from './packages';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router';
import { Banner } from './Banner';
import './Home.scss';
import { UpsellsList } from './Upsells';
import ExtensionRepurchase from '../Checkout/ExtensionRepurchase';
import UpsellCartButton from './Upsells/Cart/UpsellCartButton';
import { Api, EventBus } from 'src/helpers/new';
import { getState, setState } from 'src/helpers/localStorage';

interface IConnectProps {
    loggedIn: any;
}

export interface ICartDetail {
    courses: any[];
    itemsCount: number;
    packages: any[];
    pkgIds: string[];
    _id: string;
}

interface IRouteProps {
    userPackageId: string;
}

type TProps = RouteComponentProps<IRouteProps> & IConnectProps;

interface IState {
    packages: any;
    cartDetails: ICartDetail;
    isLoading: boolean;
}

class Home extends Component<TProps, IState> {
    state: IState = {
        packages: null,
        isLoading: false,
        cartDetails: {
            courses: [],
            itemsCount: 0,
            packages: [],
            pkgIds: [],
            _id: '',
        },
    };

    componentDidMount() {
        EventBus.dispatch('show-exam-banner', { isResume: null });
        this.loadCarts();
    }

    loadCarts = async () => {
        this.setState({ isLoading: true });
        const cartId = localStorage.getItem('reuCheckoutCartId');
        const id = getState('user')?._id ? getState('user')?._id : cartId;
        if (id) {
            const { success, response } = await Api.call(
                'GET',
                `checkout/cart/${id}/dashboard?isLoggedIn=${!!getState('user')?._id}`
            );
            if (success) {
                if (response.isCartFound) {
                    if (response.cartDetails._id) {
                        setState('reuCheckoutCartId', response.cartDetails._id);
                    }
                    this.setState({
                        cartDetails: response.cartDetails,
                        isLoading: false,
                    });
                } else {
                    localStorage.removeItem('reuCheckoutCartId');
                }
            }
        }
    };

    updateCartDetails = (data: any) => {
        this.setState({
            cartDetails: { ...this.state.cartDetails, ...data },
        });
    };

    render() {
        const { loggedIn } = this.props;

        return (
            <div className='home'>
                <Banner channel={'dashboard'} />
                <div className='package-upsells'>
                    <PackageList userId={loggedIn.user._id} />
                    <UpsellsList
                        userId={loggedIn.user._id}
                        cartDetails={this.state.cartDetails}
                        loadCarts={this.loadCarts}
                        updateCartDetails={this.updateCartDetails}
                    />
                </div>

                <div className=''>
                    <Switch>
                        <Route
                            exact
                            path='/extension/:userPackageId'
                            component={(props: any) => (
                                <ExtensionRepurchase
                                    {...props}
                                    redirectUrl={'/'}
                                    userPackageId={this.props.match.params.userPackageId}
                                />
                            )}
                        />
                    </Switch>
                </div>
                {!this.state.isLoading && <UpsellCartButton cartDetails={this.state.cartDetails} />}
            </div>
        );
    }
}

export default connect((state: RootState) => {
    return {
        loggedIn: state.loggedIn,
    };
})(withRouter(Home));
