import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import QuizCalendar from './QuizCalendar/QuizCalendar';
import { compact, isNil } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConditionalWrapper from 'src/components/ConditionalWrapper';

interface IRouteProps {
    lessonId: string;
}

// these need proper types added
export interface IProps {
    setActiveCardIndex: (e: number) => any;
    handleSubmit: (e: any) => any;
    activeCardIndex: number;
    course: any;
    questions: any[];
    isReview: boolean;
    answers: any[];
    allowSkip: boolean;
    isQuizLoading: boolean;
    isExam: boolean;
    isCheckedAnswer?: boolean;
}

export type TProps = IProps & RouteComponentProps<IRouteProps>;

class Navigation extends Component<TProps> {
    quizCalendarRef = React.createRef<any>();

    nextQuestion = () => {
        if (this.progressionBlocked) return;
        this.props.setActiveCardIndex(this.props.activeCardIndex + 1);
        if (this.quizCalendarRef?.current?.state?.expanded) {
            this.quizCalendarRef.current.handleToggleClick();
        }
    };

    previousQuestion = () => {
        this.props.setActiveCardIndex(this.props.activeCardIndex - 1);
        if (this.quizCalendarRef?.current?.state?.expanded) {
            this.quizCalendarRef.current.handleToggleClick();
        }
    };

    get progressionBlocked(): boolean {
        const answers = this.props.answers;

        if (this.props.course?.isAdminPreview) return false;
        if (this.props.allowSkip) return false;
        else if (this.props.isReview) return false;
        else return answers[this.props.activeCardIndex] != undefined && !this.props.isReview ? false : true;
    }

    get firstCard(): boolean {
        return this.props.activeCardIndex === 0;
    }

    get lastCard(): boolean {
        return this.props.questions && this.props.questions.length - 1 === this.props.activeCardIndex;
    }

    get allQuestionAnswered(): boolean {
        const answers = compact(this.props.answers.map((v) => !isNil(v)));
        if (this.props.questions.length === answers.length) {
            return true;
        } else return false;
    }

    showUnansweredQuestions = () => {
        if (this.quizCalendarRef?.current) {
            this.quizCalendarRef.current.handleToggleClick();
        }
    };

    nextButton = () => {
        return (
            <ConditionalWrapper
                condition={this.progressionBlocked}
                wrapper={(children: any) => (
                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-next-lesson`}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: `You can't skip this question, please answer all of the questions in order to submit your ${
                                            this.props.isExam ? 'exam' : 'attempt'
                                        }`,
                                    }}
                                />
                            </Tooltip>
                        }
                        rootClose={true}
                        trigger={['click', 'focus']}
                    >
                        {children}
                    </OverlayTrigger>
                )}
            >
                {!this.lastCard ? (
                    <div
                        className={`lesson-button lesson-button--next${
                            this.progressionBlocked ? ' lesson-button--disabled' : ''
                        }`}
                        onClick={this.nextQuestion}
                    >
                        Next
                        <i className='fa-solid fa-chevron-right' />
                    </div>
                ) : (
                    <></>
                )}
            </ConditionalWrapper>
        );
    };

    render() {
        const { activeCardIndex, course, questions, isReview, answers, isQuizLoading, isExam, isCheckedAnswer } =
            this.props;

        const disabledSubmit = isExam ? !!isCheckedAnswer : false;
        return (
            <div className='slide-container__navigation'>
                {!this.firstCard && (
                    <div className={`lesson-button lesson-button--previous`} onClick={this.previousQuestion}>
                        <i className='fa-solid fa-chevron-left' />
                        Previous
                    </div>
                )}
                {this.allQuestionAnswered && this.lastCard && !isReview && !isQuizLoading && (
                    <div className={`check-answer-container`}>
                        <button onClick={this.props.handleSubmit} disabled={disabledSubmit}>
                            Submit
                        </button>
                    </div>
                )}
                {!this.allQuestionAnswered && this.lastCard && !isReview && this.props.allowSkip && (
                    <div className={`check-answer-container`}>
                        <button onClick={this.showUnansweredQuestions}>Show unanswered questions</button>
                    </div>
                )}
                <QuizCalendar
                    course={course}
                    activeCardIndex={activeCardIndex}
                    questions={questions}
                    answers={answers}
                    setActiveCardIndex={this.props.setActiveCardIndex}
                    isReview={isReview}
                    allowSkip={this.props.allowSkip}
                    isPreview={this.props.course?.isAdminPreview ?? false}
                    ref={this.quizCalendarRef}
                />
                {this.nextButton()}
            </div>
        );
    }
}

export default withRouter(Navigation);
