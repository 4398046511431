import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Api, EventBus } from 'src/helpers/new';
import './ExpirePasswordPrompt.scss';

class ExpirePasswordPrompt extends Component {
    state = {
        showModal: true,

        currentPassword: '',
        password: '',
        passwordCheck: '',
        passwordError: '',
    };

    toggleShowModal = (e) => {
        if (e instanceof Event) {
            e.preventDefault();
        }

        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleInputChange = (e) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    handlePasswordChange = async (e) => {
        e.preventDefault();

        const { success, message } = await Api.call('POST', '/users/password/change', {
            currentPassword: this.state.currentPassword,
            password: this.state.password,
            passwordCheck: this.state.passwordCheck,
        });

        if (success) {
            EventBus.dispatch('toast', {
                type: 'success',
                message: message ?? 'Your password was changed!',
            });

            this.toggleShowModal();
            window.location.reload();
        } else {
            this.setState({
                passwordError: message,
            });
        }
    };

    render() {
        const { passwordError } = this.state;
        return (
            <div>
                <Modal className='expire-model' show={this.state.showModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Your password has expired. Create a new password to keep your account secure
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className={'form' + (passwordError ? ' form--with-error' : '')}>
                            {passwordError && <div className='form__error'>{passwordError}</div>}
                            <div className='form__content'>
                                <form action='/' onSubmit={this.handlePasswordChange}>
                                    <div className='form__field'>
                                        <label htmlFor='currentPassword'>Current password</label>
                                        <input
                                            type='password'
                                            name='currentPassword'
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='password'>New password</label>
                                        <input type='password' name='password' onChange={this.handleInputChange} />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='passwordCheck'>Repeat new password</label>
                                        <input type='password' name='passwordCheck' onChange={this.handleInputChange} />
                                    </div>
                                    <div className='form__buttons'>
                                        <button className='btn bp' type='submit'>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    }
)(withRouter(ExpirePasswordPrompt));
