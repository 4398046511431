import React, { Component, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CourseContext from 'src/pages/Course/CourseContext';
import LessonContext from './LessonContext';
import { Api, EventBus } from 'src/helpers/new';
import { PageLesson, SlideLesson } from './LessonTypes';
import { NavigationSidebar } from './NavigationSideBar';
import ProgressionManager from './ProgressionManager';
import StudentPreviewController from './StudentPreviewController/StudentPreviewController';
import { commonTimeout } from 'src/helpers/commonTimeout';
import { clearState, getState } from 'src/helpers/localStorage';
import { throttle, isEmpty, get, set } from 'lodash';
import { connect } from 'react-redux';
import { Spinner } from '../../../../components/Spinner';
import { ICard } from './Cards/LessonCard';
import './Lessons.scss';
import { IUserLessonCardData } from './Cards/UserLessonCard';
import { IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import ChatPostPurchaseModal from 'src/layouts/Main/UserBar/Chat/ChatPostPurchaseModal';
import { io } from 'socket.io-client';
import { SecretQuestion as SecretQuestionModal } from './SecretQuestions/SecretQuestion';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface IProps {
    loggedIn: IUserLoggedIn;
    courseChat: { conversationId: string; unreadCount: number };
    setLoggedIn: (payload: any) => void;
    setCourseChat: (payload: any) => void;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

interface IFeedback {
    show: boolean;
    expanded: boolean;
}

interface ModalType {
    show: boolean;
    message: string;
    header?: string;
    buttonText: string;
    buttonClick: () => void;
    backdrop?: boolean | 'static';
}

interface IState {
    isLoading: boolean;
    lesson: any;
    feedback: IFeedback;
    pageStatus: string;
    isBlockedByNodes: boolean;
    hotspotsCardsViewedNodes: Record<string, unknown>;
    isDraft: boolean;
    isSynced: boolean;
    isMediaPlay: boolean;
    modalContent: ModalType;
    cardUnlockTooltip: string;
}

class Lessons extends Component<TProps, IState> {
    static readonly contextType = CourseContext;

    state: IState = {
        isLoading: true,
        pageStatus: 'LOADING',
        lesson: null,
        feedback: {
            show: false,
            expanded: false,
        },
        isBlockedByNodes: false,
        hotspotsCardsViewedNodes: {},
        isDraft: true,
        isSynced: true,
        isMediaPlay: false,
        modalContent: {
            buttonClick: () => {},
            buttonText: '',
            show: false,
            header: '',
            message: '',
            backdrop: 'static',
        },
        cardUnlockTooltip: '',
    };

    async componentDidMount() {
        window.socket.on('course-sync-completed', this.refreshCourseAfterSync);

        window.socket.emit('enter-user-course', this.socketPayload());

        this.chatConnectionProcess();
        document.addEventListener('beforeunload', this.leaveUserCourseListener);
        window.socket.on('card-viewed', this.updateCardProgression);

        const { lessonId } = this.props.match.params;
        if (lessonId) await this.loadLessonData(lessonId, !!this.context.course.isAdminPreview, true);
    }

    async componentDidUpdate(prevProps: TProps, prevState: IState) {
        if (this.props.match.params.lessonId && prevProps.match.params.lessonId !== this.props.match.params.lessonId) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    pageStatus: 'LOADING',
                }),
                async () => {
                    await this.loadLessonData(this.props.match.params.lessonId, !!this.context.course.isAdminPreview);
                },
            );
        }

        window.socket.on('daily-study-limit-used', this.courseTimerPopup('Daily Study Limit'));
        window.socket.on('essay-feedback-received', this.essayFeedbackReceived);
        window.socket.on('daily-progress-limit-used', this.courseTimerPopup('Daily Progress Limit'));

        if (!this.context.course.isAdminPreview) {
            window.socket.on('changes-in-course-received', () => this.refreshPopup());
        }

        if (this.state.isLoading !== prevState.isLoading && !this.context.course.isAdminPreview) {
            commonTimeout.clearTimer('inactive');
            commonTimeout.clearTimer('logout');

            this.startInactiveTimer();
            this.startLogoutTimer();
        }
        if (this.state.lesson) {
            const { isShowSecretQuestionModal, isSecretQuestionCheckPoint } = this.secretQuestionAuthentication(
                this.state.lesson,
            );
            if (
                this.state.lesson.isShowSecretQuestionModal !== isShowSecretQuestionModal ||
                this.state.lesson.isSecretQuestionCheckPoint !== isSecretQuestionCheckPoint
            ) {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        lesson: {
                            ...prevState.lesson,
                            isShowSecretQuestionModal: isShowSecretQuestionModal,
                            isSecretQuestionCheckPoint: isSecretQuestionCheckPoint,
                        },
                    };
                });
            }
        }
    }

    componentWillUnmount() {
        // I wanted to have this inside the progression manager, however the unmounting during a lesson change caused issue with tracking left at
        window.socket.emit('leave-user-lesson', this.props.match.params.lessonId);
        window.socket.emit('leave-user-course', this.socketPayload());

        this.clearChatData();
        if (!this.context.course.isAdminPreview) {
            commonTimeout.clearTimer('inactive');
            commonTimeout.clearTimer('logout');
            this.handleTimeout.cancel();
        }
        document.removeEventListener('beforeunload', this.leaveUserCourseListener);
    }

    socketPayload = () => {
        const { courseId } = this.props.match.params;
        return {
            courseId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    };

    chatConnectionProcess = () => {
        if (this.context?.course?.chatAccess && process.env.REACT_APP_WS_Chat_URL && isEmpty(window.chatsocket)) {
            window.chatsocket = io(process.env.REACT_APP_WS_Chat_URL, {
                query: {
                    token: localStorage.getItem('authToken'),
                },
                transports: ['websocket'],
            });
        }

        if (window.chatsocket) {
            window.chatsocket.emit(
                'student-dashboard',
                { courseId: this.context.course?.courseId ?? '' },
                this.getConversationId,
            );
        }

        if (this.props.loggedIn?.user?._id && window.chatsocket) {
            window.chatsocket.on(this.props.loggedIn?.user?._id, (data) => {
                if (data?.courseId === this.context?.course?.courseId) {
                    if (this.props.courseChat?.conversationId) {
                        this.props.setCourseChat({
                            ...this.props.courseChat,
                            unreadCount: data?.unreadCount ?? 0,
                        });
                    } else {
                        const conversationId = get(data.conversation[0], '_id', '');
                        this.props.setCourseChat({
                            conversationId: conversationId,
                            unreadCount: data?.unreadCount ?? 0,
                        });
                        if (conversationId) {
                            EventBus.dispatch('message-from-admin', { conversationId });
                        }
                    }
                }
            });
        }
    };

    clearChatData = () => {
        this.props?.setCourseChat({});
        EventBus.dispatch('display-chat-icon', { enable: false, course: this.context.course });
    };

    getConversationId = (data: any) => {
        if (data?.success && data?.data) {
            this.props.setCourseChat(data.data);
        }
    };

    leaveUserCourseListener(event: any) {
        window.socket.emit('leave-user-course', this.socketPayload());
    }

    essayFeedbackReceived = () => {
        this.refreshPopup(
            `Your essay has been graded! Click 'Refresh' to view your instructor's feedback.`,
            'Essay Grading Feedback',
        );
    };

    refreshPopup = (message?: string, title?: string) => {
        this.setState((prevState) => ({
            ...prevState,
            modalContent: {
                show: true,
                message:
                    message ??
                    "It looks like the course you're using has received some updates! To see new lesson content and ensure your progress is saved, please refresh the page.",
                buttonText: 'Refresh',
                header: title ?? 'Your course has been updated',
                buttonClick: () => {
                    const { courseId } = this.props.match.params;
                    localStorage.setItem(`${courseId}-manual-reload`, 'true');

                    const { history: routerHistory } = this.props;
                    routerHistory.go(0);
                    this.clearModalContent();
                },
            },
        }));
    };

    courseTimerPopup = (str: string) => () => {
        this.setState((prevState) => ({
            ...prevState,
            modalContent: {
                show: true,
                message: `You have reached your ${str}. Please go back to the Home Page`,
                buttonText: 'Go Back',
                buttonClick: () => {
                    this.props.history.push('/');
                    this.clearModalContent();
                },
            },
        }));
    };

    refreshCourseAfterSync = (syncData: any) => {
        const { type, updatedIds } = syncData;
        switch (type) {
            case 'chapter': {
                const { chapterId } = this.props.match.params;
                if (updatedIds.includes(chapterId)) {
                    this.refreshPopup();
                }
                break;
            }
            case 'lesson': {
                const { lessonId } = this.props.match.params;
                if (updatedIds.includes(lessonId)) {
                    this.refreshPopup();
                }
                break;
            }
            case 'course': {
                const { courseId } = this.props.match.params;
                if (updatedIds.includes(courseId)) {
                    this.refreshPopup();
                }
                break;
            }
        }
    };

    isLessonSyncRequired = async (lessonId: string) => {
        const { success, response } = await Api.call('GET', `/users/lesson/${lessonId}/sync`);
        if (success) return response;
        return null;
    };

    secretQuestionAuthentication = (lesson: any) => {
        let isShowSecretQuestionModal = false;
        let isSecretQuestionCheckPoint = false;

        const needsAuth = this.context.course?.proctoringSettings?.needsAuth ?? [];
        const { courseLessonId, requiresReAuthentication, secretQuestionsValidated, requiresReAuthenticationForNmls } =
            lesson;
        const { secretQuestionsCheckpoints = [], courseType = '', isAdminPreview } = this.context.course;

        const isCheckPoint = needsAuth.includes(courseLessonId);

        if (
            secretQuestionsCheckpoints.length &&
            courseType !== 'optional' &&
            !isAdminPreview &&
            !requiresReAuthenticationForNmls &&
            (!isCheckPoint || (isCheckPoint && requiresReAuthentication === false))
        ) {
            if (!this.props.loggedIn.user?.secretQuestionsSubmittedAt) {
                isShowSecretQuestionModal = true;
            } else if (
                courseLessonId &&
                !secretQuestionsValidated &&
                secretQuestionsCheckpoints.includes(courseLessonId)
            ) {
                isShowSecretQuestionModal = true;
                isSecretQuestionCheckPoint = true;
            }
        }

        return { isShowSecretQuestionModal, isSecretQuestionCheckPoint };
    };

    loadLessonData = async (lessonId: string, isResetCard: boolean, isComponentMounted = false) => {
        if (!this.state.isLoading) this.setState((prevState) => ({ ...prevState, isLoading: true }));

        if (this.context.course.isSyncCourseInProgress) {
            this.setState((prevState) => ({
                ...prevState,
                modalContent: {
                    show: true,
                    message:
                        "It looks like the course you're using has received some updates! You can click on refresh to get last synced content.",
                    buttonText: 'Refresh',
                    header: 'Your course is syncing latest updates',
                    buttonClick: () => {
                        const { history: routerHistory } = this.props;
                        routerHistory.go(0);
                        this.clearModalContent();
                    },
                },
            }));
        }

        if (!this.context.course.isSyncCourseInProgress) {
            const { isSyncRequired } = await this.isLessonSyncRequired(lessonId);
            if (isSyncRequired) await this.context.syncCourseData(this.props.match.params.courseId);
        }

        const params = this.context.course.isAdminPreview
            ? {
                  isResetCard,
                  isDraft: this.context.course.isAdminPreview && this.state.isDraft,
              }
            : { isDraft: this.context.course.isAdminPreview && this.state.isDraft };

        const { success, response } = await Api.call('GET', `/users/lessons/${lessonId}`, null, params);

        if (!success) return;

        response.requiresReAuthenticationForNmls = this.context.course?.proctoringSettings?.bioSight === 'biosig-nmls';

        const updateStateData: Partial<IState> = {
            isLoading: false,
            lesson: {
                ...response,
                showBiosig: isComponentMounted,
                isShowSecretQuestionModal: this.secretQuestionAuthentication(response).isShowSecretQuestionModal,
                isSecretQuestionCheckPoint: this.secretQuestionAuthentication(response).isSecretQuestionCheckPoint,
            },
            hotspotsCardsViewedNodes: response?.hotspotsCardsViewedNodes ?? {},
            pageStatus: 'READY',
        };

        this.setState((prevState) => ({
            ...prevState,
            ...updateStateData,
        }));
    };

    setFeedback = (feedback: IFeedback, cb: () => 1) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                feedback: {
                    ...prevState?.feedback,
                    ...feedback,
                },
            }),
            cb,
        );
    };

    setViewedNodes = (cardId: string, viewedNodes: any) => {
        this.setState((prevState) => ({
            ...prevState,
            hotspotsCardsViewedNodes: {
                ...prevState.hotspotsCardsViewedNodes,
                [cardId]: viewedNodes,
            },
        }));
    };

    setIsBlockedByNodes = (isBlockedByNodes: boolean) => {
        return;
    };

    setLessonCardQuestionAttempt = ({ cardIndex, questionAttempt }: any, cb: () => 1): void => {
        const lesson = { ...this.state.lesson };

        lesson.cards[cardIndex].questionAttempt = questionAttempt;

        this.setState(
            (prevState) => ({
                ...prevState,
                lesson,
            }),
            cb,
        );
    };

    startInactiveTimer = () => {
        if (this.context.course.inactiveTime <= 0) return;
        commonTimeout.startTimer(
            'inactive',
            () => {
                this.setState((prevState) => ({
                    ...prevState,
                    modalContent: {
                        show: true,
                        message: `You are Inactive from the last ${
                            this.context.course.inactiveTime / 60
                        } minutes, Please Click on the Refresh button to continue.`,
                        buttonText: 'Refresh',
                        buttonClick: () => {
                            window.socket.emit('student-active', this.socketPayload());
                            const { history: routerHistory } = this.props;
                            routerHistory.go(0);
                            this.clearModalContent(true);
                        },
                    },
                }));
            },
            this.context.course.inactiveTime * 1000,
        );
    };

    clearModalContent = (isInactive?: boolean) => {
        if (isInactive) {
            commonTimeout.clearTimer('inactive');
            commonTimeout.clearTimer('logout');
        }
        this.setState((prevState) => ({
            ...prevState,
            modalContent: {
                show: false,
                header: '',
                message: '',
                buttonText: '',
                buttonClick: () => {},
                backdrop: 'static',
            },
        }));
    };

    startLogoutTimer = () => {
        if (this.context.course.logoutTime <= 0) return;
        commonTimeout.startTimer(
            'logout',
            () => {
                this.logOut();
            },
            this.context.course.logoutTime * 60000,
        );
    };

    handleTimeout = throttle(() => {
        if (this.context.course.isAdminPreview) return;
        if (this.state.isMediaPlay) return;
        if (!this.state.modalContent.show) {
            commonTimeout.clearTimer('inactive');
            commonTimeout.clearTimer('logout');

            this.startInactiveTimer();
            this.startLogoutTimer();
        }
    }, 1000);

    logOut = async () => {
        if (getState('authToken')) {
            const { success, message } = await Api.call('POST', '/users/logout');
            if (success) {
                window.socket.emit('student-logout', this.socketPayload());
                this.handleLogout(message);
            }
        } else {
            this.handleLogout('');
        }
    };

    handleLogout = (message: string) => {
        EventBus.dispatch('toast', {
            type: 'success',
            message: message || "You've been successfully logged out.",
        });
        clearState();
        this.props.setLoggedIn({
            token: null,
            user: null,
        });
        EventBus.dispatch('show-exam-banner', { isResume: null });
        window.socket.emit('logout', '');
        if (window.chatsocket) {
            window.chatsocket.close();
            set(window, 'chatsocket', undefined);
        }

        this.props.history.push('/login');
    };

    updateLesson = (lesson: any) => {
        this.setState((prevState) => ({
            ...prevState,
            lesson,
        }));
    };

    updateLessonPertially = (partialData: any) => {
        this.setState((prevState) => ({
            ...prevState,
            lesson: {
                ...prevState.lesson,
                ...partialData,
            },
        }));
    };

    updateIsDraft = (isDraft: boolean) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                isDraft,
            }),
            () => {
                this.loadLessonData(this.props.match.params.lessonId, !!this.context.course.isAdminPreview);
            },
        );
    };

    updateIsSynced = (isSynced: boolean) => {
        this.setState((prevState) => ({
            ...prevState,
            isSynced,
        }));
    };

    clearMediaPlay = (isPlay: boolean) => {
        this.setState((prevState) => ({
            ...prevState,
            isMediaPlay: isPlay,
        }));
    };

    loadUpdatedLesson = async () => {
        const { lessonId } = this.props.match.params;
        if (!lessonId) return;

        const { success, response } = await Api.call('GET', `/users/lessons/${lessonId}`, null, {
            isDraft: this.context.course.isAdminPreview && this.state.isDraft,
        });

        if (!success) return;

        this.setState((prevState) => ({
            ...prevState,
            lesson: {
                ...response,
            },
        }));
    };

    updateCardProgression = async ({
        updatedCards,
        tooltip,
    }: {
        updatedCards: IUserLessonCardData[];
        tooltip: string;
    }) => {
        this.setTooltip(tooltip);
        if (!isEmpty(updatedCards)) this.updateUserLessonCards(updatedCards);
    };

    updateUserLessonCards = async (updatedLessonCards: IUserLessonCardData[]) => {
        const lessons = this.state.lesson;
        updatedLessonCards.forEach((userLessonCard: IUserLessonCardData) => {
            const lessonIndex = this.state.lesson?.cards?.findIndex(
                (v: ICard) => v._id === userLessonCard.lessonCardId,
            );
            if (lessonIndex !== -1 && !isEmpty(lessons)) {
                lessons.cards[lessonIndex].userLessonCardData = userLessonCard;
            }
        });

        this.setState((prevState: IState) => {
            return {
                ...prevState,
                lesson: lessons,
            };
        });
    };

    setTooltip = (tooltip: string) => {
        this.setState({ cardUnlockTooltip: tooltip });
    };

    public render(): ReactNode {
        const { pageStatus } = this.state;
        if (pageStatus !== 'READY') return <Spinner />;

        const {
            isLoading,
            lesson,
            feedback,
            isBlockedByNodes,
            hotspotsCardsViewedNodes,
            isDraft,
            isSynced,
            modalContent,
            cardUnlockTooltip,
        } = this.state;

        const { course, examStatus, isSideBarHidden } = this.context;

        return (
            <>
                <Modal
                    className={`lesson-refresh-modal ${modalContent?.backdrop == false ? 'modal-backdrop' : ''}`}
                    show={modalContent.show}
                    onHide={this.clearModalContent}
                    backdrop={modalContent?.backdrop ?? 'static'}
                >
                    {modalContent.header && (
                        <Modal.Header closeButton>
                            <h4>{modalContent.header}</h4>
                        </Modal.Header>
                    )}
                    <Modal.Body>{modalContent.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={modalContent.buttonClick}>
                            {modalContent.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div
                    onMouseMove={this.handleTimeout}
                    onClick={this.handleTimeout}
                    onKeyPress={this.handleTimeout}
                    onWheel={this.handleTimeout}
                    className='lesson-container-wrapper'
                >
                    <SecretQuestionModal
                        isShowSecretQuestionModal={this.state?.lesson?.isShowSecretQuestionModal}
                        isSecretQuestionCheckPoint={this.state.lesson?.isSecretQuestionCheckPoint}
                        updateLesson={this.updateLessonPertially}
                    />
                    <ChatPostPurchaseModal />
                    <LessonContext.Provider
                        value={{
                            ...lesson,
                            isLoading,
                            lesson: lesson,
                            feedback,
                            isBlockedByNodes,
                            hotspotsCardsViewedNodes,
                            isDraft,
                            isSynced,
                            examStatus,
                            cardUnlockTooltip,
                            setViewedNodes: this.setViewedNodes,
                            setIsBlockedByNodes: this.setIsBlockedByNodes,
                            setFeedback: this.setFeedback,
                            setLessonCardQuestionAttempt: this.setLessonCardQuestionAttempt,
                            updateIsDraft: this.updateIsDraft,
                            updateIsSynced: this.updateIsSynced,
                            updateLesson: this.updateLesson,
                            clearMediaPlay: this.clearMediaPlay,
                            inactiveTimerCallback: this.handleTimeout,
                            loadUpdatedLesson: this.loadUpdatedLesson,
                            updateUserLessonCards: this.updateUserLessonCards,
                            setTooltip: this.setTooltip,
                        }}
                    >
                        <div className='lesson-container'>
                            <StudentPreviewController lesson={lesson} updateLesson={this.updateLesson} />
                            <ProgressionManager lesson={lesson} updateLesson={this.updateLesson} />
                            <NavigationSidebar
                                course={course}
                                isDraft={isDraft}
                                isSideBarHidden={isSideBarHidden}
                                isAdminPreview={this.context.course.isAdminPreview}
                            />
                            {course.lessonType === 'page' && <PageLesson />}
                            {course.lessonType === 'slide' && <SlideLesson lesson={lesson} course={course} />}
                        </div>
                    </LessonContext.Provider>
                </div>
            </>
        );
    }
}

export default connect(
    (state: any) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        setCourseChat: (payload: any) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    },
)(withRouter(Lessons));
