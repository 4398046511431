import React, { Component, ReactNode } from 'react';
import { FileImage } from 'src/components/ApiFile';
import Courses from './Courses';
import './PackageCard.scss';
import PackageHelper from 'src/helpers/PackageHelper';

export interface IPackage {
    _id: string;
    packageId: string;
    userId: string;
    data: any;
    invoice: any;
    expiresAt?: Date;
    createdAt: Date;
    updatedAt: Date;
    image: string[];
    courses: any[];
    showCourses?: boolean;
    affiliateCode: {
        allowed: boolean;
        code: string;
    };
    suspendedAt?: Date;
}

interface IProps {
    userPackage: IPackage;
}
interface IState {
    imageClass: { _id: string; className: string }[];
}

export default class PackageCard extends Component<IProps, IState> {
    state: IState = {
        imageClass: [],
    };

    async componentDidMount() {
        const resolvedImgClasses = await Promise.all(PackageHelper.getImagePromise([this.props.userPackage]));
        if (resolvedImgClasses.length > 0) {
            this.setState({
                imageClass: resolvedImgClasses,
            });
        }
    }

    render(): ReactNode {
        const { userPackage } = this.props;
        const { title, description } = this.props.userPackage.data;

        const imgClassObj: any = this.state.imageClass.find(
            (v: { _id: string; className: string }) => v._id === this.props.userPackage._id
        );

        return (
            <div className='package-card'>
                <div className='package-contents'>
                    <div className='square-img'>
                        <FileImage className={`image ${imgClassObj?.className}`} fileId={userPackage.image} />
                    </div>
                    <div className='description'>
                        <h3>{title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
                <Courses packageId={userPackage._id} courses={userPackage.courses} userPackage={userPackage} />
                {/* TODO: Hide this for now revisit it again */}
                {/* <ShareButton packageId={userPackage.packageId} affiliateCode={userPackage.affiliateCode} /> */}
            </div>
        );
    }
}
