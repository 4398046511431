import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NameAvatar from 'src/layouts/Main/UserBar/Menus/NameAvatar';
import { IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import './EssayAttemptListItem.scss';
import { IEssayAttempt } from './Essay.page';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface IProfileDetails {
    file?: { fileName: string; url: string[] };
    firstName: string;
    image?: string;
    lastName: string;
    email?: string;
}
interface IProps {
    loggedIn?: IUserLoggedIn;
    item: IEssayAttempt;
    isAdmin: boolean;
    studentDetails?: IProfileDetails;
}
interface IState {}
class EssayAttemptListItem extends Component<IProps & RouteComponentProps, IState> {
    state: IState = {};

    displayItem = (displayContent: string, isSender: boolean, profileDetails?: any) => {
        return (
            <Row className='attempt-list-container'>
                <Col xs={1} sm={1} md={1} className={`sender-photo ${!isSender ? 'sender' : 'receiver'}`}>
                    {profileDetails?.file?.url[0] ? (
                        <img className='profile-photo' src={profileDetails?.file?.url[0]} alt='profile photo' />
                    ) : (
                        <NameAvatar
                            className='profile-avatar'
                            firstName={profileDetails?.firstName ?? ''}
                            lastName={profileDetails?.lastName ?? ''}
                        />
                    )}
                </Col>
                <Col xs={11} sm={11} md={11} className={`answer-container  ${!isSender ? 'sender' : 'receiver'}`}>
                    <p className='sender-name'>
                        {(profileDetails?.firstName ?? '') + ' ' + (profileDetails?.lastName ?? '')}
                    </p>
                    <div className='sender-message' dangerouslySetInnerHTML={{ __html: displayContent ?? '' }} />
                </Col>
                <Col xs={1} sm={1} md={1} className={`sender-photo-hidden  ${!isSender ? 'sender' : 'receiver'}`}>
                    {profileDetails?.file?.url[0] ? (
                        <img className='profile-photo' src={profileDetails?.file?.url[0]} alt='profile photo' />
                    ) : (
                        <NameAvatar
                            className='profile-avatar'
                            firstName={profileDetails?.firstName ?? ''}
                            lastName={profileDetails?.lastName ?? ''}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    render() {
        const { item, studentDetails, loggedIn, isAdmin: isSender } = this.props;
        const profileData: any = studentDetails ?? loggedIn?.user;
        return (
            <>
                {item.essayAnswer && this.displayItem(item.essayAnswer, isSender, profileData)}
                {item?.feedback && (
                    <Row className='attempt-feedback-status'>
                        <i
                            className={`fa-solid fa-thumbs-${
                                item.attemptStatus === 'APPROVED' ? 'up' : 'down'
                            } status-icon`}
                        />
                        <span>
                            {item?.instructorReviewDate &&
                                DateTimeFormatHelper.format(item.instructorReviewDate, 'MM/DD hh:mm A')}
                        </span>
                        <span>
                            {(item?.instructorData?.firstName ?? '') + ' ' + (item?.instructorData?.lastName ?? '')}{' '}
                            marked this assessment attempt as{' '}
                            <b>{item.attemptStatus === 'APPROVED' ? 'successful' : 'unsuccessful'}</b>
                        </span>
                    </Row>
                )}
                {item?.feedback && this.displayItem(item.feedback, !isSender, item?.instructorData)}
            </>
        );
    }
}

export default connect((state: any) => {
    return { loggedIn: state.loggedIn };
})(withRouter(EssayAttemptListItem));
