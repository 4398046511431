import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router';
import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditialWrapper';
import { EventBus } from 'src/helpers/new';
import CourseContext from 'src/pages/Course/CourseContext';

interface IRouteProps {
    lessonId: string;
    courseId: string;
}

interface IProps {
    lessonContext: any;
    activeCardIndex: number;
    middleware: (e?: boolean) => boolean;
}

export type TProps = IProps & RouteComponentProps<IRouteProps>;

class NextButton extends Component<TProps> {
    static contextType = CourseContext;

    get isFinalCardInCourse(): boolean {
        const { nextLesson, cards } = this.props.lessonContext;
        return (!nextLesson || !nextLesson._id) && cards.length - 1 === this.props.activeCardIndex;
    }

    get progressionBlocked(): boolean {
        if (this.isLastCard) return !this.isNextLessonAvailable;
        else if (this.context.isAdmin) return false;
        else return !this.isNextCardAvailable;
    }

    get isLastCard(): boolean {
        return this.props.lessonContext.cards.length - 1 === this.props.activeCardIndex;
    }

    get isNextLessonAvailable(): boolean {
        return this.context.unlockedLessons?.includes(this.props?.lessonContext?.nextLesson?._id);
    }

    get isNextCardAvailable(): boolean {
        return !!this.props.lessonContext.cards[this.props.activeCardIndex + 1]?.userLessonCardData?.unlockedAt;
    }

    handleClick = () => {
        if (this.progressionBlocked) return;
        if (this.props.middleware() === false) return;

        const { userChapterId: chapterId, _id: lessonId } = this.props.lessonContext.nextLesson;

        if (chapterId && lessonId) {
            EventBus.dispatch('change-lesson', {
                lessonId: lessonId,
                chapterId: chapterId,
            });
        }
    };

    render() {
        const { children } = this.props;

        if (this.isFinalCardInCourse) return <></>;
        return (
            <ConditionalWrapper
                condition={this.context.isAdmin ? false : this.progressionBlocked}
                wrapper={(children: any) => (
                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-next-lesson`}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            (this.isLastCard
                                                ? this.context.unlockNextLessonTooltip
                                                : this.props?.lessonContext?.cardUnlockTooltip) ?? '',
                                    }}
                                />
                            </Tooltip>
                        }
                    >
                        {children}
                    </OverlayTrigger>
                )}
            >
                <div
                    className={`lesson-button lesson-button--next${
                        this.progressionBlocked ? ' lesson-button--disabled' : ''
                    }`}
                    onClick={this.handleClick}
                >
                    {children ? (
                        children
                    ) : (
                        <>
                            Next
                            <i className='fa-solid fa-chevron-right' />
                        </>
                    )}
                </div>
            </ConditionalWrapper>
        );
    }
}

export default withRouter(NextButton);
